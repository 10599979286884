import React, { useState, useEffect } from 'react'
import { GetOrder } from '../../../helpers';
import { parseCookies } from 'nookies'
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { IMAGE_URL } from '../../../config';
const cookie = parseCookies()
const MyOrders = () => {
    const [isLoading, setLoading] = useState(true);
    const [userOrders, setUserOrders] = useState([]);
    const [isOrderDetails, setIsOrderDetails] = useState(false);
    const [singleOrder, setSingleOrder] = useState(null);
    const [products, setProduct] = useState([]);
    const getData = async () => {
        const userCred = JSON.parse(cookie.user);
        const user = {
            user_id: userCred.authId,
            user_type: userCred.auth_type,
        }
        const res = await GetOrder.getUserOrder(user);
 
        if (res && res.length !== 0) {
            console.log(res);
            setUserOrders(res);
            setLoading(false)
        } else {
            setLoading(false)
        }

    }
    useEffect(() => {
        getData();
    }, []);

    const showOrderDetails = (order_id) => {
        setIsOrderDetails(true);
        const singleOrder = userOrders.find(el => el.id === order_id);
        console.log(singleOrder)
        setSingleOrder(singleOrder);
    }
    const backTolist = () => {
        setIsOrderDetails(false);
        setSingleOrder(null);

    }
    const getItemInfo = (discount, mainPrice) =>{
      return parseInt(mainPrice) - parseInt(discount);
    }
    
    return (
        <>
            <div className="dashboard-title">
                {
                    !isOrderDetails ? <h4 className="user-heading">My Orders</h4> : <Link to="#" onClick={backTolist} className="back-btn"><i className="fa fa-angle-left" /> Back</Link>
                }

            </div>
            <div className="dashboard-content">
                {
                    isLoading ? <div className='loading-container mh150'>
                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" />
                    </div> : <>
                        {
                            isOrderDetails ? <div className='orderDetails'>
                                <h5>Order Details</h5>
                                <p>ORDER ID : {singleOrder?.order_id}</p>
                                {/* <p>Placed on : {singleOrder !== null ? singleOrder?.created_at : null}</p> */}
                                <div className='order-time'>
                                    <p>Delivery Status : {singleOrder?.delivery_status}</p>
                                    <p>Payment Type : {singleOrder?.payment_type}</p>
                                    <p>Payment Status : {singleOrder?.payment_status}</p>
                                </div>
                                {/* <div className='payOnDelivery'>
                                    Pay on Delivery
                                </div> */}

                                <br />
                                <div className="card">
                                    <div className="card-header">
                                    <p>  {singleOrder?.total_items} Items <span>-</span> Product Details</p>
                                    </div>
                                    <div className="card-body">
                                        {
                                            singleOrder?.products.length !== 0 && singleOrder?.products.map((item, i)=>{
                                                return <div className='order-product-item'>
                                                    <div className='prod_details'>
                                                    <div className='prod_thumb'>
                                                        <img src={IMAGE_URL + item.thumbnail_img} className="img-fluid center-block cart_img2" alt='item' />
                                                      </div>
                                                      <div className='prod_title'>
                                                        <p>{item.product_name}</p>
                                                        <p>{item.brand_name}</p>
                                                         <p>{item.variants !== null ? item.variants.variant_name : null}</p>
                                                      </div>
                                                    </div>
                                                    <div className='prod_price'>
                                                   <p> <i class="fa fa-inr"/> {item.variants !== null ? item.variants.variant_discount !== null ? getItemInfo.call(this, item.variants.variant_discount, item.variants.variant_price) : getItemInfo.call(this, 0, item.variants.variant_price) : getItemInfo.call(this, 0, item.purchase_price)}</p>
                                                    </div>
                                                     

                                                    </div>
                                            })
                                        }
                                   

                                       

                                    </div>
                                </div>
                                <br />
                                <div className='payment-summary'>
                                    <div className="card">
                                        <div className="card-header">
                                            Payment Summary
                                        </div>
                                        <div className="card-body">
                                            <div className='price-item'>
                                                <div className='item1'>
                                                    <p>Total Amount</p>
                                                </div>
                                                <div className='item2'>
                                                    <p className='text-right'> <span style={{fontSize:'9px'}}>(+{singleOrder?.delivery_charge} Delivery Charge)</span> <br/><i className="fa fa-rupee" /> {singleOrder?.amount}  </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="card">
                                    <div className="card-header">
                                        Delivery Address
                                    </div>
                                    <div className="card-body">

                                    {/* {singleOrder?.address} */}

                                        <p>{JSON.parse(singleOrder?.address)}</p>

                                    </div>
                                </div>

                            </div> : userOrders.length !== 0 ? userOrders.map((item, i) => {
                                return <div className='order-box' key={i}>
                                    <div className='row'>
                                        <div className='col-md-8 bdr'>
                                            <div className='itemPrice'>
                                                <span>{item.total_items}  {parseInt(item.total_items) === 1 ? 'Item' : 'Items'} <br /><span className='orderId'>Order ID : {item.order_id}</span> </span>
                                                <span><i className="fa fa-rupee" /> {item.amount}</span>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='order-status'>
                                                <p>{item.delivery_status}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='viewOrder'>
                                        <Link to="#" onClick={() => showOrderDetails(item.id)}>View Details</Link>
                                    </div>
                                </div>
                            }) : <div className='order-not-found'>
                                <div>
                                    <img src="/images/user_img2.png" className="img-responsive center-block" alt='' />
                                    <p className="text-bold text-center">You have no orders</p>
                                    <p className=" text-center">Go to products page and select products</p>
                                    <p className="text-center pt-30">
                                        <Link to="/" className="btn4">
                                            Continue to Shopping
                                        </Link>
                                    </p>
                                </div>
                            </div>

                        }
                    </>
                }

            </div>
        </>
    )
}

export default MyOrders