import React from 'react'
import { Formik } from 'formik';
const RegisterNextStep = (props) => {

    const submitSignIn = async (values) => {
        console.log(values)
        props.signupNextStep(values);
    }
    return (
        <>
        <h4>Complete your details</h4>
         <Formik
            initialValues={{ legal_name: '', gst: '', state: '', pan: '' }}
            validate={values => {
                const errors = {};
                if (!values.legal_name) {
                    errors.legal_name = 'Legal name is required.';
                }

                if (!values.gst) {
                    errors.gst = 'GST is required.';
                }
                if (!values.state) {
                    errors.state = 'State is required.';
                }
                if (!values.pan) {
                    errors.pan = 'Pan is required.';
                }
                return errors;
            }}

            onSubmit={submitSignIn}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (<form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Legal Name"
                        className="form-control"
                        name="legal_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.legal_name}
                    />
                    {errors.legal_name && touched.legal_name && errors.legal_name}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="GST"
                        className="form-control"
                        name="gst"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gst}
                    />
                    {errors.gst && touched.gst && errors.gst}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="State"
                        className="form-control"
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                    />
                    {errors.state && touched.state && errors.state}
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="PAN"
                        className="form-control"
                        name="pan"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pan}
                    />
                    {errors.pan && touched.pan && errors.pan}
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-green btn-block">REGISTER</button>
                </div>
            </form>)}
        </Formik>
        </>
       
    )
}

export default RegisterNextStep