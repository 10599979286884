import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useQueries } from 'react-query';
import { GetHome } from '../../helpers';
import { IMAGE_URL } from '../../config';
const Footer = () => {
  // const { data, isLoading } = useQuery('footerCategories', GetHome.getAllCategory);
  const results = useQueries([
    { queryKey: ['footer_categories', 1], queryFn: GetHome.getAllCategory },
    { queryKey: ['footer_logo', 2], queryFn: GetHome.getSiteLogo },
])
const footer_categories = results[0]?.data;
const footer_logo = results[1]?.data;



  return (
    <Fragment>
         <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h4 className="text-bold">CATAGORIES</h4>
              <p>
                {
                  footer_categories?.data.map((item, i)=>{
                    return (
                     <Link to={`/${item.category_slug}`} className='categoryFtItem' key={i}>{item.category_name}</Link> 
                    )
                  })
                }
              </p>
              <h4 className="text-bold mt-40">POPULAR BRANDS</h4>
              <p>
                <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link> | <Link to="/">Amul</Link> | <Link to="/">Nescafe</Link> | <Link to="/">MTR</Link> | <Link to="/">Red Bull</Link>
              </p>
              <h4 className="text-bold mt-40">AVAILABLE IN CITIES</h4>
              <p>
                The delivery services is operational in Gurgaon, Delhi noida, Ghazlabad, Faridabad, jaipur, kanpur,jalsaimer, Prayagraj,Agra Merut, Chandigarh, Pune, Bangalore, Hidrabad, Lucknow, Varasanasi, nagpur, nasik, Thane, Mumbai.  
              </p>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-md-5 col-sm-4 col-xs-3">
              <hr className="hr1 mt-30" />
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6 footer-logo">
              {/* <h1 className="text-color1 text-bold text-center logo">OWO</h1> */}
              <img src={IMAGE_URL + footer_logo?.data.logo} alt=''/>
            </div>
            <div className="col-md-5 col-sm-4 col-xs-3">
              <hr className="hr1 mt-30" />
            </div>
          </div>
          <div className='payUsing'>
          <div>
              <h4 className="text-bold">
                PAY USING
              </h4>
              <img src="/images/cart.png" className="img-responsive" alt="cart"/>
          </div>
          <div>
          <ul className="app1">
                <li>
                  <a href='https://play.google.com/store/apps/details?id=owo.com.owo' target={'_blank'} rel="noreferrer">
                  <img src="/images/app_img1.png" className="img-responsive" alt="app_img1"/>
                  </a>
                 
                </li>
                <li>
                  <a href='https://apps.apple.com/us/app/id1517833936' target={'_blank'} rel="noreferrer">
                  <img src="/images/app_img2.png" className="img-responsive" alt="app_img2"/>
                  </a>
                </li>
              </ul>
          </div>

          
          </div>
        
        </div>
        <div className="container-fluid bordertop mt-30"> 
          <div className="row mt-40">
            <div className="col-md-2 col-sm-4 col-xs-7">
              <h4 className="text-black text-bold">LINKS</h4>
              <ul className="footerc1">
                <li><p><Link to="/contact-us">Contact Us</Link></p></li>
                <li><p><Link to="/">Terms &amp; conditions</Link></p></li>
                <li><p><Link to="/return-policy">Returns &amp; Cancellation</Link></p></li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-5 ">
              <ul className="footerc1 mt-26">
                <li><p><Link to="/about-us">
                      About us 
                    </Link></p></li>
                <li><p><Link to="/career">
                      Careers
                    </Link></p></li>
                <li><p><Link to="/privacy-policy">
                      Privacy Policy 
                    </Link></p></li>
              </ul>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-12 p-0 mt20-xs">
              <h4 className="text-bold">CUSTOMER SERVICE</h4>
              <ul className="footerc1">
                <li>
                  <p>
                    <span className="text-bold">Phone no: </span>
                  <a href='tel:9870333820'> 9870333820</a> 
                  </p>
                </li>
                <li>
                    <p>
                      <span className="text-bold">Email: </span>
                      <a href="mailto:info@owo.in">info@owo.in</a>
                    </p>
                  </li>
              </ul>
            </div>  
            <div className="col-md-3 col-sm-12 col-xs-12 pl-01 mt20-xs mt20-sm">
              <h4 className="text-bold">
                REGISTEREED OFFICE ADDRESS
              </h4>
              <ul className="footerc1">
                <li> <a href='https://www.google.com/maps/place/OWO+India+-+Online+Water+Delivery+Services/@28.4133096,77.0417622,21z/data=!4m5!3m4!1s0x390d23082d933' target="_blank" rel="noreferrer">
                  <p>
               OWO Technologies Pvt Ltd
                  </p></a>  
                </li>
                <li> <a href='https://www.google.com/maps/place/OWO+India+-+Online+Water+Delivery+Services/@28.4133096,77.0417622,21z/data=!4m5!3m4!1s0x390d23082d933' target="_blank" rel="noreferrer">
                    <p>
                    3rd Floor, Some bulding, Sohana Road
                      Gurgaon - 122002
                    </p>
                    </a>  </li>
              </ul>
            </div>  
            <div className="col-md-3 col-sm-12 col-xs-12">
           
              <ul className="footerc1 mt-26 mt20-xs mt20-sm">
                <li>
                  <p>
                    <span className="text-bold">CIN:</span> UOBRBRHROOOOOOZZ
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-50 mt20-xs">
            <div className="col-md-1 col-sm-1 " />
            <div className="col-md-3 col-sm-2 col-xs-1">
              <hr className="hr1" />
            </div>
            <div className="col-md-4 col-sm-6 col-xs-10">
              <ul className="social mt-10">
                <li>
                  <Link to="/"><i className="fa fa-facebook" /></Link>
                </li>
                <li>
                  <Link to="/"><i className="fa fa-twitter" /></Link>
                </li>
                <li>
                  <Link to="/"><i className="fa fa-instagram" /></Link>
                </li>
                <li>
                  <Link to="/"><i className="fa fa-pinterest" /></Link>
                </li>
                <li>
                  <Link to="/"><i className="fa fa-wordpress" /></Link>
                </li>
                <li>
                  <Link to="/"><i className="fa fa-youtube" /></Link>
                </li>
                <li>
                  <Link to="/"><i className="fa fa-linkedin" /></Link>
                </li>
                <li>
                  <Link to="/"><i className="fa fa-google-plus" /></Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-2 col-xs-1">
              <hr className="hr1" />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12 mt-20">
              <p className="text-center copyright">
                © Copyright 2022 owo.com. All right reserved. 
              </p>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default Footer