import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GetHome } from '../../helpers';
import { IMAGE_URL } from '../../config';
import { connect } from 'react-redux';
import { removeFromCart, incrementToCart, decreaseToCart, resetCart } from '../../store/actions/cartActions'
import { resetToWishlist } from '../../store/actions/wishListActions'
import Cart from '../pages/cart/Cart';
import { parseCookies, setCookie } from 'nookies'
import { useLocation } from 'react-router-dom';
import SearchBar from './SearchBar';
import AddressLocatorModal from './AddressLocatorModal';
const cookie = parseCookies();
const Header = (props) => {
 let cartRef = useRef();
  const location = useLocation();
  const [isShow, setShow] = useState(false)

  const toogleCart = () => {
    setShow(!isShow);
  }

  useEffect(() => {
    if (!cookie.delivery) {
      const location = JSON.stringify({
        pin_code: '122018',
        district: 'Gurgaon',
        state: 'Haryana'
      })
      setCookie(null, 'delivery', location, {
        sameSite: true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })
    }
   let handler = (event)=>{
     if(!cartRef.current.contains(event.target)){
      setShow(false)
     }
   }
   document.addEventListener("mousedown", handler);
   return () =>{
     document.removeEventListener("mousedown", handler);
   }
  }, [])

  const scrolltoelement = () => {
    if (location.pathname === '/') {
      const section = document.querySelector('#dealoftheday');
      section.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

  };
  const delivery = cookie.delivery && JSON.parse(cookie.delivery);
  const { data, isLoading } = useQuery('logo', GetHome.getSiteLogo);
  const apiData = data?.data;
  return (
    <Fragment>
      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 col-sm-12 col-xs-12 logolink">

              <Link to="/">
                {
                  !isLoading && <img src={IMAGE_URL + apiData?.logo} alt="OWO" className='brand_image' />
                }
              </Link>
            </div>
            <div className="col-md-5 col-sm-12 mt-10">
              <div className='header-first-bar'>
                <div className="user-location">
                  <button type='button' className='addLocatorBtn' data-toggle="modal" data-target="#addressLocatorModal" data-backdrop="static" data-keyboard="false"><i className="fa fa-map-marker" />
                    {
                      delivery ? <><p>Deliver to <br />{delivery.district} {delivery.pin_code}</p></> : "122018, Gurgaon, Haryana"
                    }
                  </button>
                  <AddressLocatorModal />
                </div>
                <div className='fg1 plr'>
                  <SearchBar />
                </div>

              </div>

            </div>
            <div className="col-md-1 col-sm-4 col-xs-6 col-6 mt-10">
            <div className="dropdown">
        <button className="btn btn-default dealBtn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        All Deals
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="#">New Arrivals</a>
          <a className="dropdown-item" href="#">Trending Brands</a>
          <a className="dropdown-item" href="#">You May Also Like</a>
          <a className="dropdown-item" href="#">Flash Deals & Offers</a>
        </div>
      </div>
              {/* <button className="btn btn-default dealBtn dropdown-toggle" type="button" onClick={scrolltoelement}>
                All Deals
              </button> */}
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6 col-6 ">
              <h4 className="text-white text-bold text-center number1 mmt-10">800 332 65 00</h4>
              <p className="text-white spc1 text-center mb-0">
                SUPPORT
              </p>
              {/* <button onClick={reset}>Reset</button> */}
              {/* <button onClick={resetWishlist}>Reset Wishlist</button>   */}

            </div>
            <div className="col-md-2 col-sm-4 col-xs-12 pl-0 mt-10">

              <p className="logspan  text-center mb-0">

                {cookie.auth && cookie.auth === 'true' ? <span> <a href="/wishList" className="text-black border-right"><i className="fa fa-heart" /></a></span> : null}

                <span>
                  {
                    cookie.auth && cookie.auth === 'true' ? <Link to="/user/dashboard" className="text-black border-right"><i className="fa fa-user" /></Link> : <Link to="/login" className="text-black border-right"><i className="fa fa-user" /> LOGIN</Link>
                  }

                </span>
                {
                  location.pathname !== '/checkout' && <span>
                    <button className="text-black tbtn" onClick={toogleCart}>{props.cartItems && <><i className="fa fa-shopping-cart" /><span className="cartno">{props.cartItems.length}</span></>} MY CART </button>
                  </span>
                }

              </p>
            </div>
          </div>
        </div>
      </header>
      {/* {isShow && location.pathname !== '/checkout' && <Cart isShow={isShow} toogleCart={toogleCart} />} */}
      <Cart isShow={isShow} toogleCart={toogleCart} cartRef={cartRef}/>
    </Fragment>
  )
}

export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { incrementToCart, decreaseToCart, removeFromCart, resetCart, resetToWishlist }
)(Header);