import React from 'react'

const CashFreeModal = (props) => {
    return (
        <div className="modal fade" id="cashfreeModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>Make Your Payment </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div id="payment-form">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CashFreeModal