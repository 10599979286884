import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IMAGE_URL } from '../../config'
import { parseCookies } from 'nookies'
import { useHistory } from "react-router-dom";
import { addToCart, incrementToCart, decreaseToCart } from '../../store/actions/cartActions';
import { addToWishlist } from '../../store/actions/wishListActions';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
const cookie = parseCookies()

const ProductItem = ({ data, mCate, subCate, subSubCate, addCart, showLoading, cartProduct }) => {


  const category_slug = data?.category?.category_slug;
  const subCategory_slug = data?.category?.sub_category.subcategory_slug;
  const subSubCategory_slug = data?.category?.sub_category.sub_sub_category.subsubcategory_slug;
  let history = useHistory();
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.user);
  const cartItems = useSelector(state => state.cart.cartItems);
  const wishitems = useSelector(state => state.wishlist.wishItems);
  // const showLoading = useSelector(state => state.cart.showLoading);
  const [isLoading, setLoading] = useState(true);
  const [purchagePrice, setPurchagePrice] = useState(null);
  const [mainPrice, setMainPrice] = useState(null);
  const [varient, setVarient] = useState(null);
  const [productItem, setProductItem] = useState(null);
  const [qty, setQty] = useState(1);



  useEffect(() => {
    setProductItem(data);
    const fvarient = data?.has_variant === '1' && data?.variant[0];
    if (fvarient) {
      setVarient(fvarient);
      setMainPrice(fvarient.variant_price);
      getItemInfo2(fvarient);
      setLoading(false)
    } else {
      setVarient(null);
      setPurchagePrice(data.purchase_price);
      setMainPrice(data.mrp);
      setLoading(false)
    }

  }, []);
  const getItemInfo2 = (item) => {
    if (item.variant_discount !== null) {
      const discPer = (item.variant_discount / item.variant_price) * 100;
      const disc_price = item.variant_price - item.variant_discount;
      setPurchagePrice(disc_price);
    } else {
      setPurchagePrice(item.variant_price);
    }
  }
  const fvarient = data?.has_variant === '1' && data?.variant[0];
  const getItemInfo = (item) => {
    if (item.variant_discount !== null) {
      const discPer = (item.variant_discount / item.variant_price) * 100;
      const disc_price = item.variant_price - item.variant_discount;
      return " Rs." + disc_price;
    } else {
      return null;
    }
  }
  const handleChangeSize = (val) => {
    const currentVarient = productItem.variant.find(el => el.id === val);
    setVarient(currentVarient);
    setMainPrice(currentVarient.variant_price);
    getItemInfo2(currentVarient);
  }

  const handleSubmitWishlist = () => {
    if (cookie.auth && cookie.auth === 'true') {
      if (userDetails && userDetails.user !== null) {
        var data = {
          "user_id": userDetails.user.id,
          "user_type": userDetails.user.user_type,
          "product_id": productItem.id,
        }
        // dispatch(loadingWishlist(true));
        dispatch(addToWishlist(data));
      }
    } else {

      return history.push({
        pathname: "/login",
        state: {
          pre_path: window.location.pathname
        }
      });
    }
  }
  const handleCartSubmit = () => {
    if(typeof(qty) === 'number' && qty > 0 ){
    if (cookie.auth && cookie.auth === 'true') {
      if (userDetails && userDetails.user !== null) {
        const data = {
          "user_id": userDetails.user.id,
          "user_type": userDetails.user.user_type,
          "product_id": productItem.id,
          "variant_id": varient !== null ? varient.id : null,
          "quantity":qty
        }

        // dispatch(loadingToggleAction(true));
        dispatch(addToCart(data));
      }
    } else {

      return history.push({
        pathname: "/login",
        state: {
          pre_path: window.location.pathname
        }
      });
    }
    }else{
      toast.error('You have entered wrong quantity !', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

  }

  const increaseQty = (cart_id) => {
    dispatch(incrementToCart(cart_id));
  }
  const decreaseQty = (cart_id) => {
    dispatch(decreaseToCart(cart_id))
  }


  const isProduct = data?.has_variant === '1' ? cartItems?.find(el => parseInt(el.product_id) === data?.id && parseInt(el.variant_id) === varient?.id) : cartItems?.find(el => parseInt(el.product_id) === data?.id);
  const isWishListed = wishitems?.find(el => parseInt(el.product_id) === data?.id);
  return (
    <>

      {
        !isLoading && <div className="product-item">
          <Link to={`/p/${category_slug ? category_slug : productItem.category_slug}/${subCategory_slug ? subCategory_slug : productItem.sub_category_slug}/${subSubCategory_slug ? subSubCategory_slug : productItem.sub_sub_category_slug}/${productItem.product_slug}`}>
            <img src={IMAGE_URL + productItem.thumbnail_img} className="img-responsive" alt='' />
          </Link>
          <p>
            <Link to={`/p/${category_slug ? category_slug : productItem.category_slug}/${subCategory_slug ? subCategory_slug : productItem.sub_category_slug}/${subSubCategory_slug ? subSubCategory_slug : productItem.sub_sub_category_slug}/${productItem.product_slug}`} className="product-name">
              {productItem.product_name}
            </Link>
          </p><p>
          </p>
          {/*-hr class="hrlist"-*/}
          {
            parseInt(productItem.has_variant) === 1 ?
            <div className="dropdown">

              <button className="btn dropdown-toggle qty-dropdown" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {varient.variant_name} - {purchagePrice}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  productItem.variant?.map((item, i) => {
                    return <span className="dropdown-item" key={i} onClick={() => handleChangeSize(item.id)}>{item.variant_name} - {item.variant_discount !== null ? null : `Rs. ${item.variant_price}`} <span> {getItemInfo.call(this, item)}</span></span>
                  })
                }
              </div>
            </div> : <div className='emp-space'></div>
          }


          <p className="listprice">
            Rs {purchagePrice}
            <span className="acci1 text-color1">
              <del>Rs {mainPrice}</del>
              {/* <span className="text-green">{productItem.discount}</span> */}
            </span>
          </p>
          {
            isWishListed ? <div className='isWishlisted'>
              Wishlisted <i className="fa fa-heart-o"></i>
            </div> : <button type="button" className="qtybtn2" onClick={() => handleSubmitWishlist(productItem.id)}>
              Wishlist <i className="fa fa-heart-o"></i>
            </button>
          }


          {
            isProduct ? <div className='qty-add'>
              <div><button type='button' className='qty-btn' onClick={() => decreaseQty(isProduct.id)}>-</button></div>
              <div><div className='qty-num'>{isProduct.quantity}</div></div>
              <div><button type='button' className='qty-btn' onClick={() => increaseQty(isProduct.id)}>+</button></div>
            </div> : <div className="qtyfirstdiv pdiv1"><div className="col-xs-4 cpadding3">
              <div className='row'>
                <div className='col-md-7'>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="btnGroupAddon">QTY</div>
                    </div>
                    <input type="number" className="form-control" aria-label="Input group example" aria-describedby="btnGroupAddon" value={qty} onChange={(e)=>setQty(parseInt(e.target.value))}/>
                  </div>
                </div>
                <div className='col-md-5 text-right'>
                  <button type="button" className="qtybtn" onClick={() => handleCartSubmit(productItem.id)}>
                    Add +
                  </button>
                </div>
              </div>



              <div className="clearfix" />
            </div></div>
          }
        </div>
      }
    </>

  )
}


export default ProductItem;