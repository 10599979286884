import React, { Fragment, useState, useEffect } from 'react'
import MainCategories from '../product/MainCategories'
import CategoryMenu from '../product/CategoryMenu'
import ProductItem from '../product/ProductItem'
import productData from '../../data/products.json'
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router-dom';
import { GetCategories } from '../../helpers'
import Custom404 from '../partials/Custom404'
import { useSelector } from "react-redux";

const Products = () => {

  const params = useParams()
 
  //redux data

  const cartItems = useSelector(state => state.cart.cartItems);
  const showLoading = useSelector(state => state.cart.showLoading);

  // We start with an empty list of productData
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [category, setCategory] = useState([]);
  const [sub_categories, setSub_categories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [cartProduct, setCartProduct] = useState([]);

  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(false);
  const [isServerError, setServerError] = useState(false);
  //const [itemsPerPage, setitemsPerPage] = useState(20);
  const itemsPerPage = 20;
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  // const [cartLoading, setCartLoading] = useState(false);



  const getCategory = async (slug) => {
    const res = await GetCategories.getSingleCategory(slug);
    if (res && res.status === 200) {
      setCategory(res.category);
      setSub_categories(res.data);
      setProducts(res.all_products);
      setAllProducts(res.all_products);
      getSubCategory(res.data, res.all_products);
      setLoading(false)
      // setServerError(false)
    }
    else {
      setLoading(false)
      setServerError(true)

    }

  }
  const getSubCategory = async (data, products) => {
    if (params.subcategory && !params.subsubcategory) {
      const subCategory = data.filter(el => el.subcategory_slug === params.subcategory);
      if (subCategory.length !== 0) {
        const product = products.filter(el => parseInt(el.subcategory_id) === parseInt(subCategory[0].id));
        //setSub_categories(subCategory);
       // setSubSubCategories(subCategory[0].children)
        setProducts(product);
      } else {
        setServerError(true)
      }



    } else if (params.subcategory && params.subsubcategory) {

      const subCategory = data.filter(el => el.subcategory_slug === params.subcategory);
      if(subCategory.length !== 0){
        const subSubCategories = subCategory[0].children.filter(el => el.subsubcategory_slug === params.subsubcategory);
        if(subSubCategories.length !== 0){
          const product = products.filter(el => parseInt(el.subsubcategory_id) === parseInt(subSubCategories[0].id));
          // console.log(subSubCategories);
          //setSub_categories(subCategory);
         // setSubSubCategories(subCategory[0].children)
          setProducts(product);
        }else{
          setServerError(true)
        }
      }else{
        setServerError(true)
      }
     
    } 
  }

const showCategory = (val) =>{
  getCategory(val);
}

  useEffect(() => {
    getCategory(params.category);
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(productData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(productData.length / itemsPerPage));
    setCartProduct(cartItems)
  }, [params.category, itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % productData.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const onCategoryChange = (catSlug, subCatSlug, subSubCateSlug) =>{
    setLoading2(true)
    if(catSlug && subCatSlug && !subSubCateSlug){
      const subCate = sub_categories.find(el=>el.subcategory_slug === subCatSlug);
       const product = allProducts.filter(el=>parseInt(el.subcategory_id) === parseInt(subCate.id));
       setProducts(product);
       setLoading2(false)
    }
    if(catSlug && subCatSlug && subSubCateSlug){
      const subCate = sub_categories.find(el=>el.subcategory_slug === subCatSlug);
      const subSubCate = subCate.children.find(el=>el.subsubcategory_slug === subSubCateSlug)
       const product = allProducts.filter(el=>parseInt(el.subcategory_id) === parseInt(subCate.id) && parseInt(el.subsubcategory_id) === parseInt(subSubCate.id));
       setProducts(product);
       setLoading2(false)  
    }
   
  }
  return (
    <Fragment>
      <MainCategories />

      <section className="breadcrumbs sectiondiv">
        <div className="container">
          <div className='page-title'>
            <h1 className="text-center text-color1">{category?.category_name}</h1>
          </div>
        </div>
      </section>
      <section className="productlisting sectiondiv">
        <div className="container">
          {
            isLoading ? <div className='loading-container'>
              <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" />
            </div> : isServerError ? <Custom404/> :
              <div className="row">
                <div className="col-md-3 col-sm-4">
                  <h3 className="visible-xs h3-xs">Categories <button type="button" className="navbar-toggle catmenu" data-toggle="collapse" data-target="#myNavbar2">
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button></h3>
                  <div className="sidebar1 borderimg" id="myNavbar2">

                    <CategoryMenu onCategoryChange={onCategoryChange} sub_categories={sub_categories} category={category} subSubCategories={subSubCategories}  showCategory={showCategory}/>
                    <hr />
                    <div className='disc_sec'>
                      <h4 className="text-color1 ">
                        Discount Offers
                      </h4>
                      <form>
                        <div className="checkbox">
                          <label><input type="checkbox" defaultValue />
                            10% and above
                          </label>
                        </div>
                        <div className="checkbox">
                          <label><input type="checkbox" defaultValue />
                            20% and above
                          </label>
                        </div>
                        <div className="checkbox">
                          <label><input type="checkbox" defaultValue />
                            30% and above
                          </label>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
                <div className="col-md-9 col-sm-8">
                  <div className="row">

                    { isLoading2 ? "Please wait.." :
                      products.length !== 0 ? <>
                        <ul className="productlists">
                          {
                            products.map((item, i) => {
                              return <li key={i}><ProductItem cartProduct={cartProduct} showLoading={showLoading} data={item} mCate={params.category} subCate={params.subcategory} subSubCate={params.subsubcategory}  cartItems={cartItems} /></li>
                            })
                          }


                        </ul>
                        {
                          products.length > 20 && <ReactPaginate
                            className='pegination'
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={10}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                          />
                        }

                      </>
                        : <div className='not-found-box'>

                          <p><i className="fa fa-exclamation-triangle"></i>Product not found !</p>
                        </div>}
                  </div>



                </div>
              </div>
          }

        </div>
      </section>
    </Fragment>
  )
}

export default Products