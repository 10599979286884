import React from 'react'
import { Formik, Field } from 'formik';
import { parseCookies } from 'nookies'
import { GetAddress } from '../../../helpers';
import { toast } from 'react-toastify';
import $ from "jquery";

const cookie = parseCookies()
const EditAddress = (props) => {
    const editData = props.editData;
    const address = editData ? editData.address : '';

    const locality = editData ? editData.locality : '';
    const pincode = editData ? editData.pincode : '';
    const city = editData ? editData.city : '';
    const state = editData ? editData.state : '';
    const type = editData ? editData.type : '';
    const updateAddress = async (values, { resetForm }) =>{
        if(cookie.auth && cookie.auth === 'true'){
            const user = JSON.parse(cookie.user);
            const data = {
                user_id:user.authId,
                user_type:user.auth_type,
                address:values.address,
                locality:values.locality,
                pincode:values.pincode,
                city:values.city,
                state:values.state,
                type:values.type,
                default:0
              }
              const res = await GetAddress.updateAddress(data, props.editData?.id);
             
              if(res && res.status === 200){
                $("#updateAddressModal").modal("hide");
                resetForm();
                props.reloadPageData(true);
                toast.success('Address updated successfully !', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                  
              }
        }
      
    }
  return (
    <div className="modal fade" id="updateAddressModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div className="modal-content">
      <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
        
          <h5 >Edit Address</h5>
          <Formik
          
       initialValues={{ address:address, locality:locality, pincode:pincode, city:city, state:state, type:type} }
       enableReinitialize
       validate={values => {
         const errors = {};
         if (!values.locality) {
           errors.locality = 'Locality is required';
         } 
         if (!values.pincode) {
           errors.pincode = 'Pincode is required';
         } 
        
         if (!values.city) {
           errors.city = 'City is required';
         } 
         if (!values.state) {
           errors.state = 'State is required';
         } 
         if (!values.type) {
           errors.type = 'Address type is required';
         } 
         return errors;
       }}
       onSubmit={updateAddress}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              {/* <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="house_no"
                placeholder="House No." 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.house_no}
                />
                
              </div> */}
              <div className="col-sm-12">
                <input type="text" 
                className="form-control add-input" 
                name="address" 
                placeholder="Address" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
                />
               
              </div>
              </div>
              <div className="row mb-3">
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="locality" 
                placeholder="Area/Locality" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.locality}
                />
                {errors.locality && touched.locality && errors.locality}
              </div>
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="pincode" 
                placeholder="PIN" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pincode}
                />
                {errors.pincode && touched.pincode && errors.pincode}
              </div>
              </div>
              <div className="row mb-3">
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="city" 
                placeholder="City" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                />
                {errors.city && touched.city && errors.city}
              </div>
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="state" 
                placeholder="State" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.state}
                />
                {errors.state && touched.state && errors.state}
              </div>
            </div>
            <div className="form-group user_type">
                    <div role="group" aria-labelledby="my-radio-group" className='d-flex'>
                      <label>
                        <Field type="radio" name="type" value="Home" />
                        Home
                      </label>
                      <label>
                        <Field type="radio" name="type" value="Office" />
                        Office
                      </label>
                      <label>
                        <Field type="radio" name="type" value="Other" />
                        Other
                      </label>
                    </div>
                    {errors.type && touched.type && errors.type}
                  </div>
            {/* <div className="row">
              <div className="col-sm-12">
                <p className="mb-3"><span className="mr-30"><input type="radio" name="addtype" /> Home</span><span className="mr-30"><input type="radio" name="addtype" /> Office</span><span><input type="radio" name="addtype" /> Other</span></p>
              </div>
            </div> */}
            <div className="row mb-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-address">Update Now</button>
              </div>
            </div>
          </form> )}
     </Formik>
        </div>
      </div>
    </div>
  </div>
  )
}

export default EditAddress