import React from 'react'
import { Link } from 'react-router-dom';
const Breadcrumbs = ({category, produtName}) => {
  return (
    <section className="breadcrumbs2 sectiondiv">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <p className="breadcrumbtext">
            <Link to="/">{category?.category_name}</Link> &gt; <Link to="/">{category.sub_category.subcategory_name}</Link> &gt; <Link to="/">{category.sub_category.sub_sub_category.subsubcategory_name}</Link> &gt; {produtName}
          </p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Breadcrumbs