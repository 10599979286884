import React,{useEffect, useState} from 'react'
import { useSelector } from "react-redux";
const WalletPay = ({cfCheckout, onPayment}) => {
    const user = useSelector(state => state.user.user);
   const [appIsReady, setAppIsReady] = useState(false);

   function appEventHandler(data){
    // console.log("App --> ", data)
    setAppIsReady(data.isReadyToPay);   
   }

   useEffect(() => {
        cfCheckout.element(
            {
                pay: document.getElementById('pay-app'),
                type: 'app',
                onChange: appEventHandler
            },
        );
    }, [cfCheckout]);

   const onPaymentInit = async() =>{
    if(!appIsReady){
        alert('Upi is not ready to pay');
        return 
    }
    onPayment("app")
  
   }
  return (
    <>
    <div id="pay-app">
         <label htmlFor='wallet'>Wallet</label>
         <select  data-app-name className='form-control mb-3' id="wallet">
                  <option value="">Select app</option>
                  <option value="phonepe">PhonePe</option>
                  <option value="airtel">Airtel</option>
                  <option value="freecharge">Freecharge</option>
                  <option value="mobikwik">Mobikwik</option>
                  <option value="jio">Jio</option>
                  <option value="ola">Ola</option>
         </select>
         <input type="hidden" data-app-phone value={user.phone}/>
         <button id="pay-app-btn" className={appIsReady ? 'btn btn-block pay-btn2' :'btn btn-block btn-dark pay-btn'} onClick={onPaymentInit}>Pay Now</button>
</div>
    </>
  )
}

export default WalletPay