import React, { useState } from 'react'
import { Formik, Field } from 'formik'
import { useSelector } from "react-redux";
import { GetWallet } from '../../../helpers';
import { toast } from 'react-toastify';
import $ from "jquery";
const AddMoneyModal = (props) => {
    const userDetails = useSelector(state => state.user);
    const [isLoading, setLoading] = useState(false);
    // const addMoney = async (values) => {
    //     setLoading(true)
    //      const user = userDetails?.user;
    //     const data={
    //         user_id: user?.id,
    //         user_type: user?.user_type,
    //         amount:parseInt(values.amount)*100,
    //         firstname:user?.name,
    //         email:user?.email,
    //         payment_method:"razorpay"
    //         // product_info:"wallet"
    //     }
    //     const res = await GetWallet.addMoneyToWallet(data);

    //     if(res && res.status === 200){
    //         const respData = {
    //             order_id: res.razorpay_order_id,
    //             amount: data.amount,
    //             currency:'INR'
    //         }
    //         setLoading(false)
    //         $("#addMoneyModal").modal("hide");

    //         props.displayRazorpay(respData); 
    //     }else{
    //         setLoading(false)
    //         toast.error('You have entered wrong amount.', {
    //             position: "top-center",
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             });

    //     }

    // }


    const addMoney = async (values) => {
        setLoading(true)
        if(values.picked === 'cashfree'){
            const user = userDetails?.user;
            const data = {
                amount: parseFloat(values.amount),
                payment_method: values.picked,
                customer_details: {
                    customer_id: `${user?.id}`,
                    customer_name: `${user?.name}`,
                    customer_type: `${user?.user_type}`,
                    customer_email: `${user?.email}`,
                    customer_phone: `${user?.phone}`,
                },
                payment_details: "Wallet"
            }
            const res = await GetWallet.addMoneyToWallet(data);
    
            if (res && res.status === 200) {
                const respData = {
                    order_id: res.cashfree_order_id,
                    order_token: res.cashfree_order_token,
                    amount: data.amount,
                    currency: 'INR'
                }
                setLoading(false)
                $("#addMoneyModal").modal("hide");
    
                props.renderDropin(respData);
            } else {
                setLoading(false)
                toast.error('Something went wrong.', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
    
            }
        }else if(values.picked === 'payu'){
          var amount = parseFloat(values.amount);
          props.payUmoneyHash(amount);
          setLoading(false)
          $("#addMoneyModal").modal("hide");
        }
      

    }

    return (
        <>
            <div className="modal fade" id="addMoneyModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5>Add Money to Wallet </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={isLoading}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Formik
                                initialValues={{ amount: '', picked:'cashfree' }}
                                validate={values => {
                                    const errors = {};
                                    if (!values.amount) {
                                        errors.amount = 'Amount is required';
                                    }

                                    return errors;
                                }}
                                onSubmit={addMoney}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    /* and other goodies */
                                }) => (<form onSubmit={handleSubmit}>
                                    <div className="form-group text-left">
                                        <input type="number"
                                            className="form-control"
                                            name="amount"
                                            placeholder='Enter amount'
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.amount}
                                        />
                                        <span className='help-error'>{errors.amount && touched.amount && errors.amount}</span>
                                    </div>
                                    <div className='form-group text-left'>
                                        <div id="payment_method">Payment Method</div>
                                        <div role="group" aria-labelledby="payment_method" className='payM'>
                                            <label>
                                                <Field type="radio" name="picked" value="cashfree" />
                                                <img src="/images/cashfre.png" alt="img1" className="img-responsive" />
                                            </label>
                                            <label>
                                                <Field type="radio" name="picked" value="payu" />
                                                <img src="/images/payu.png" alt="img1" className="img-responsive" />
                                            </label>

                                        </div>
                                    </div>
                                    <button type='submit' className='address-btn' disabled={isLoading}>Add Now</button>

                                </form>)}
                            </Formik>

                            {/* <button onClick={props.renderDropin}>Cashfree Open</button> */}

                            {
                                isLoading && <div className='loading-container mh50'>
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" /> Please wait..
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMoneyModal