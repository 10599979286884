export const ADD_TO_CART = 'ADD_TO_CART';
export const FETCH_TO_CART = 'FETCH_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const GET_TOTALS = "GET_TOTALS";

export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const RESET = 'RESET';

export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const FETCH_FROM_WISHLIST = 'FETCH_FROM_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const INCREASE_QUANTITY_WISHLIST = 'INCREASE_QUANTITY_WISHLIST';
export const DECREASE_QUANTITY_WISHLIST = 'DECREASE_QUANTITY_WISHLIST';
export const LOADING_WISHLIST = 'LOADING_WISHLIST';
export const RESET_WISHLIST = 'RESET_WISHLIST';

