import React from 'react'
import { Link } from 'react-router-dom'
const NotFound = () => {
  return (
    <div className="container-fluid">
                <div className="mainbox">
                    <h1>404</h1>
                    <div className="msg">Maybe this page moved? Got deleted? Is hiding out in quarantine? Never existed in the first place?<p>Let's go <Link to="/">home</Link> and try from there.</p></div>
                </div>
            </div>
  )
}

export default NotFound