import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import { IMAGE_URL } from '../../../config';
import { GetAddress, GetProducts, GetOrder } from '../../../helpers';
import { parseCookies } from 'nookies'
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { compareTwoDatesForStart, compareTwoDatesForEnd, getCurrentDate, } from '../../../helpers/getDate'
import Payment from './payment/Payment';
import { GetWallet } from '../../../helpers'
import { toast } from 'react-toastify';
import { API_URL } from '../../../config';
import { GetPayment } from '../../../helpers';
import $ from "jquery";
const cookie = parseCookies();
const Checkout = () => {
    const history = useHistory();
    const cart = useSelector(state => state.cart);
    const user = useSelector(state => state.user.user);
    const [isLoading, setLoading] = useState(true);
    const [isSubmitCheckout, setIsSubmitCheckout] = useState(false)
    const [payableAmt, setPaybleAmt] = useState(0);
    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const [deliveryCharge, setDeliveryCharge] = useState(null)
    const [coupons, setCoupons] = useState([]);
    const [couponsAmt, setCouponAmt] = useState(0);
    const [couponCode, setCouponCode] = useState('');
    const [couponError, setCouponError] = useState('');
    const [wallet, setWallet] = useState(null);
    const [walletLoading, setWalletLoading] = useState(false);

    //PayU Money
    const [key, setKey] = useState('oZ7oo9');
    const [txnid, setTxnid] = useState('');
    const [productinfo, setProductinfo] = useState('');
    const [amount, setAmount] = useState(10);
    const [hash, setHash] = useState('');
  
    const cartItems = cart?.cartItems;
    const totalPrice = cart?.total_price;
    const fetchAddresses = async () => {
        const user = JSON.parse(cookie.user);
        const data = {
            user_id: user.authId,
            user_type: user.auth_type
        }
        const res = await GetAddress.getDefaultAddress(data);
        const res2 = await GetProducts.getDeliveryCharge();
        const res3 = await GetProducts.getCoupon();
        const res4 = await GetWallet.getWalletMoney(data);
        if ((res && res.data) && (res2 && res2.data)) {
            const totalPayAmt = parseInt(res2.data.charge) + totalPrice;
            setDeliveryAddress(res.data);
            setDeliveryCharge(res2.data.charge);
            setPaybleAmt(totalPayAmt);
            setCoupons(res3.data);
            setWallet(res4.data);
            setLoading(false);
        }
    }
    useEffect(() => {
        fetchAddresses();
    }, [])

    const onChangeCoupon = (e) => {
        const val = e.target.value;
        //    if(val.length > 6){

        //    }
        setCouponError('');
        setCouponCode(val)
    }
    const applyCoupon = () => {
        if (couponCode !== '') {
            const fcoupon = coupons.find(el => el.coupon_code === couponCode);
            if (fcoupon) {
                if (parseInt(fcoupon.status) === 1 && compareTwoDatesForStart.call(this, fcoupon.start_date) && compareTwoDatesForEnd.call(this, fcoupon.end_date)) {
                    console.log(fcoupon);
                    if (payableAmt >= parseInt(fcoupon.minimum_shopping)) {
                        const coupon_dic = parseInt(fcoupon.discount);
                        setCouponAmt(coupon_dic);
                        setPaybleAmt(payableAmt - coupon_dic)
                        $(".bd-example-modal-lg").modal("hide")
                    } else {
                        setCouponError('Invalid amount for this code.');
                    }

                }
            } else {
                setCouponError('Coupon code does not exist.');
            }

        }
    }
    const payWithOWOWallet = async () => {
        setWalletLoading(true);
        if (wallet !== null) {
            const data = {
                wallet_id: wallet.id,
                amount: payableAmt
            }
            const res = await GetWallet.processWalletOrder(data);

            if (res && res.status === 200) {
                createOrder('wallet', 'success');
            } else {
                toast.error(res.message, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return history.push('/user/my-wallet')
            }
        } else {
            toast.error('Insufficient balance in wallet. Please add money to wallet !', {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return history.push('/user/my-wallet')
        }
    }

    const createOrder = async () => {
        setIsSubmitCheckout(true)
        const user = JSON.parse(cookie.user);
        const address = `${deliveryAddress.address}, ${deliveryAddress.locality}, ${deliveryAddress.city}, ${deliveryAddress.state}, ${deliveryAddress.pincode}`;
        const data = {
            user_id: user.authId,
            user_type: user.auth_type,
            address: address,
            payment_type: 'cod',
            transaction_id: "no",
            amount: payableAmt,
            total_items: cartItems?.length,
            payment_status: 'pending',
            delivery_charge: deliveryCharge,
            delivery_slot: "",
            coupon_discount: couponsAmt,
            delivered_by: "",
            delivered_at: "",
            instant_order: 1
        }
        const res = await GetOrder.createOrder(data);

        if (res && res.status === 200) {

            setWalletLoading(false);
            setIsSubmitCheckout(false);
            window.location.href = "/checkout/success"
            // return history.push({
            //     pathname: "/checkout/success",
            //     state: {
            //         checkoutResp:true
            //     }
            // });

        } else {
            window.location.href = "/checkout"
        }

    }

    const createOnlineOrder = async (paymentType, paymentStatus, transactionId, txnAmout) => {
        const user = JSON.parse(cookie.user);
        const address = `${deliveryAddress.address}, ${deliveryAddress.locality}, ${deliveryAddress.city}, ${deliveryAddress.state}, ${deliveryAddress.pincode}`;
        const data = {
            user_id: user.authId,
            user_type: user.auth_type,
            address: address,
            payment_type: paymentType ? paymentType : '',
            transaction_id: transactionId,
            amount: payableAmt,
            total_items: cartItems?.length,
            payment_status: paymentStatus.toLowerCase(),
            delivery_charge: deliveryCharge,
            delivery_slot: "",
            coupon_discount: couponsAmt,
            delivered_by: "",
            delivered_at: "",
            instant_order: 1
        }
        const res = await GetOrder.createOrder(data);
        if (res && res.status === 200) {
            setWalletLoading(false);
            setIsSubmitCheckout(false);
            window.location.href = "/checkout/success"
            // return history.push({
            //     pathname: "/checkout/success",
            //     state: {
            //         checkoutResp:true
            //     }
            // });
            //  const state = {
            //         checkoutResp:true
            //     }
            // return  history.pushState(state, '', '/checkout/success')

        } else {
            window.location.href = "/checkout"
        }
    }

    const payWithPayU = async () => {
        const data = {
            payment_method: "payu",
            udf1: user.id,
            udf2: user.user_type,
            udf3: cartItems?.length,
            udf4: deliveryCharge,
            udf5: couponsAmt,
            amount: payableAmt,
            firstname: user.name,
            email: user.email,
            phone: user.phone,
            productinfo: "product checkout"
        }
        const res = await GetPayment.createCheckoutPaymentOrder(data);
        console.log(res);
        if (res && res.status === 200) {
            setTxnid(res.txnid)
            setHash(res.hash)
            document.getElementById('payformChechout').submit();
        }

    }
    const address = `${deliveryAddress?.address}, ${deliveryAddress?.locality}, ${deliveryAddress?.city}, ${deliveryAddress?.state}, ${deliveryAddress?.pincode}`;
    return (
        <section className="checkoutsection">
            {
                isLoading ? <div className='loading-container'>
                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" />
                </div> : <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="userinfo">
                                <div className="phoneverification">
                                    <div className="row">
                                        <div className="col-sm-1 col-1">
                                            {
                                                parseInt(user?.verified) === 1 ? <img src="/images/check.png" alt='' /> : <img src="/images/check-(x).png" alt='' />
                                            }

                                        </div>
                                        <div className="col-sm-11 col-10">
                                            <p>Phone Verification</p>
                                            <p className="text-grey">{user?.phone}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="phoneverification">
                                    <div className="row">
                                        <div className="col-sm-1 col-1">
                                            <img src="/images/check.png" alt='' />
                                        </div>
                                        <div className="col-sm-7 col-10">
                                            <p>Delivery Address</p>
                                            {
                                                deliveryAddress && deliveryAddress !== null && <p className="text-grey">{deliveryAddress.address}, {deliveryAddress.locality}, {deliveryAddress.city}, {deliveryAddress.state}, {deliveryAddress.pincode}</p>
                                            }

                                        </div>
                                        {/* <div className="col-sm-4 text-right text-center-xs">
                      <p><a href="#addressModal" className="checkout-btn" data-toggle="modal">Change Address</a></p>
                    </div> */}
                                    </div>
                                </div>
                                <div className="paymentdiv">
                                    <div className="row">
                                        <div className="col-sm-1">
                                            <span className="checkoutnumber">3</span>
                                        </div>
                                        <div className="col-sm-11 col-12">
                                            {
                                                totalPrice !== 0 && <>
                                                    <form action='https://test.payu.in/_payment' method='post' id="payformChechout">
                                                        <input type="hidden" name="key" value={key} />
                                                        <input type="hidden" name="txnid" value={txnid} />
                                                        <input type="hidden" name="productinfo" value="product checkout" />
                                                        <input type="hidden" name="amount" value={payableAmt} />
                                                        <input type="hidden" name="email" value={user?.email} />
                                                        <input type="hidden" name="firstname" value={user?.name} />
                                                        <input type="hidden" name="surl" value={`${API_URL}/api/payu-checkout-responce`} />
                                                        <input type="hidden" name="furl" value={`${API_URL}/api/payu-checkout-responce`} />
                                                        <input type="hidden" name="phone" value={user?.phone} />
                                                        <input type="hidden" name="udf1" value={user?.id} />
                                                        <input type="hidden" name="udf2" value={user?.user_type} />
                                                        <input type="hidden" name="udf3" value={cartItems?.length} />
                                                        <input type="hidden" name="udf4" value={deliveryCharge} />
                                                        <input type="hidden" name="udf5" value={couponsAmt} />
                                                        <input type="hidden" name="address1" value={deliveryAddress.address} />
                                                        <input type="hidden" name="address2" value={deliveryAddress.locality} />
                                                        <input type="hidden" name="city" value={deliveryAddress.city} />
                                                        <input type="hidden" name="state" value={deliveryAddress.state} />
                                                        <input type="hidden" name="zipcode" value={deliveryAddress.pincode} />
                                                        <input type="hidden" name="hash" value={hash} />
                                                    </form>

                                                    <h4><strong>Payment</strong></h4>
                                                    <div className="paymentinfo">
                                                        <div className="total">
                                                            <p className="text-grey">Total Amount<span className="pull-right"><i className="fa fa-rupee" /> {totalPrice ? totalPrice : null}</span></p>
                                                            <p className="text-grey">Delivery Charge<span className="pull-right"> + <i className="fa fa-rupee" /> {deliveryCharge}</span></p>
                                                            {couponsAmt !== 0 && <p className="text-grey">Coupon Discount<span className="pull-right"> - <i className="fa fa-rupee" /> {couponsAmt}</span></p>}
                                                            <p className="btop-dotted">Amount Payable<span className="text-grey font12">(incl. of all taxes)</span><span className="pull-right"><i className="fa fa-rupee" /> {payableAmt ? payableAmt : null}</span></p>
                                                        </div>
                                                        {
                                                            couponsAmt === 0 && <div className="promocode">
                                                                <div className='promocode-main'>
                                                                    <div>
                                                                        <img src="images/promo.png" alt="promo" className="promoimg" /><span className="text-green">Promo code &amp; Bank offer</span>
                                                                    </div>
                                                                    <div className=''>
                                                                        <Link to="#" data-toggle="modal" data-target=".bd-example-modal-lg" className="text-green font18"><i className="fa fa-angle-right" /></Link>
                                                                    </div>
                                                                </div>

                                                                <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-md modal-dialog-centered mw-430">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title " id="exampleModalLabel">Have a promo code ?</h5>
                                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                    <span aria-hidden="true">×</span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="modal-body bg-light-grey">

                                                                                <div className='apply-coupon show'>
                                                                                    <div className='form-group mt-10'>
                                                                                        <div className="input-group coupon_box">
                                                                                            <input type="text" className="form-control" value={couponCode} onChange={onChangeCoupon} placeholder="Enter coupon code" />
                                                                                            <div className="input-group-append" onClick={couponCode !== '' ? applyCoupon : undefined}>
                                                                                                <span className={couponCode !== '' ? 'input-group-text apply' : 'input-group-text'}>Apply</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {couponError !== '' && <span className='promo-code-error'> <span className='invalid_code'><i className="fa fa-exclamation-circle" /> Invalid Code </span> {couponError}</span>}
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    coupons && coupons.length !== 0 &&
                                                                                    <div className='coupon-box'>
                                                                                        <ul>
                                                                                            {
                                                                                                coupons.map((couponsItem, i) => {
                                                                                                    if (parseInt(couponsItem.status) === 1 && compareTwoDatesForStart.call(this, couponsItem.start_date) && compareTwoDatesForEnd.call(this, couponsItem.end_date)) {

                                                                                                        return <li key={i}>
                                                                                                            <div className='couponItem'>
                                                                                                                <div className='coupon_code'>
                                                                                                                    <span>{couponsItem.coupon_code}</span>
                                                                                                                </div>
                                                                                                                <div className='coupon_description'>
                                                                                                                    <span>{couponsItem.description}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    } else {
                                                                                                        return null;
                                                                                                    }

                                                                                                })
                                                                                            }

                                                                                        </ul>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>
                                                        }
                                                        <div className='owo-wallet'>
                                                            <div className='pay-with-wallet'>
                                                                <p className='text-green'>Wallet Balance <br /><i class="fa fa-inr" /> {wallet === null ? '0' : wallet?.amount}</p>
                                                                <button className='btn btn-block btn-dark wallet-btn ' onClick={!walletLoading ? payWithOWOWallet : undefined}> <img src={process.env.PUBLIC_URL + '/images/wallet-icon.jpg'} alt='' /> {walletLoading ? 'Please wait...' : 'Pay With OWO Wallet'}  </button>
                                                            </div>

                                                        </div>

                                                        <button className='payWithpayU' onClick={payWithPayU}> Pay With <img src="/images/payu.png" alt="img1" className="img-responsive" /></button>

                                                        <p className='text-center'>-----------OR-----------</p>

                                                        <Payment productsDetails={cartItems} order_amount={payableAmt} user={user} createOnlineOrder={createOnlineOrder} createCODOrder={createOrder} />


                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="revieworder">
                                <h4><strong>My Cart ({cartItems?.length} items)</strong></h4>
                                <table className="table table-revieworder">
                                    <tbody>

                                        {
                                            cartItems?.map((item, i) => {
                                                return <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td><a href="#"><img src={IMAGE_URL + item.product.thumbnail_img} alt='' className='chImg' /></a></td>
                                                    <td><p><a href="#" className="text-black">{item.product.product_name}</a></p>
                                                        <p><a href="#" className="text-green"> {item.product.brand_name}</a></p>
                                                        <p> {item.varient !== null && item.varient?.variant_name}</p></td>
                                                    <td><i className="fa fa-rupee" />{item.purchage_price}</td>
                                                </tr>
                                            })
                                        }


                                    </tbody></table>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </section>
    )
}

export default Checkout