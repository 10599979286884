import React, { useState } from 'react'
import { IMAGE_URL } from '../../config'
const SingleProductSlider = ({ productImages }) => {
  const [current, setCurrent] = useState(0);
  const length = productImages.length;
  const nextSlide = () => {
    setCurrent(current === length -1 ? 0 : current+1);
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length -1 : current-1);
  }
  if (!Array.isArray(productImages) || productImages.length <= 0) {
    return null;
  }
  return (
    <section className='slider'>
      <div className='right-arrow' onClick={nextSlide}> <i className="fa fa-angle-right"></i></div>
      <div className='left-arrow' onClick={prevSlide}><i className="fa fa-angle-left"></i></div>
      {
        productImages?.map((item, i) => {
          return <div key={i} className={i === current ? 'slide active' : 'slide'}>
            {
              i === current && (<img src={IMAGE_URL + item} alt="img1" className='image' />)
            }
            
            </div>
        })
      }
    </section>
  )
}

export default SingleProductSlider