import { Apis } from '../../config';
import api from '../../ApiConfig';
import { toast } from 'react-toastify';
const getCategories = async () =>{
    try {
        let result = await api.get(Apis.GetCategories);
        return result.data;
    } catch (error) {
        toast.error('Unable to fetch Categories!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        throw Error('Unable to fetch Categories');
        
    }
}
const getSubCategory = async (slug) =>{
    try {
        let result = await api.get(Apis.GetSubCategory + slug);
        return result.data;
    } catch (error) {
        toast.error('Unable to fetch subcategory!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        throw Error('Unable to fetch subcategory')
    }
}

const getSingleCategory = async (slug) =>{
    try {
        let result = await api.get(Apis.GetSingleCategory + slug);
        return result.data;
    } catch (error) {
        // toast.error('Unable to fetch subcategory!', {
        //     position: "bottom-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     });
        return error
        // throw Error('Unable to fetch category')
    }
}
const getSubSubCategory = async (slug) =>{
    try {
        let result = await api.get(Apis.GetSubSubCategory + slug);
        return result.data;
    } catch (error) {
        toast.error('Unable to fetch subcategory!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        throw Error('Unable to fetch category')
    }
}
const exportedObject = {
    getCategories,
    getSubCategory,
    getSingleCategory,
    getSubSubCategory
};
export default exportedObject;