
const API_URL = "http://staging.owowaas.com";
const IMAGE_URL = "http://staging.owowaas.com/storage/";

//  const API_URL = "http://127.0.0.1:8000";
// const IMAGE_URL = "http://127.0.0.1:8000/storage/";



const Apis = {
  GetLogo:`${API_URL}/api/logo`,
  GetBanners:`${API_URL}/api/banner`,
  GetDealOfTheDay:`${API_URL}/api/product/deal-of-the-day`,
  GetNewArrivals:`${API_URL}/api/product/new-arrivals`,
  GetAlsoLike:`${API_URL}/api/product/you-may-like`,
  GetTrending:`${API_URL}/api/product/trendings`,
  GetCategories:`${API_URL}/api/categories`,
  GetSubCategory:`${API_URL}/api/sub-categories/`,
  GetSingleCategory:`${API_URL}/api/category/`,
  GetSubSubCategory:`${API_URL}/api/sub-sub-categories/`,
  GetSingleProduct:`${API_URL}/api/products/`,
  GetSimilarProducts:`${API_URL}/api/similar-products`,
  GetAllProduts:`${API_URL}/api/products`,
  ProdutSearch:`${API_URL}/api/product/search`,
  GetAllCategories:`${API_URL}/api/categories`,

  //Inner Pages
  GetAboutData:`${API_URL}/api/pages/about-us`,
  GetOurCommitmentData:`${API_URL}/api/pages/our-commitments`,
  GetHowItWorksData:`${API_URL}/api/pages/how-it-works`,
  GetTestimonialsData:`${API_URL}/api/pages/testimonials`,
  GetPressData:`${API_URL}/api/pages/press`,
  GetPrivacyPolicyData:`${API_URL}/api/privacy-policy`,
  GetRefundPolicyData:`${API_URL}/api/refund-policy`,
  GetReturnPolicyData:`${API_URL}/api/return-policy`,
  GetShippingPolicyData:`${API_URL}/api/shipping-policy`,

  //Authentication
  GetRegister:`${API_URL}/api/register`,
  GetSignUpNextStep:`${API_URL}/api/register/company_details`,
  GetLogin:`${API_URL}/api/login`,
  GetVerifyOtp:`${API_URL}/api/verify-otp/`,
  GetUser:`${API_URL}/api/profile/`,
  ForgetPassword:`${API_URL}/api/forgot-password`,
  ResetPassword:`${API_URL}/api/confirm-password`,
  UpdateProfile:`${API_URL}/api/update-profile/`,

  //Cart
  AddToCart:`${API_URL}/api/user/addToCart`,
  UserCartItem:`${API_URL}/api/user/cart/`,
  RemoveFromCart:`${API_URL}/api/user/removeFromCart`,
  UpdateCartQty:`${API_URL}/api/user/updateCart`,

  //wishlist
   AddToWishlist:`${API_URL}/api/user/add-wishlist`,
   RemoveFromWishlist:`${API_URL}/api/user/remove-wishlist`,
   GetAllWishlistItem:`${API_URL}/api/user/wishlist/`,

   //Address 
   AddNewAddress:`${API_URL}/api/new-address`,
   GetAddressList:`${API_URL}/api/address/`,
   DeleteAddress:`${API_URL}/api/delete-address/`,
   GetSigleAddress:`${API_URL}/api/address/`,
   UpdateAddress:`${API_URL}/api/update-address/`,
   SetDefaultAddress:`${API_URL}/api/set-default-address/`,
   GetDefaultAddress:`${API_URL}/api/get-default-address/`,
   
   //Update password
   UpdatePassword:`${API_URL}/api/update-password/`, 

   //Delivery Charge
   GetDeliveryCharge:`${API_URL}/api/delivery-charge`, 

   //Coupon
   GetCoupon:`${API_URL}/api/coupons`, 
   
   //Create Order
  //  CreateOrder:`${API_URL}/api/order/store`, 
   CreateOrder:`${API_URL}/api/order/store`, 
   GetUserOrder:`${API_URL}/api/user/orders`, 

   //Send Otp
   SendOTP:`${API_URL}/api/resend-otp`,
   ResendOTP:`${API_URL}/api/resend-otp`,

   //Add money to wallet
   GetWalletMoney:`${API_URL}/api/user/wallet`,
   AddMoneyToWallet:`${API_URL}/api/add-money-to-wallet`,
   VerifyPaymentWallet:`${API_URL}/api/process-wallet-money`,
   ProcessWalletOrder:`${API_URL}/api/process-wallet-order`,
   VerifyCashfreePayment:`${API_URL}/api/verify-wallet-cashfree-payment`,
   GetCashfreePaymentStatus:`${API_URL}/api/get-cashfree-payment-status`,
   

   //pincode
   GetAllPincodes:`${API_URL}/api/pincodes`,
   GetSinglePincode:`${API_URL}/api/pincodes/`,

   //checkout payment
   CreateCheackoutPaymentOrder:`${API_URL}/api/payment/order/create`,
   ConfirmCheckoutPaymentStatus:`${API_URL}/api/payment/order/verify`,

   //Contact Enquiry
   ContactSubmit:`${API_URL}/api/contact/enquiry`,
  
  
};
export { API_URL, Apis, IMAGE_URL };
