import React from 'react'
import { useQuery } from 'react-query';
import { GetInnerPages } from '../../../helpers';
import parse from 'html-react-parser';
const RefundPolicy = () => {
    const { status, data, error } = useQuery('refundPolicy', GetInnerPages.getRefundPolicyData);
    const mainData = data?.data;
    return (
        <>
            <section className="breadcrumbs sectiondiv">
                <div className="container">
                    <div className='page-title'>
                        <h1 className="text-center text-color1">Refund Policy</h1>
                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    {status === "loading" ? (
                        "Loading..."
                    ) : status === "error" ? (
                        <span>Error: {error.message}</span>
                    ) : (
                        <div className='inner-page-content'>
                          {parse(mainData && mainData !== null ?mainData.content : '')}
                        </div>
                    )
                    }
                </div>
            </section>
        </>
    )
}

export default RefundPolicy