import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { parseCookies } from 'nookies'
const cookie = parseCookies()
const Protected = ({component: Cmp,...rest}) => (
    <Route
    {...rest}
        render={(props)=>{
            if(cookie.auth && cookie.auth === 'true'){
                 return <Cmp {...props} />
            }else{
                return <Redirect
            to={{
            pathname: "/login",
            state: { pre_path: window.location.pathname }
          }}
        />
            }
        }}
    />
)
export default Protected; 