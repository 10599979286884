import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { removeFromWishlist, loadingWishlist } from '../../../store/actions/wishListActions';
import { IMAGE_URL } from '../../../config';
import WishListItem from './WishListItem';
import {GetProducts} from '../../../helpers'
import { parseCookies } from 'nookies'
import { addToCart, loadingToggleAction, incrementToCart, decreaseToCart } from '../../../store/actions/cartActions';
import { Link } from 'react-router-dom';
const cookie = parseCookies()

const WishList = () => {
   // const [ isLoading, setLoading] = useState(true);
   // const [wishitems, setWishitem] = useState([]);
    const dispatch = useDispatch();
//    const getWishList = async () =>{
//        const user = JSON.parse(cookie.user);
//     const data = {
//         user_id:user.authId,
//         user_type:user.auth_type
//     }
    
//     const res = await GetProducts.getAllWishlistItems(data);
//     if(res && res.data !== null){
//         setLoading(false)
//         setWishitem(res.data)
//     }
//    }

//     useEffect(() => {
//         getWishList();
//     },[])
    const wishitems = useSelector(state => state.wishlist.wishItems);

    const showWishLoading = useSelector(state => state.wishlist.showWishLoading);

    const removeWishItem = (del_id) => {

        dispatch(loadingWishlist(true));
        dispatch(removeFromWishlist(del_id))
    }
// const addCart = (data, wish_id) =>{
//     dispatch(loadingWishlist(true));
//     dispatch(addToCart(data, wish_id));

//    // window.location.reload();
// }
    return (
        <>
            <section className="breadcrumbs sectiondiv">
                <div className="container">
                    <div className='page-title'>
                        <h1 className="text-left text-color1">My Wishlist</h1>
                    </div>
                </div>
            </section>
            <section>

                <div className='container'>
                    <div className='wish-container'>
                        {
                            showWishLoading && <div className='loader'>
                                <div className="lds-dual-ring"></div>
                            </div>
                        }
                        <div className='row'>
                            <ul className='productlists mt-30'>
                                {
                                  wishitems && wishitems.length !== 0 ? <>
                                        {
                                            wishitems.map((item, i) => {
                                                return <WishListItem key={i} item={item} removeWishItem={removeWishItem} />
                                            })
                                        }
                                    </> : <div className='wishItem-not-found'>
                                          <div className='wishlist-inner-not-found'>
                                                <h3>YOUR WISHLIST IS EMPTY</h3>
                                                <p>Add items that you like to your wishlist. Review them anytime and easily move them to the bag.</p>
                                                <Link to="/">Continue to Shopping</Link>
                                          </div>
                                    </div>
                                }


                            </ul>
                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}

export default WishList