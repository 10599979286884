import React,{useState} from 'react'
import { Formik } from 'formik';
import {GetHome} from '../../../helpers'
import { toast } from 'react-toastify';
const ContactUs = () => {
const [isLoading, setLoading] = useState(false)
  const sendEquiry = async (values, {resetForm})=>{
    setLoading(true)
        const res = await GetHome.contactSubmit(values);
     
        if(res && res.status){
          toast.success(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
           
            setLoading(false)
            resetForm();
        }else{
          setLoading(false)
        }
       
  }
  return (
    <div className='contact-wrapper'>
    <section className="sec1">
      <div className="container">
        <div className="row">
          <img src="/images/banner-contact.jpg" className="img-responsive" alt=""/>
        </div>
        <div>
        </div></div></section>
    <section className>
      <br /><br />
      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-20">
            <h3 className="text-color1 text-bold text-size1 font-gotham">
              CONTACT US
            </h3>
            <p className="font-16">If you have any enquiry or any questions, please reach us, call us or  fill out the form below.<br />We will get back to you soon as possible.</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 mt-20">
         
          <Formik
       initialValues={{ name: '', email: '', phone:'', subject:'', message:''}}
       validate={values => {
         const errors = {};
         if (!values.name) {
          errors.name = 'Name is required.';
        }

         if (!values.email) {
           errors.email = 'Email is required.';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }

         if (!values.phone) {
          errors.phone = 'Phone number is required';
        }

        if (!values.subject) {
          errors.subject = 'Subject is required';
        }

        if (!values.message) {
          errors.message = 'Message is required';
        }
         return errors;
       }}
       onSubmit={sendEquiry}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <form onSubmit={isLoading ? undefined : handleSubmit}>
              <div className="row">
               
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Your Name</label>
                    <input type="text" 
                    name="name"
                    className="form-control c-feild" 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    />
                     {errors.name && touched.name && <span className='help-error'>{errors.name}</span>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" 
                    className="form-control c-feild" 
                    name="email" 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    />
                     {errors.email && touched.email && <span className='help-error'>{errors.email}</span>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mt-20">
                    <label>Telephone Number</label>
                    <input type="text" 
                    className="form-control c-feild" 
                    name="phone" 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    />
                     {errors.phone && touched.phone && <span className='help-error'>{errors.phone}</span>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mt-20">
                    <label>Subject</label>
                    <input type="text" 
                    className="form-control c-feild" 
                    name="subject" 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subject}
                    />
                     {errors.subject && touched.subject && <span className='help-error'>{errors.subject}</span>}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mt-20">
                    <label>Message</label>
                    <textarea 
                    className="form-control mc-feild" 
                    rows={6} 
                    name="message" 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    />
                     {errors.message && touched.message && <span className='help-error'>{errors.message}</span>}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mt-20">
                      <button type='submit' disabled={isLoading} className="btn-contact">{isLoading ? 'Please Wait..' : 'Send Message'}</button>
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="errcontact" />
                  <p className="sucmsg" />
                  <div className="loadingcontact">
                    {/* <img src="images/loader.gif" alt="loader"> */}
                  </div>
                </div>
              </div>
            </form>  )}
     </Formik>
          </div>
          <div className="col-md-4 mt-20">
            <h3 className="text-bold text-color2">REGISTERED OFFICE</h3>
            <p className="font-16">
              OWO Technologies,<br />
              Spaze ITech Park,<br />
              Sector- 49,<br />
              Gurugram - 122002
            </p>
            <h3 className="text-bold text-color2">CUSTOMER SERVICE</h3>
            <p className="font-16">
              <strong>Tel :</strong> +91 9870333820<br />
              <strong>Email :</strong> info@owo.in
            </p>
          </div>
        </div>	
      </div>
    </section>
    <p className="mt-100" />
  </div>
  )
}

export default ContactUs