import React from 'react'

const Career = () => {
    return (
        <div className='contact-wrapper career'>
            <section className="sec1">
                <div className="container">
                    <img src="/images/bnr_career.jpg" className="img-responsive" alt="" />
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-20">
                            <h4 className="text-color1 text-bold text-size1 font-gotham">
                            Career
                            </h4>
                            <p className="font-16">At OWO, we believe in fostering human capital. We have a robust role evaluation system, which forms the basis of our development process, defining responsibilities, expertise and skills for a strong outcome. Our performance evaluation systems are designed to bring the best in people. We create strong feedback channels, collaboration opportunities between managers and teams for responsibilities, expertise and behavior to bring the best outcomes.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    <h4 className="text-color1 text-bold text-size1 font-gotham">
                        Available jobs
                    </h4>
                    <p>Send your resume at <a href='mailto:careers@owo.in'>careers@owo.in</a> </p>
                    <div className="accordion career-accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Area sales manager
                                    </button>
                                    <i className="fa fa-angle-down rotate-icon"></i>
                                </h2>
                            </div>
                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">

                                    <h5> Job profile</h5>
                                    <p>Fresh MBAs' or graduates with 2-3 years of experience in selling eyewear, to be inducted as Management Trainees & post confirmation to be designated as Area Sales Executives. They would be posted in state capitals acaross India like Jaipur, Dehradun, Patna, Ranchi, Indore/Bhopal, Raipur, Guwahati, Cochin, Bhubaneshwar, Ahmedabad, etc. Candidates (female/male), who hail from these cities, are encouraged to apply for a fast track career with us. </p>

                                    <h5>Criteria</h5>
                                    <p>The job entails extensive travelling; mandatorily carrying sample kits and candidates with their own cars are preferred. We offer rigorous yet innovative trainings, as well as the best salaries & incentives in the industry in India.</p>

                                    <h5>Criteria 2</h5>
                                    <p>The job entails extensive travelling; mandatorily carrying sample kits and candidates with their own cars are preferred. We offer rigorous yet innovative trainings, as well as the best salaries & incentives in the industry in India.</p>

                                    <h5>Criteria 2</h5>
                                    <p>The job entails extensive travelling; mandatorily carrying sample kits and candidates with their own cars are preferred. We offer rigorous yet innovative trainings, as well as the best salaries & incentives in the industry in India.</p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Area service head
                                    </button>
                                    <i className="fa fa-angle-down rotate-icon"></i>
                                </h2>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body">
                                    Some placeholder content for the second accordion panel. This panel is hidden by default.
                                </div>
                            </div>
                        </div>
                        <div className="card ">
                            <div className="card-header" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Area service head
                                    </button>
                                    <i className="fa fa-angle-down rotate-icon"></i>
                                </h2>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Career