import React from 'react'
import { useSelector } from "react-redux";
import { Formik, Field } from 'formik';
import { GetAuth } from '../../../helpers';
import { toast } from 'react-toastify';
const ChangePassword = () => {
    
    const updatePassword = async (values, { resetForm }) =>{
        
      const res = await GetAuth.updateUserPassword(values.new_password);
      if(res && res.status === 200){
        resetForm();
        toast.success(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }
    }  
  return (
    <div >
      <div className="dashboard-title">
          <h4 className="user-heading">Change Password</h4>
      </div>
      <div className="dashboard-content mw-350">
      <Formik
       initialValues={{ new_password: '', confirm_password: '' }}
       validate={values => {
         const errors = {};
         if (!values.new_password) {
           errors.new_password = 'Password is required.';
         } 
         if (!values.confirm_password) {
           errors.confirm_password = 'Confirm password is required.';
         }else if(values.new_password !== values.confirm_password){
            errors.confirm_password = 'Confirm password does not match.';
         } 
         return errors;
       }}
       onSubmit={updatePassword}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
              <form onSubmit={handleSubmit}>
                <div className='form-group'>
                    <input type="password" 
                    className='form-control add-input mb-3 pac-target-input' 
                    name="new_password" 
                    placeholder='New Password' 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.new_password}
                    />
                    {errors.new_password && touched.new_password && errors.new_password}
                </div>
                <div className='form-group'>
                    <input type="password" 
                    className='form-control add-input mb-3 pac-target-input' 
                    name="confirm_password" 
                    placeholder='Confirm Password' 
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirm_password}
                    />
                    {errors.confirm_password && touched.confirm_password && errors.confirm_password}
                </div>
                <button type="submit" className='btn btn-address'>Submit</button>
              </form>
              )}
     </Formik>
          
           
      </div>
          
        
    </div>
  )
}

export default ChangePassword