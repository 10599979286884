import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Field } from 'formik';
import { GetAuth } from '../../../helpers'
import OtpInput from 'react-otp-input';
import { parseCookies, setCookie } from 'nookies'
import RegisterNextStep from './RegisterNextStep';
import ResendOpt from './ResendOpt';
const cookie = parseCookies();
const Register = (props) => {
    const [isCorporate, setCorporate] = useState(false);
    const [otp, setOtp] = useState('');
    const [isDisabled, setDisabled] = useState(false)
    const [numInputs, setnumInputs] = useState(6);
    const [isSendOtp, setSendOtp] = useState(false);
    const [submitData, setSubmitData] = useState(null);
    const [otpErrorResp, setOtpErrorResp] = useState(null);
    const [isNextStep, setNextStep] = useState(false);
    const [isLoading, setLoading] = useState(false)
   const [resError, setResError] = useState('');
    const handleOtp = async (opt) => {
        setOtpErrorResp('');
        setOtp(opt);
    }
    const submitSignIn = async (values) => {
        const res = await GetAuth.getRegister(values);
        console.log(res);
        if(res.data){
            if(res.data.user.user_type === 'corporate'){
                setSubmitData(res.data);
                setNextStep(true)
            }else{
                setSubmitData(res.data);
                persistUserDetails(res.data);
                setSendOtp(true);
            }
             
        }else if(res.status === 401){
            setResError(res.message)
        }
    }
  
    const signupNextStep = async (data) =>{
      const postData = {
          user_id:submitData.user.id,
          user_type:submitData.user.user_type,
          legal_name:data.legal_name,
          gst:data.gst,
          state:data.state,
          pan:data.pan
      }

      const res = await GetAuth.getSignUpNextStep(postData);
      if(res.status === 200){
        persistUserDetails(submitData);
        setNextStep(false);
        setSendOtp(true);
      }
      console.log(res);
    }

   const persistUserDetails = (data) => {
    const user = {
        authId: data.user.id,
        auth_type:data.user.user_type
    }
       
    setCookie(null, 'auth', 'false', {
        sameSite:true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })

    setCookie(null, 'auth_access', data.access_token, {
        sameSite:true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })

    setCookie(null, 'user', JSON.stringify(user), {
        sameSite:true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })

    };

    const clearOtp = () => {
        setOtpErrorResp('');
        setOtp('');

    };

    const getOtp = async () => {
        setLoading(true)
        const res = await GetAuth.getVerifyOtp(submitData, otp);
        if(res.status === 401){
            setOtpErrorResp(res.message);
            setLoading(false)
        }
        if(res.status === 200){
            setCookie(null, 'auth', 'true', {
                sameSite:true,
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
              })
        
            setTimeout(function () {
                window.location.href = '/'; //will redirect to your home
            }, 2000); //will call the function after 2 secs.
        }  
    }
   
    if (cookie.auth && cookie.auth === 'true') {
        props.history.push('/');
    }

    return (
        <section className="login-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4" />
                    <div className="col-lg-4">
                        <div className="loginbox shadow">
                            <img src="images/owo-blue-logo.png" className="loginlogo d-block mx-auto" alt='' />
                           
                             {
                                 isNextStep && <RegisterNextStep signupNextStep={signupNextStep} /> 
                             }
                            {
                              !isNextStep && isSendOtp ?  <div className='otp-box pt-4'>
                                    <h4 className='fw-700 mrb-5'>Verify With OTP</h4>
                                    <p>Sent to {submitData?.user?.phone  }</p> 
                                <OtpInput
                                    inputStyle="inputStyle"
                                    errorStyle="error"
                                    shouldAutoFocus
                                    value={otp}
                                    onChange={handleOtp}
                                    numInputs={numInputs}
                                    isDisabled={isDisabled}
                                    isInputNum={true}
                                    separator={<span>-</span>}
                                />
                                <div className="btn-row">
                                    <button
                                        className="btn clear-btn margin-top--large"
                                        type="button"
                                        disabled={isDisabled || otp.trim() === ''}
                                        onClick={clearOtp}
                                    >
                                        Clear
                                    </button>
                                    <button className="btn sb-btn margin-top--large btn-green" disabled={otp.length < numInputs} onClick={getOtp}>
                                       Verify
                                    </button>
                                    

                                </div>
                                 <ResendOpt submitData={submitData}/>
                                {
                                   isLoading && <div className='loading-container mh50'>
                                   <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" /> Please wait..
                                 </div>
                                }
                                 {otpErrorResp !== null && otpErrorResp !== '' ? <span className='promo-code-error mt-10'><span><i className="fa fa-exclamation-circle"/> {otpErrorResp}</span></span> : null}
                                
                            </div> :  !isNextStep &&  <> <h4>Register for new user</h4>
                            {resError !== '' &&  <div className='alert alert-danger'>
                               <i className="fa fa-exclamation-circle"/> {resError}
                                </div>}
                            <Formik
                                initialValues={{ user_type: '', name: '', email: '', phone: '', company_name: '', password: '' }}
                                validate={values => {
                                    setResError('');
                                    const errors = {};
                                    if (!values.user_type) {
                                        errors.user_type = 'Please select user type.';
                                    }
                                    if (values.user_type === 'corporate') {
                                        setCorporate(true)
                                        if (!values.company_name) {
                                            errors.company_name = 'Company name is required.';
                                        }
                                    } else {
                                        values.company_name = '';
                                        setCorporate(false)
                                    }
                                    if (!values.name) {
                                        errors.name = 'Name is required.';
                                    }
                                    if (!values.email) {
                                        errors.email = 'Email is required.';
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                    ) {
                                        errors.email = 'Invalid email address';
                                    }
                                    if (!values.phone){
                                        errors.phone = 'Phone number is required.';
                                    }else{
                                        // const regex = '/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/';
                                        if(!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(values.phone)){
                                            errors.phone = 'Please enter 10 digit valid phone number.';
                                        }

                                    }
                                    if (!values.password) {
                                        errors.password = 'Password is required.';
                                    }
                                    return errors;
                                }}
                                onSubmit={submitSignIn}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (<form onSubmit={handleSubmit}>
                                    <div className="form-group user_type">
                                        <div role="group" aria-labelledby="my-radio-group" className='d-flex'>
                                            <label className='mr-3 mb-0'>
                                                <Field type="radio" name="user_type" value="individual" className="mr-1"/>
                                                Individuals
                                            </label>
                                            <label className='mb-0'>
                                                <Field type="radio" name="user_type" value="corporate" className="mr-1"/>
                                                Corporate
                                            </label>

                                        </div>
                                        <span className='help-error'>{errors.user_type && touched.user_type && errors.user_type}</span>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            placeholder="Your full name"
                                            className="form-control"
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        />
                                        <span className='help-error'>{errors.name && touched.name && errors.name}</span>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            placeholder="Email Address"
                                            className="form-control"
                                            name="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                        />
                                         <span className='help-error'>{errors.email && touched.email && errors.email}</span>
                                       
                                         
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            placeholder="Phone Number"
                                            className="form-control"
                                            name="phone"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.phone}
                                        />
                                         <span className='help-error'>{errors.phone && touched.phone && errors.phone}</span>
                                    </div>
                                    {
                                        isCorporate ? <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Company Name"
                                                className="form-control"
                                                name="company_name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.company_name}
                                            />
                                             <span className='help-error'>{errors.company_name && touched.company_name && errors.company_name}</span>
                                        </div> : null
                                    }

                                    <div className="form-group">
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            className="form-control"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur} 
                                            value={values.password}
                                        />
                                        <span className='help-error'> {errors.password && touched.password && errors.password}</span>
                                    </div>
                                    <p className="font-small">By continuing you agree to our <Link to="#" className='text-green'>Terms &amp; Conditions</Link></p>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-green btn-block">{isCorporate ? 'NEXT' : 'REGISTER'}</button>
                                        <p className="text-center">Already have an account? <Link to="/login" className='text-green'>Login now.</Link></p>
                                    </div>
                                </form>
                                )}
                            </Formik>
                             
                            </>
                            }
                           



                          
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Register