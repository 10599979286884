import React,{useState, useEffect} from 'react'
import { GetAddress } from '../../helpers';
import { setCookie } from 'nookies'

const AddressLocatorModal = () => {
  const [isLoading, setLoading]= useState(false);
  const [pincodes, setPincodes] = useState([]);
  const [query, setQuery] = useState('');
  const [place, setPlace] = useState('');
  const [places, setPlaces] = useState([]);
  const [error, setError] = useState('');
  // const getPincodes = async()=>{
  //   const res = await GetAddress.getAllPincodes();
  //   if(res && res.status === 200){
  //     setLoading(false)
  //     setPincodes(res.pincodes);
  //     console.log(res.pincodes);
  //   }
  // }
  // useEffect(()=>{
  //   getPincodes();
  // },[])
  const handleChangePincode = (e)=>{
    setError('')
    setQuery(e.target.value)
  }
  const checkPincodes = async()=>{
    setError('')
    
     if(query !== ''){
      setLoading(true)
      const res = await GetAddress.getSinglePincodes(query);
      if(res && res.status === 1){
       setPlaceName();
      }else{
        setError(res.pincodes)
        setLoading(false)
      }
     }else{
      setError('Please enter your pincode.')
     }
   

  }
  const setMultiPlaceNames = async (query)=>{
    const res = await GetAddress.getPincodePlace(query);
    if(res && res[0].PostOffice.length !== 0){
      const district = res[0].PostOffice[0].District;
      const state = res[0].PostOffice[0].State;
      const location = query + ", " + district + ", " + state;
      return location;
    }else{
      return null
    }
  }
  const setPlaceName = async ()=>{
    const res = await GetAddress.getPincodePlace(query);
    if(res && res[0].PostOffice.length !== 0){
      const district = res[0].PostOffice[0].District;
      const state = res[0].PostOffice[0].State;
     // const location = query + ", " + district + ", " + state;
      const location = JSON.stringify({
        pin_code:query,
        district:district,
        state:state
      })
      setPlace(location);
      setCookie(null, 'delivery', location, {
        sameSite:true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })
      setLoading(false)
      window.location.reload();
    }
    
  }
  return (
    <div className="modal  fade mxw-950" id="addressLocatorModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modelLocation" role="document">
      <div className="modal-content">
        <div className="modal-header brd-0">
         
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body addres-modal-body">
            <h6>Select your city to start shopping</h6> 
           <div className=' d-flex align-items-center'>
             <input type="text" className="form-control mr-2" placeholder='Enter pincode' value={query} onChange={handleChangePincode}/>
             <button type='button' className='btn btn-secondary' onClick={checkPincodes}>Apply</button>
            
           </div>
           {isLoading &&  <div className='loading-container mh50 no-justify-content ft-12'>
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" /> Please wait..
                                  </div>}
          <span className='help-error'>{error}</span> 
        </div>
       
      </div>
    </div>
  </div>
  )
}

export default AddressLocatorModal