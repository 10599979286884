import React,{useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useParams } from 'react-router-dom';
const CategoryMenu = ({sub_categories, category, subSubCategories, onCategoryChange}) => {
    const params = useParams()
const [selected, setSelected] = useState(null);
const toggleItem = (i) =>{

    if(selected === i){
        return setSelected(null);
    }
    setSelected(i);
}
 useEffect(() => {
    if(params.subcategory){
          const subCate = sub_categories.find(el=>el.subcategory_slug === params.subcategory);
          setSelected(subCate.id);
    }
 },[params.subcategory])
    return (
        <div className='sub-category-menus'>
            <div className='accordian'>
            {
                sub_categories.map((item, i)=>{
                 return <div className="panel panel-default custom-panel" key={i}>
                    <div className="panel-heading">
                        <h3 className="panel-title" >
                        <Link to={`/${category.category_slug}/${item.subcategory_slug}`} onClick={()=>onCategoryChange(category.category_slug, item.subcategory_slug)}>{item.subcategory_name}</Link>
                            <span onClick={()=>toggleItem(item.id)}>{selected === item.id ? <i className="accordion_icon fa-minus1"></i> : <i className="accordion_icon fa-plus1"></i>}</span>
                        </h3>
                    </div>
                    <div className={selected === item.id ? "panel-collapse content-list show" : "panel-collapse content-list" }>
                        <div className="panel-body">
                            <ul className='subsubcategory'>
                            {
                                item.children.map((item2, i)=>{
                                  return <li key={i}><Link to={`/${category.category_slug}/${item.subcategory_slug}/${item2.subsubcategory_slug}`} onClick={()=>onCategoryChange(category.category_slug, item.subcategory_slug, item2.subsubcategory_slug)} className={params.subsubcategory === item2.subsubcategory_slug ? 'active' : ''}>{item2.subsubcategory_name}</Link></li>
                                })
                            }
                                
                            </ul>
                        </div>
                    </div>
                </div>
                })
            }

            </div>
        </div>
       
       
    )
}

export default CategoryMenu