import React, {useState} from 'react'
import Header from '../partials/Header'
import Footer from '../partials/Footer'
import SearchedData from '../partials/SearchedData'
const PagesLayout = ({children}) => {
const [isShowSearch ,setShowSearch] = useState(false)
const toogleSearchItem = (val) =>{
  if(val){
    setShowSearch(true)
  }else{
    setShowSearch(false)
  }
  
}
  return (
        <>
        <Header toogleSearchItem={toogleSearchItem}/>
           {children}
        
          { <SearchedData isShowSearch={isShowSearch} toogleSearchItem={toogleSearchItem}/>}
        <Footer/>
       </>
  )
}

export default PagesLayout