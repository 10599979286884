import React from 'react'
import SuggestionItem from './SuggestionItem'
const Suggestions = (props) => {
  return (
    <>
      <section ref={props.sgRef} className="cartp searchSuggestion" style={{ display:'block' }}>
    <table className="table suggetion-table" >
      <tbody>
        {
          props.results.map((productItem, i)=>{
            return <SuggestionItem key={i} data={productItem.product}/>
          })
        }
       
      </tbody>
    </table>
  </section>
    </>
  )
}

export default Suggestions