import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { GetAuth } from '../../../helpers';
import $ from "jquery";
import EditProfileModal from './EditProfileModal';
import { fetchusers } from '../../../store/actions/userActions';
import EditProfile from './EditProfile';

const AccountDetails = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.user);
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpErrorResp, setOtpErrorResp] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const onEdit = async () => {
    setLoading(true);
    setOtpErrorResp('');
    setOtp('');
    const res = await GetAuth.sendOTP(userDetails.user.phone);
    if (res && res.data !== null) {
      console.log(res.data.otp);
      // setOtp(res.data.otp);
      setLoading(false);
      $("#editProfileModal").modal("show")
    }
    //setSendOtp(true);

  }
  const verifyOtp = async (otp) => {
    setLoading2(true)
    const res = await GetAuth.getVerifyOtp(userDetails, otp);
    if (res.status === 401) {
      setOtpErrorResp(res.message);
      setLoading2(false)
    }
    if (res.status === 200) {
    
      setIsEdit(true)
     
      $("#editProfileModal").modal("hide");
      setLoading2(false);
    }
  }
  const submitUpdateProfile = async (values) => {
    const res = await GetAuth.updateUserProfile(values);
    if (res && res.data !== null) {
      dispatch(fetchusers());
      setIsEdit(false)
    }
  }

  const handleOtp = async (opt) => {
    setOtp(opt);
    setOtpErrorResp('')
  }

  const clearOtp = () => {
    setOtp('');
  };

  const getOtp = async () => {
    verifyOtp(otp);
  }
  return (
    <>
      <div className="dashboard-title">
        <h4 className="user-heading">My Profile</h4>
      </div>
      <div className="dashboard-content">
        {
          userDetails ? <>
            {
              <ul className="user-details-list">
                {userDetails.user.user_type === 'corporate' ?
                  <>
                    {
                      !isEdit ? <>
                        <li>
                          <div className='label'>
                            <span>
                              Name
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.full_name}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              User Type
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.user_type}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              Email
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.email}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              Phone
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.phone}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              Company Name
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.company_name}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              GST
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.gst}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              Legal Name
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.legal_name}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              State
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.state}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              PAN
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.pan}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              Varified
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.verified === '1' ? <span className="badge badge-success">Yes</span> : <span className="badge badge-danger">No</span>}
                          </div>
                        </li>
                        <button className='btn btn-custom-primary mt-10' type='button' onClick={!isLoading ? onEdit : undefined}>{isLoading ? <><img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" style={{ maxWidth: '20px' }} /> Please wait..</> : 'Edit'}</button>

                      </> : <EditProfile userDetails={userDetails} submitUpdateProfile={submitUpdateProfile} />
                    }
                  </>
                  : <>
                    {
                      !isEdit ? <>
                        <li>
                          <div className='label'>
                            <span>
                              Name
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.name}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              User Type
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.user_type}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              Email
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.email}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              Phone
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.phone}
                          </div>
                        </li>
                        <li>
                          <div className='label'>
                            <span>
                              Varified
                            </span>
                          </div>
                          <div className='value'>
                            {userDetails.user.verified === '1' ? <span class="badge badge-success">Yes</span> : <span class="badge badge-danger">No</span>}
                          </div>
                        </li>

                        <button className='btn btn-custom-primary mt-10' type='button' onClick={!isLoading ? onEdit : undefined}>{isLoading ? <><img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" style={{ maxWidth: '20px' }} /> Please wait..</> : 'Edit'}</button>
                      </> : <EditProfile userDetails={userDetails} submitUpdateProfile={submitUpdateProfile} />

                    }



                  </>
                }
              </ul>
            }
            <EditProfileModal otp={otp} handleOtp={handleOtp} clearOtp={clearOtp} getOtp={getOtp} userDetails={userDetails} verifyOtp={verifyOtp} otpErrorResp={otpErrorResp} setOtpErrorResp={setOtpErrorResp} isLoading2={isLoading2} />
          </> : null
        }
        {

        }

      </div>


    </>
  )
}

export default AccountDetails