import React from 'react'
import Header from '../partials/Header'
import Footer from '../partials/Footer'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector} from "react-redux";

import {unsetUser} from '../../store/actions/userActions'
import {resetCart} from '../../store/actions/cartActions'
import {resetToWishlist} from '../../store/actions/wishListActions'
import { destroyCookie } from 'nookies'

const DashboardLayout = ({children}) => {
  const userDetails = useSelector(state => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const LogOut = () => {
    // Destroy
   
    destroyCookie(null, 'auth', {
      path: '/',
    });
    destroyCookie(null, 'auth_access', {
        path: '/',
      });
      destroyCookie(null, 'user', {
        path: '/',
      });
      dispatch(unsetUser());
      dispatch(resetCart());
      dispatch(resetToWishlist());
      
     window.location.href = '/login'; //will redirect to your home
   
  }
  return (
      <>
      <Header/>
    <section className="cartp2">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2" />
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-4 p-0">
              <div className="bd1">
                <div className="row bdm pd105">
                  <div className="col-md-1" />
                  <div className="col-md-1 p-0 col-3">
                    <img src="/images/user1.png" className="img-fluid mrt-4" alt=''/>
                  </div>
                  <div className="col-md-8 col-9 plxs-0">
                    <h5 className="text-bold text-yellow pt-5">{userDetails?.user.phone}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="morder">
                       <li className={location.pathname === '/user/dashboard' ? "active" : "" }><Link to="/user/dashboard"><i className="fa fa-user"/> My Profile</Link></li>
                      <li className={location.pathname === '/user/save-address' ? "active" : "" }><Link to="/user/save-address"><i className="fa fa-address-book"/> My Address</Link></li>
                      <li className={location.pathname === '/user/change-password' ? "active" : "" }><Link to="/user/change-password"><i className="fa fa-unlock-alt"/> Change Password</Link></li>
                      <li className={location.pathname === '/user/my-orders' ? "active" : "" }><Link to="/user/my-orders"><i className="fa fa-first-order"/> My Orders</Link></li>
                      <li className={location.pathname === '/user/my-wallet' ? "active" : "" }><Link to="/user/my-wallet"> <i className="fa fa-money"/> My Wallet</Link></li>
                      <li><Link to="#" onClick={LogOut}><i className="fa fa-sign-out"/> Logout</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 p-0">
            <div className="bd2">
            {children}
            </div>
            
            </div>
          </div>
        </div>
        <div className="col-md-2" />
      </div>
    </div>
  </section>
  <Footer/>
  </>
  )
}

export default DashboardLayout