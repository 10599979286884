import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query';
import { GetCategories } from '../../helpers';
const MainCategories = () => {
  const { data, isLoading } = useQuery('categories', GetCategories.getCategories);
  const apiData = data?.data;
  const mainCategory = apiData?.slice(0, 8);
  const dropDownSubCategory = apiData?.slice(8, apiData.length)
  return (
    <section className="menu sectiondiv">
      <div className="container">
        <div className="row">
        {
          !isLoading && <ul className="menu2" id="myNavbar">
            {
              mainCategory?.map((item, i) => {
                return <li key={i}><Link to={`/${item.category_slug}`}>{item.category_name}</Link></li>
              })
            }
            {
              dropDownSubCategory && dropDownSubCategory.length ? <li className="dropdown"><a href="#" data-toggle="dropdown" className="ddmenu">More <span className="caret" /></a>
                <ul className="dropdown-menu moremenu">
                  {
                    dropDownSubCategory?.map((innerItem, i) => {
                      return <li key={i}><Link to={`/${innerItem.category_slug}`}>{innerItem.category_name}</Link></li>
                    })
                  }
                </ul></li> : null
            }

          </ul>
        } 
          
          <button type="button" className="navbar-toggle dp-toggle" data-toggle="collapse" data-target="#myNavbar">
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
        </div>
      </div>
    </section>
  )
}

export default MainCategories