import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { IMAGE_URL } from '../../config';
 class ProductCarouselItem extends Component {
  render() {
      const data = this.props.data;

    return (
        <div> <div className="cate-item cate1">
        <img src={IMAGE_URL + data.thumbnail_img} className="img-responsive" alt="cate_img1"/>
        <div className="product-text">
            <p className="text-black">
               <Link to={`/p/${data.category_id}/${data.subcategory_id}/${data.subsubcategory_id}/${data.product_slug}`} >
                   {data.product_name}
                </Link>
            </p>
            {/*-<span class="cate-text1">1kg</span> -*/}
            <select name="pack" className="form-control custom-select ">
               <option value="Pack of 12">Pack of 12</option>
               <option value="Pack of 6">Pack of 6</option>
            </select>
            <p className="text-black">
                Rs {data.purchase_price} <span className="cate-text2">RS {data.mrp}</span>
            </p>
            <div className="qtyfirstdiv pdiv1">
                <div className="col-xs-2 cpadding1 mt0">Qty:</div>
                <div className="col-xs-6 cpadding2"><input type="number" className="form-control custom-select pqty_1" defaultValue={1} /></div>
                <div className="col-xs-4 cpadding3 mt0"><button type="button" className="qtybtn" data-btn={1}>Add +</button></div>
                <div className="clearfix" />
            </div>
            <div className="qtyseconddiv qdiv1">
                <div className="col-md-12 padding0">
                    <div className="input-group">
                        <span className="input-group-btn">
                            <button type="button" className="quantity-left-minus btn btn-number" data-type="minus" data-field={1}>
                                <span className="glyphicon glyphicon-minus" />
                            </button>
                        </span>
                        <input type="text" id="quantity1" name="quantity" className="form-control input-number quantity_1" defaultValue min={1} max={100} />
                        <span className="input-group-btn">
                            <button type="button" className="quantity-right-plus btn btn-number" data-type="plus" data-field={1}>
                                <span className="glyphicon glyphicon-plus" />
                            </button>
                        </span>
                    </div>
                </div>
                <div className="clearfix" />
            </div>
        </div>
    </div></div>
    )
  }
}

ProductCarouselItem.defaultProps = {
    data:{
        product_name: "Organic Keto raw nuts mix pack",
        thumbnail_img:"images/cate_img1.jpg",
        mrp:200,
        purchase_price:100,
        pack:[{
            label:"Pack of 12",
            value:"Pack of 12"
        },{
            label:"Pack of 6",
            value:"Pack of 6"
        }]
    }
   
  }
export default ProductCarouselItem;