import React,{useState} from 'react'
import { Formik, Field } from 'formik';
import { parseCookies } from 'nookies'
import { GetAddress } from '../../../helpers';
import { toast } from 'react-toastify';
import $ from "jquery";

const cookie = parseCookies()
const AddressModal = (props) => {
  const [error, setError] = useState('');
    const saveAddres = async (values, { resetForm }) =>{
        if(cookie.auth && cookie.auth === 'true'){
            const user = JSON.parse(cookie.user);
            const data = {
                user_id:user.authId,
                user_type:user.auth_type,
                address:values.house_no + " " + values.street,
                locality:values.locality,
                pincode:values.pincode,
                city:values.city,
                state:values.state,
                type:values.type,
                default:0
              }
              const res1 = await GetAddress.getSinglePincodes(values.pincode);
              if(res1 && res1.status === 1){
                const res = await GetAddress.addNewAddres(data);
                if(res && res.status === 200){
                  $("#addressModal").modal("hide");
                  resetForm();
                  props.reloadPageData(true);
                  toast.success('Address added successfully !', {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      });
                    
                }
              }else{
                setError(res1.pincodes)
              }
         
        }
      
    }
  return (
    <div className="modal fade" id="addressModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Add new address</h5>
         
          <Formik
       initialValues={{ house_no:'', street:'', locality:'', pincode:'', city:'', state:'', type:'' }}
       validate={values => {
         const errors = {};
         setError('');
         if (!values.locality) {
           errors.locality = 'Locality is required';
         } 
         if (!values.pincode) {
           errors.pincode = 'Pincode is required';
         } 
        
         if (!values.city) {
           errors.city = 'City is required';
         } 
         if (!values.state) {
           errors.state = 'State is required';
         } 
         if (!values.type) {
           errors.type = 'Address type is required';
         } 
         return errors;
       }}
       onSubmit={saveAddres}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="house_no"
                placeholder="House No." 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.house_no}
                />
                
              </div>
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="street" 
                placeholder="Street" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.street}
                />
               
              </div>
              </div>
              <div className="row mb-3">
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="locality" 
                placeholder="Area/Locality" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.locality}
                />
                <span className='help-error'>{errors.locality && touched.locality && errors.locality}</span>
              </div>
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="pincode" 
                placeholder="PIN" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pincode}
                />
                <span className='help-error'>{errors.pincode && touched.pincode && errors.pincode}</span>
                <span className='help-error'>{error}</span>
              </div>
              </div>
              <div className="row mb-2">
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="city" 
                placeholder="City" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.city}
                />
                <span className='help-error'>{errors.city && touched.city && errors.city}</span>
              </div>
              <div className="col-sm-6">
                <input type="text" 
                className="form-control add-input" 
                name="state" 
                placeholder="State" 
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.state}
                />
                <span className='help-error'>{errors.state && touched.state && errors.state}</span>
              </div>
            </div>
            <div className="user_type mb-2">
                    <div role="group" aria-labelledby="my-radio-group" className='d-flex'>
                      <label>
                        <Field type="radio" name="type" value="Home" />
                        Home
                      </label>
                      <label>
                        <Field type="radio" name="type" value="Office" />
                        Office
                      </label>
                      <label>
                        <Field type="radio" name="type" value="Other" />
                        Other
                      </label>
                    </div>
                    <span className='help-error'>{errors.type && touched.type && errors.type}</span>
                  </div>
            {/* <div className="row">
              <div className="col-sm-12">
                <p className="mb-3"><span className="mr-30"><input type="radio" name="addtype" /> Home</span><span className="mr-30"><input type="radio" name="addtype" /> Office</span><span><input type="radio" name="addtype" /> Other</span></p>
              </div>
            </div> */}
            <div className="row mb-4">
              <div className="col-sm-12">
                <button type="submit" className="btn btn-address">Submit</button>
              </div>
            </div>
          </form> )}
     </Formik>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AddressModal