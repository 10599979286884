import React from 'react'
import { Formik } from 'formik';
const EditProfile = ({userDetails, submitUpdateProfile}) => {
    const handleSubmitProfile = (values) =>{
        submitUpdateProfile(values);
    }
  return (
    <div className='account-update'>
        {
            userDetails.user.user_type !== 'corporate' ? <Formik
            initialValues={{ name: userDetails.user.name, email: userDetails.user.email, phone: userDetails.user.phone }}
            validate={values => {
              const errors = {};
              
              if (!values.name) {
                errors.name = 'Name is required';
              }
              if (!values.email) {
                errors.email = 'Email is required';
              }
              if (!values.phone) {
                errors.phone = 'Phone is required';
              }
      
              return errors;
            }}
            onSubmit={handleSubmitProfile}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (<form onSubmit={handleSubmit}>
              <div className='row form-group'>
                <div className='col-md-12'>
                  <label htmlFor=''>User Type</label>
                  <input type="text" className='form-control' value={userDetails.user.user_type} readOnly placeholder="" />
                </div>
              </div>
              <div className='row form-group'>
                <div className='col-md-6'>
                  <label htmlFor=''>Name</label>
                  <input type="text"
                    className='form-control'
                    name='name'
                    placeholder="Enter your name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <span className='help-error'>{errors.name && touched.name && errors.name}</span>
                </div>
                <div className='col-md-6'>
                  <label htmlFor=''>Phone</label>
                  <input type="text"
                    className='form-control'
                    name='phone'
                    placeholder="Enter your phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  <span className='help-error'>{errors.phone && touched.phone && errors.phone}</span>
                </div>
              </div>
      
              <div className='row form-group'>
                <div className='col-md-12'>
                  <label htmlFor=''>Email</label>
                  <input type="text"
                    className='form-control'
                    name='email'
                    placeholder="Enter your email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <span className='help-error'>{errors.email && touched.email && errors.email}</span>
                </div>
              </div>
              <button type='submit' className='btn btn-custom-primary'>Save</button>
            </form>)}
          </Formik> : <Formik
      initialValues={{ name: userDetails.user.full_name, email: userDetails.user.email, phone: userDetails.user.phone }}
      validate={values => {
        const errors = {};
        
        if (!values.name) {
          errors.name = 'Name is required';
        }
        if (!values.email) {
          errors.email = 'Email is required';
        }
        if (!values.phone) {
          errors.phone = 'Phone is required';
        }

        return errors;
      }}
      onSubmit={handleSubmitProfile}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (<form onSubmit={handleSubmit}>
        <div className='row form-group'>
          <div className='col-md-12'>
            <label htmlFor=''>User Type</label>
            <input type="text" className='form-control' value={userDetails.user.user_type} readOnly placeholder="" />
          </div>
        </div>
        <div className='row form-group'>
          <div className='col-md-6'>
            <label htmlFor=''>Name</label>
            <input type="text"
              className='form-control'
              name='name'
              placeholder="Enter your name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <span className='help-error'>{errors.name && touched.name && errors.name}</span>
          </div>
          <div className='col-md-6'>
            <label htmlFor=''>Phone</label>
            <input type="text"
              className='form-control'
              name='phone'
              placeholder="Enter your phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
            />
            <span className='help-error'>{errors.phone && touched.phone && errors.phone}</span>
          </div>
        </div>

        <div className='row form-group'>
          <div className='col-md-12'>
            <label htmlFor=''>Email</label>
            <input type="text"
              className='form-control'
              name='email'
              placeholder="Enter your email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <span className='help-error'>{errors.email && touched.email && errors.email}</span>
          </div>
        </div>
        <button type='submit' className='btn btn-custom-primary'>Save</button>
      </form>)}
    </Formik>
        }
    

    
  </div>
  )
}

export default EditProfile