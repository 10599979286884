import React from 'react'
import { useParams, Link, Redirect } from 'react-router-dom'

const CheckoutResponse = (props) => {
    const params = useParams();
    // if(!props.location.state){
    //     return <Redirect to="/"/>
    //   }
  return (
        <section className="checkoutsection">
            <div className='container'>
             <div className='resp-box'>
              {
                  params.resp === 'success' && <>
                     
                     <img src="/images/correct.png" alt='' className='mxw-64'/>
                     <h1>Your order has been placed.</h1>
                     <Link to="/" className='btn3 mw-200'>Back to Home</Link>
                  </>
              }
              {
                  params.resp === 'failed' && <>
                  <img src="/images/cnl.png" alt='' className='mxw-64'/>
                   <h1>Something went wrong ! Try again.</h1>
                     <Link to="/" className='btn3 mw-200'>Back to Home</Link>
                  </>
              }
              
             </div>
            </div>
        </section>
  )
}

export default CheckoutResponse