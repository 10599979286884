import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IMAGE_URL } from '../../../config';
import { removeFromCart, loadingToggleAction, incrementToCart, decreaseToCart } from '../../../store/actions/cartActions'
import { useSelector, useDispatch } from "react-redux";
import AddressList from './AddressList';
import { GetAddress, GetProducts } from '../../../helpers';
import { parseCookies, setCookie } from 'nookies'
import AddressModal from '../user/AddressModal';

const cookie = parseCookies()

const Cart = ({ isShow, toogleCart, cartRef }) => {
  const dispatch = useDispatch();
  const [toogleAddress, setToggleAddress] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [isDefaultAddres, setIsDefaultAddress] = useState(false)
  const [deliveryCharge, setDeliveryCharge] = useState(null)
  const [isSettingDeafault, settingDeafault] = useState(false);
  const cartItems = useSelector(state => state.cart.cartItems)
  const total_price = useSelector(state => state.cart.total_price)
  const removeFromCartMethod = (del_id) => {
    dispatch(removeFromCart(del_id));
  }
  const updateQty = (val, qty, cart_id) => {
    dispatch(incrementToCart(cart_id));
  }
  const decreaseQty = (val, qty, cart_id) => {
    dispatch(decreaseToCart(cart_id));
  }
  const fetchAddresses = async () => {
    setLoading(true);
    const user = JSON.parse(cookie.user);
    const res = await GetAddress.getAllAddressed(user.authId);
    let isDefault = false
    if (res && res.data) {
      res.data.forEach((x) => {
        if (parseInt(x.default) === 1) {
          isDefault = true
        }
      })
      setIsDefaultAddress(isDefault);
      setAddresses(res.data);
      setToggleAddress(true);
      setLoading(false);
    } else {
      setToggleAddress(true);
      setLoading(false);
    }
  }

  const onBackToCart = () => {
    setAddresses([])
    setToggleAddress(false);
  }

  const reloadPageData = (status) => {
    if (status) {
      fetchAddresses();
    }
  }
  const setDefaultAddress = async (address_id, pinCode) => {
    settingDeafault(true)
    const user = JSON.parse(cookie.user);

    const data = {
      user_id: user.authId,
      user_type: user.auth_type
    }
    const res = await GetAddress.setDefaultAddress(data, address_id);
    const res1 = await GetAddress.getPincodePlace(pinCode);
    if(res1 && res1.length !== 0){
      settingDeafault(false)
      const district = res1[0].PostOffice[0].District;
      const state = res1[0].PostOffice[0].State;
      const location = JSON.stringify({
        pin_code:pinCode,
        district:district,
        state:state
      })
      setCookie(null, 'delivery', location, {
        sameSite:true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })
      
    }

    if (res && res.data) {
      fetchAddresses();
    }
  
  }
  const fetchDeliveryCharges = async () =>{
    const res = await GetProducts.getDeliveryCharge();
    if(res && res.data){
      setDeliveryCharge(res.data);
    }
  }
  useEffect(() => {
    fetchDeliveryCharges();
  },[])    
  return (
    <section ref={cartRef} className={isShow ? "cartpc showCart" : 'cartpc'}>
      {
        toogleAddress ? <>
          <div className='cart-title'>
            <h3 className="text-bold text-black mycart1">  <Link to="#" className="back" onClick={onBackToCart}><i className="fa fa-long-arrow-left backBtn" /></Link>  My Address </h3>
          </div>
          <div className='address-section'>
            <div className='addNewAdd'>
              <a href="#addressModal" className="address-btn" data-toggle="modal"><i className="fa fa-plus" /> Add a new address</a>
            </div>
          </div>

          {
            addresses && addresses.length !== 0 && <AddressList data={addresses} setDefaultAddress={setDefaultAddress} isSettingDeafault={isSettingDeafault} />
          }
          {
            isDefaultAddres ? <div className='continue-btn'>
              <a href="/checkout">Proceed to Continue</a>
            </div> : <div className='continue-btn-desabled'><p>Proceed to Continue</p></div>
          }


          <AddressModal reloadPageData={reloadPageData} />
        </> : <>
          <div className="pt-20 pb-20">
            <h3 className="text-bold text-black mycart1">My Cart  <Link to="#" className="close44" onClick={toogleCart}><i className="fa fa-close close3" /></Link></h3>
          </div>
          {
            cartItems && cartItems.length === 0 ? <div className='noCartProduct'>
              <div className='no-cart-inner'>
                <img src={process.env.PUBLIC_URL + '/images/cart2.png'} alt='item' />
                <h3> Your cart is empty.</h3>
                <p>Your favourite items are just a click away</p>
                <br/>
                <a href="/" className='continue-shop'>Continue to Shopping</a>
              </div>


            </div> :
              <>
                {
                  isLoading ? <div className='loading-container mh150'>
                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" />
                  </div> : <div className="row">

                    <div className="col-md-12">
                      <table className="table">
                        <tbody>
                          {
                            cartItems?.map((item, i) => {
                              return <tr key={i}>
                                <td style={{ padding: '10px', width: '15%' }} className="cpci">
                                  <img src={IMAGE_URL + item.product.thumbnail_img} className="img-fluid center-block cart_img2" alt='item' />
                                </td>
                                <td style={{ width: '46%' }} className="cpcf">
                                  <h5>
                                    <Link to="/" className="text-bold" >
                                      {item.product.product_name}
                                    </Link>
                                  </h5>
                                  <p className="text-green">
                                    {item.product.brand_name}
                                  </p>
                                  <p>
                                    {

                                    }
                                    {item.varient !== null && item.varient?.variant_name}
                                  </p>
                                  <p>
                                    {/* <img src="/images/gcircle_icon1.png" className="img-fluid gcircle_icon1" alt='' /> */}
                                    {/* <Link to="/" className="coff">
                  6% OFF
                </Link> */}
                                  </p>
                                </td>
                                <td style={{ width: '20%' }} className="cpcp">
                                  <div className='qty-add'>
                                    <div><button type='button' className='qty-btn' onClick={() => decreaseQty(-1, item.quantity, item.id)}>-</button></div>
                                    <div><div className='qty-num'>{item.quantity}</div></div>
                                    <div><button type='button' className='qty-btn' onClick={() => updateQty(1, item.quantity, item.id)}>+</button></div>
                                  </div>

                                </td>
                                <td style={{ width: '21%' }} className="cpcp">
                                  <p className="text-right">
                                    <i className="fa fa-rupee" />{item.purchage_price} <span className="linet"><i className="fa fa-rupee" />{item.main_price}</span>
                                  </p>
                                  <p className="text-green text-right">
                                    Saved Rs. {item.discounted_price}
                                  </p>
                                </td>
                                <td>
                                  <button className='tbtn' onClick={() => removeFromCartMethod(item.id)}>
                                    <i className="fa fa-close close2" />

                                  </button>

                                </td>
                              </tr>
                            })
                          }


                          <tr>
                            <td colSpan={2}>
                            </td>
                            <td colSpan={3}>
                              <p>
                                <span>Sub Total:</span>
                                <span className="fl-r">Rs {total_price}</span>
                              </p>
                              <p>
                                <span>Delivery Charge:</span>
                                <span className="fl-r">Rs {deliveryCharge ? deliveryCharge.charge : null}</span>
                              </p>
                              <p className="text-center">
                                <Link to="#" className="btn3" onClick={fetchAddresses}>
                                  View basket and Checkout
                                </Link>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                }

              </>
          }

        </>
      }

    </section>
  )
}

export default Cart