import { Apis } from '../../config';
import api from '../../ApiConfig';

const getSingleProduct = async (slug) =>{
    try {
        let result = await api.get(Apis.GetSingleProduct + slug);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}



const getSimilarProduct = async (data) =>{
    try {
        let result = await api.post(Apis.GetSimilarProducts,data);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}

const addToCart = async (data) =>{
    try {
        let result = await api.post(Apis.AddToCart,data);
        return result.data;
    } catch (error) {
        throw Error('Unable to add item')
    }
}

const getUserCartItem = async (data) =>{
    try {
        let result = await api.get(Apis.UserCartItem + data.user_id + "/" + data.user_type);
        return result.data;
    } catch (error) {
        throw Error('Unable to add item')
    }
}

const removeFromCart = async (data) =>{
    try {
        let result = await api.post(Apis.RemoveFromCart, data);
        return result.data;
    } catch (error) {
        throw Error('Unable to add item')
    }
}


const updateCartQty = async (data) =>{
    try {
        let result = await api.post(Apis.UpdateCartQty, data);
        return result.data;
    } catch (error) {
        throw Error('Unable to add item')
    }
}

const addToWishlist = async (data) =>{
    try {
        let result = await api.post(Apis.AddToWishlist,data);
        return result.data;
    } catch (error) {
        throw Error('Unable to add item to wishlist')
    }
}

const removeFromWishlist = async (data) =>{
    try {
        let result = await api.post(Apis.RemoveFromWishlist,data);
        return result.data;
    } catch (error) {
        throw Error('Unable to add item to wishlist')
    }
}

const getAllWishlistItems = async (data) =>{
    try {
        let result = await api.get(Apis.GetAllWishlistItem + data.user_id + "/" + data.user_type);
        return result.data;
    } catch (error) {
        throw Error('Unable to add item to wishlist')
    }
}

const getDeliveryCharge = async () =>{
    try {
        let result = await api.get(Apis.GetDeliveryCharge);
        return result.data;
    } catch (error) {
        throw Error('Unable to delivery charge')
    }
}
const getCoupon = async () =>{
    try {
        let result = await api.get(Apis.GetCoupon);
        return result.data;
    } catch (error) {
        throw Error('Unable to delivery charge')
    }
}



const exportedObject = {
    getSingleProduct,
    getSimilarProduct,
    addToCart,
    getUserCartItem,
    removeFromCart,
    updateCartQty,
    addToWishlist,
    removeFromWishlist,
    getAllWishlistItems,
    getDeliveryCharge,
    getCoupon
};
export default exportedObject;