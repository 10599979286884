import React, { useState, useEffect } from 'react'
const UpiPayment = ({cfCheckout, onPayment}) => {
    const [upi, setUpi] = useState('');
    const [isUPICollectReady, setIsUPICollectReady] = useState(false);
    const [isEnablePay, setIsEnablePay] = useState(false);
    const [error, setError] = useState('');

    const verifyUpi = () => {
        if (isUPICollectReady) {
            setIsEnablePay(true)
        } else {
            setError('Invalid UPI ID.')
            setIsEnablePay(false)
        }
    }
    const onPaymentInit = async () =>{
        if(!isUPICollectReady){
            alert('Upi is not ready to pay');
            return 
        }

        onPayment("upi-collect");

    }

    function upiCollectHandler(data) {
        setIsEnablePay(false);
        setError('')
        console.log("UPI collect --> ", data)
        setIsUPICollectReady(data.isReadyToPay)
    }

    useEffect(() => {
        cfCheckout.element(
            {
                pay: document.getElementById('pay-collect'),
                type: 'upi-collect',
                onChange: upiCollectHandler
            },
        );
    }, [cfCheckout])

    return (
            <div id="pay-collect">
                <div className="mb-3">
                    <label>UPI ID</label>
                    <input type="text" placeholder="Enter UPI ID" className="form-control" value={upi} data-upi-id onChange={(e) => setUpi(e.target.value)} />
                    <button type='button' className="text-green verify" onClick={verifyUpi}>Verify</button>
                     {error !== '' ? <span className='payment-error'><i className="fa fa-times-circle-o" /> {error} </span> : null}
                </div>
                <div className="mb-3">
                    <button type="button" className={isEnablePay ? 'btn btn-block pay-btn2 ' : 'btn btn-block btn-dark pay-btn' } disabled={!isEnablePay} onClick={onPaymentInit}>Pay Now</button>
                </div>
            </div>
    )
}

export default UpiPayment