import React, { useState } from 'react'
import OtpInput from 'react-otp-input';
import ResendOpt from './ResendOpt';
const OtpVerification = ({isLoading, userDetails, verifyOtp, otpErrorResp, setOtpErrorResp, classes}) => {
  
    const [otp, setOtp] = useState('');
    const [isDisabled, setDisabled] = useState(false)
    const [numInputs, setnumInputs] = useState(6);

  

    const handleOtp = async (opt) => {
        setOtpErrorResp('');
        setOtp(opt);

    }

    const clearOtp = () => {
        setOtp('');
        setOtpErrorResp('');
    };

    const getOtp = async () => {
        
        verifyOtp(otp);
    }
    
  return (
    <>
    <div className={classes ? `${classes} otp-box` : 'otp-box '}>
                                    <h4 className='fw-700 mrb-5'>Verify With OTP</h4>
                                    <p>Sent to {userDetails?.user?.phone  }</p> 
    
                                <OtpInput
                                    inputStyle="inputStyle"
                                    errorStyle="error"
                                    shouldAutoFocus
                                    value={otp}
                                    onChange={handleOtp}
                                    numInputs={numInputs}
                                    isDisabled={isDisabled}
                                    isInputNum={true}
                                    separator={<span>-</span>}
                                />
                                <div className="btn-row">
                                    <button
                                        className="btn clear-btn margin-top--large"
                                        type="button"
                                        disabled={isDisabled || otp.trim() === ''}
                                        onClick={clearOtp}
                                    >
                                        Clear
                                    </button>
                                    <button className="btn sb-btn margin-top--large btn-green" disabled={otp.length < numInputs} onClick={getOtp}>
                                       Verify
                                    </button>

                                </div>
                                <ResendOpt submitData={userDetails}/>
                                {
                                    isLoading && <div className='loading-container mh50'>
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" /> Please wait..
                                  </div>
                                }
                                {otpErrorResp !== null && otpErrorResp !== '' ? <span className='promo-code-error mt-10'><span><i className="fa fa-exclamation-circle"/> {otpErrorResp}</span></span> : null}
                               
                            </div>
    </>
  )
}

export default OtpVerification