import React, { useEffect, useState } from 'react'
import OtpVerification from '../auth/OtpVerification'
import OtpInput from 'react-otp-input';
import ResendOpt from '../auth/ResendOpt';
const EditProfileModal = ({ isLoading2, userDetails, otpErrorResp , otp, handleOtp, clearOtp, getOtp }) => {

    const [isDisabled, setDisabled] = useState(false)
    const [numInputs, setnumInputs] = useState(6);



    return (
        <div className="modal fade" id="editProfileModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered mw-430" role="document">
                <div className="modal-content">
                   
                    <div className="modal-body ">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <div className={'otp-box mh300'}>
                        
                            <h4 className='fw-700 mrb-5'>Verify With OTP</h4>
                             <p className='mb-15'>Sent to {userDetails?.user?.phone  }</p> 

                            <OtpInput
                                inputStyle="inputStyle"
                                errorStyle="error"
                                shouldAutoFocus
                                value={otp}
                                onChange={handleOtp}
                                numInputs={numInputs}
                                isDisabled={isDisabled}
                                isInputNum={true}
                                separator={<span>-</span>}
                            />
                            <div className="btn-row">
                                <button
                                    className="btn clear-btn margin-top--large"
                                    type="button"
                                    disabled={isDisabled || otp.trim() === ''}
                                    onClick={clearOtp}
                                >
                                    Clear
                                </button>
                                <button className="btn sb-btn margin-top--large btn-green" disabled={otp.length < numInputs} onClick={getOtp}>
                                    Verify
                                </button>
                             
                            </div>
                            <ResendOpt submitData={userDetails}/>
                            {
                                isLoading2 && <div className='loading-container mh50'>
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" /> Please wait..
                              </div>
                            }
                            
                           {otpErrorResp !== null && otpErrorResp !== '' ? <span className='promo-code-error mt-10'><span><i className="fa fa-exclamation-circle"/> {otpErrorResp}</span></span> : null}
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfileModal