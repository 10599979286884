import { Apis } from '../../config';
import api from '../../ApiConfig';

const createRazorpayOrderId = async (data) =>{
    try {
        let result = await api.post(Apis.AddMoneyToWallet,data);
        return result.data;
    } catch (error) {
       
        // throw Error('Unable to add money');
        return error;
    }
}

const createCheckoutPaymentOrder = async (data) =>{
    try {
    let result = await api.post(Apis.CreateCheackoutPaymentOrder,data);
    return result.data;
} catch (error) {
    return error;
}
}

const verifyCheckoutPayment = async (data) =>{
    try {
    let result = await api.post(Apis.ConfirmCheckoutPaymentStatus,data);
    return result.data;
} catch (error) {
    return error;
}
}

const exportedObject = {
    createRazorpayOrderId,
    createCheckoutPaymentOrder,
    verifyCheckoutPayment
};
export default exportedObject;