const compareTwoDatesForStart = (startDate) =>{
    const x = new Date(startDate);
    const y = new Date(getCurrentDate());
    if(x <= y){
        return true
    }else{
        return false
    }
}
const compareTwoDatesForEnd = (EndDate) =>{
    const x = new Date(EndDate);
    const y = new Date(getCurrentDate());
    if(x >= y){
        return true
    }else{
        return false
    }
}
const getCurrentDate = () =>{
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today =  yyyy + '-' + mm + '-' + dd;
    return today;
}
export {
    compareTwoDatesForStart,
    compareTwoDatesForEnd,
    getCurrentDate,
}