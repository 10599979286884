import React, {useEffect} from 'react'
import { GetWallet } from '../../helpers'
const PayuMoney = () => {

    const createHash = async() =>{
        var data = {
            amount:100,
            payment_method:"payu",
            firstname:"Rahul",
            email:"rahul@gmail.com",
            phone:"8888888888",
            productinfo:"productone"
       }

       const res = await GetWallet.addMoneyToWallet(data);
       console.log(res);
       if(res){
        var pd = {
            key: 'wAWsKO',
            txnid: res.txn_id,
            amount: 100,
            firstname: "Rahul",
            email:"rahul@gmail.com",
            phone: "8888888888",
            productinfo: "productone",
            surl: 'http://localhost:3000/payumoney',
            furl: 'http://localhost:3000/payumoney',
            hash: res.hash
       }
       console.log(pd)

       window.bolt.launch(pd, {
        responseHandler: function (response) {
            console.log(response)
        },
        catchException: function (response) {
            console.log(response)
        }
       })
       }
    }
   

  return (
    <div><p>Pay with payumnoey</p> 
     <button onClick={createHash}>PayuMoney</button>
 
<form action='https://test.payu.in/_payment' method='post'>
<input type="hidden" name="key" value="gtKFFx" />
<input type="hidden" name="txnid" value="t6svtqtjRdl4js" />
<input type="hidden" name="productinfo" value="iPhone" />
<input type="hidden" name="amount" value="10" />
<input type="hidden" name="email" value="test@gmail.com" />
<input type="hidden" name="firstname" value="Ashish" />
<input type="hidden" name="lastname" value="Kumar" />
<input type="hidden" name="surl" value="https://apiplayground-response.herokuapp.com/" />
<input type="hidden" name="furl" value="https://apiplayground-response.herokuapp.com/" />
<input type="hidden" name="phone" value="9988776655"/>
<input type="hidden" name="hash" value="485225270bd1f0d5aab041194b38e41f25748962c761a9278d20784fb461094a0dddf17a4f07c6b29fa7cd8143758337654377e39da46a867b2e98d61b77966f" />
<input type="submit" value="submit"/> 
</form>


    </div>
  )
}

export default PayuMoney