import React, {useState} from 'react'
import NetBanking from './NetBanking';
import { useSelector } from "react-redux";
import UpiPayment from './UpiPayment';
import CardPayment from './CardPayment';
import WalletPay from './WalletPay';
import { GetPayment } from '../../../../helpers';
// import { cashfreeSandbox } from 'cashfree-elementjs';
import { toast } from 'react-toastify';

import { cashfreeProd } from 'cashfree-elementjs';
const Payment = (props) => {
    const[cardError, setCardError ] = useState('');
     const onPaymentSuccess = async (data) => {
        console.log("Success:", data);
        if(data && data.order){
            const reqData = {
                order_id : data.order.orderId,
                transaction:data.transaction
            }
            const res = await GetPayment.verifyCheckoutPayment(reqData);
            if(res && res.status === 200 && res.result.order_status === "PAID"){
                const txnId = res.transaxtion.transactionId;
                const txnStatus = res.transaxtion.txStatus;
                const txnAmout = res.transaxtion.transactionAmount;
                props.createOnlineOrder('cashfree', txnStatus, txnId, txnAmout);
            }
        }
       
         
    }
    const onPaymentFailure = (data) => {
        console.log("Failure:", data);
        if(data && data.transaction.txStatus === "FAILED"){
            // setCardError('Invalid card details!')
            toast.error('Invalid card details!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // onClose: () => window.location.reload()
                });
        }

    }
    const onError = (data) => {
        console.log("Error:", data);
    }
    const option = {
        onPaymentSuccess: onPaymentSuccess,
        onPaymentFailure: onPaymentFailure,
        onError: onError,
    }

    // var cfCheckout = cashfreeSandbox.initializeApp(option);
    
     var cfCheckout = cashfreeProd.initializeApp(option);
    const onPayment =async(payment_method)=>{
        setCardError('')
        const productDetails = props.productsDetails;
      // const order_amount = props.order_amount;
         const order_amount = 1;
        const user = props.user;
        const data = JSON.stringify({
            order_amount: order_amount,
            customer_details: {
              customer_id: `${user.id}`,
              customer_email: user.email,
              customer_phone: user.phone
            },
            user_type:user.user_type,
            productsDetails:productDetails
        })
     
        const res = await GetPayment.createCheckoutPaymentOrder(data);
        if(res && res.status === 200){
            console.log(res);
            const order_token = res.data.order_token;
            await cfCheckout.pay(order_token, payment_method);
        }else{
            console.log(res);
            alert('something went wrong in order creation.')
        }
    }
    return (
  <>
  
   <div className="checkouttabs">
                <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">UPI</a>
                        <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" >Wallet</a>
                        <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false" >Card</a>
                        <a className="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false" >Cash</a>
                        <a className="nav-item nav-link" id="nav-net-tab" data-toggle="tab" href="#nav-net" role="tab" aria-controls="nav-net" aria-selected="false" >Net Banking</a>
                    </div>
                </nav>
                <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div className="row">
                            <div className="col-sm-2">
                            </div>
                            <div className="col-sm-8">
                            <UpiPayment cfCheckout={cfCheckout} onPayment={onPayment}/>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="row">
                            <div className="col-sm-2">
                            </div>
                            <div className="col-sm-8">
                          <WalletPay cfCheckout={cfCheckout} onPayment={onPayment}/>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <div className="row">
                            <div className="col-sm-2">
                            </div>
                            <div className="col-sm-8">
                                
                            <CardPayment cfCheckout={cfCheckout} onPayment={onPayment} cardError={cardError}/>
                              
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                        <div className="row">
                            <div className="col-sm-2">
                            </div>
                            <div className="col-sm-8">
                                <div className="mb-3">
                                
                                <button type="button" onClick={props.createCODOrder} className={'btn btn-block pay-btn2'}>Place Order</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-net" role="tabpanel" aria-labelledby="nav-net-tab">
                        <div className="row">
                            <div className="col-sm-2">
                            </div>
                            <div className="col-sm-8">
                            <NetBanking cfCheckout={cfCheckout} onPayment={onPayment}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </>

         
       


    )
}

export default Payment