import React from 'react'
import { Link } from 'react-router-dom'
const PayUFailedRessponse = () => {
  return (
    <div className='resp-box'>
      <i className="fa fa-exclamation-triangle"></i>
      <h3>Payment Failed !</h3>
      <p>Something went wrong.</p>
      <Link to="/user/my-wallet" class="btn btn-address">Back to Wallet</Link>
    </div>
  )
}

export default PayUFailedRessponse