import { Apis } from '../../config';
import api from '../../ApiConfig';

const getSiteLogo = async () =>{
    try {
        let result = await api.get(Apis.GetLogo);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}
const getHomeBanner = async () =>{
    try {
        let result = await api.get(Apis.GetBanners);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}
const getDealOfTheDay = async () =>{
    try {
        let result = await api.get(Apis.GetDealOfTheDay);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}
const getGetNewArrivals = async () =>{
    try {
        let result = await api.get(Apis.GetNewArrivals);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}

const getYouMayAlsoLike = async () =>{
    try {
        let result = await api.get(Apis.GetAlsoLike);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}

const getTreadingProducts = async () =>{
    try {
        let result = await api.get(Apis.GetTrending);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}
const getAllProducts = async () =>{
    try {
        let result = await api.get(Apis.GetAllProduts);
        return result.data;
    } catch (error) {
        throw Error('Unable to produts')
    }
}
const productSearch = async (data) =>{
    try {
        let result = await api.post(Apis.ProdutSearch, data);
        return result.data;
    } catch (error) {
        throw Error('Unable to produts')
    }
}
const getAllCategory = async () =>{
    try {
        let result = await api.get(Apis.GetAllCategories);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch category')
    }
}


const contactSubmit = async (data) =>{
    try {
        let result = await api.post(Apis.ContactSubmit, data);
        return result.data;
    } catch (error) {
        return error;
        // throw Error('Unable to send data')
    }
}



const exportedObject = {
    getSiteLogo,
    getHomeBanner,
    getDealOfTheDay,
    getGetNewArrivals,
    getYouMayAlsoLike,
    getTreadingProducts,
    getAllProducts,
    productSearch,
    getAllCategory,
    contactSubmit
};
export default exportedObject;