import React from 'react'

const AddressList = (props) => {
  return (
    <div className='choose-address'>
       <div className='address-title'>
           <h5>Choose Delivery Address</h5>
       </div>
       <div className='address-list-sec'>
       {
           props.isSettingDeafault && <div className='loadingDefault'>

                <div className='loader'>
                  <div className="lds-dual-ring-2"></div>
                </div>

              </div>
            }
       {
              props.data.length !== 0 ? <> {props.data.map((item, i) => {
                return (
               
                <div className="addressdiv" key={i}>
                 <div className='set-default'>
                   <input type="radio" checked={parseInt(item.default) === 1} onClick={()=>props.setDefaultAddress(item.id, item.pincode)}/>
                   <span className='text-darkgrey'><strong>Address {i+1}</strong></span>
                 </div>
                 
                 
                  <p className="text-grey">{item.address}, {item.locality}, {item.locality}, {item.city}, {item.state}, {item.pincode} </p>
                </div>
                
               
                )
              }) }
              
              </> : "Addres not added"
            }
       </div>
    </div>
  )
}

export default AddressList