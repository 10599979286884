import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GetWallet } from '../../../../helpers'
import { toast } from 'react-toastify'
const HandlePaymentResponse = (props) => {
    const userDetails = useSelector(state => state.user);
    // let { order_id, order_token } = useParams();

    const verifyPayment = async () => {
        const respData = {
            order_id: order_id,
            user_id: userDetails?.user.id
        }
        const res = await GetWallet.getCashfreePaymentStatus(respData);
        console.log(res);
        if (res && res.status === 200) {
            if (res.isActive) {
                const res2 = await GetWallet.verifyCashfreetPayment(respData);
                if (res2 && res2.status === 200) {
         
                    toast.success(res.message, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                     window.location.href="/user/my-wallet"
                } else {
               
                    toast.error(res.message, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                     window.location.href="/user/my-wallet"
                }
            }else {
                toast.error('Something went wrong !', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                 window.location.href="/user/my-wallet"
            }
        }
    }
    const query = new URLSearchParams(props.location.search);
    const order_id = query.get('order_id')
    const order_token = query.get('order_token')
    if (!order_id || !order_token) {
        window.location.href = "/user/my-wallet"
    }
    useEffect(() => {
        verifyPayment();
    }, [])

    return (
        <div>Processing payment....</div>
    )
}

export default HandlePaymentResponse