import * as types from '../types'

const initialState = {
    user:{}
}

export const userReducer = (state=initialState, action)=>{
    switch(action.type){
        case types.SET_USER:
            return {
                ...state,
                user:action.payload,
            }
            case types.UNSET_USER:
                return {
                    user:{},
                }
        default:
            return state;
    }
}