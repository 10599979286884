const options1 = {
    loop:true,
    nav:true,
    autoplay:true,
    dots:false,
    margin:10,
    smartSpeed:1000,
    responsiveClass:true,
    navText:['<span aria-label="Previous"><img src="images/h-arrow-left.png"></span>',
    '<span aria-label="Next"><img src="images/h-arrow-right.png"></span>']
};

const options2 = {
    loop:true,
    nav:true,
    autoplay:true,
    dots:false,
    margin:10,
    smartSpeed:1000,
    responsiveClass:true,
    navText:['<span aria-label="Previous"><img src="images/h-arrow-left-green.png" alt=""/></span>',
                                    '<span aria-label="Next"><img src="images/h-arrow-right.png" alt=""/></span>'],
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 5,

        }
    },
};

const options3 = {
    loop:true,
    nav:true,
    dots:false,
    margin:10,
    smartSpeed:1000,
    responsiveClass:true,
    navText:['<span aria-label="Previous"><img src="images/h-arrow-left.png"/></span>',
    '<span aria-label="Next"><img src="images/h-arrow-right.png"/></span>'],
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 2,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 6,

        }
    },
};

const options4 = {
    loop:true,
    nav:true,
    dots:false,
    margin:10,
    smartSpeed:1000,
    responsiveClass:true,
    navText:['<span aria-label="Previous"><img src="images/h-arrow-left-green.png"></span>',
    '<span aria-label="Next"><img src="images/h-arrow-right.png"></span>'],
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 2,
        },
        600: {
            items: 3,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 5,

        }
    },
};

const options5 = {
    loop:true,
    nav:true,
    autoplay:true,
    dots:true,
    margin:10,
    smartSpeed:1500,
    responsiveClass:true,
    navText:['<span aria-label="Previous"><i class="fa fa-chevron-left"></i></span>',
    '<span aria-label="Next"><i class="fa fa-chevron-right"></i></span>']
};
const options6 = {
    loop:true,
    nav:true,
    dots:false,
    margin:10,
    smartSpeed:1000,
    responsiveClass:true,
 
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,

        }
    },
};


export {
    options1,
    options2,
    options3,
    options4,
    options5,
    options6
}