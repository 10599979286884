import * as types from '../types'
const initialState = {
    wishItems: [],
    showWishLoading: false
  }
export const wishListReducer = (
    state = initialState,
    action
) => {

    switch (action.type) {
        case types.ADD_TO_WISHLIST:
            return {
                ...state,
                wishItems: action.payload.wishItems,
            };
        case types.FETCH_FROM_WISHLIST:
            return {
                ...state,
                wishItems: action.payload.wishItems
            };
        case types.REMOVE_FROM_WISHLIST:
            return {
                 ...state,
                 wishItems: action.payload.wishItems
            };
            
        case types.RESET_WISHLIST:
            return {
                wishItems: [],
                showWishLoading:false
            };
        case types.LOADING_WISHLIST:
            return {
            ...state,
            showWishLoading: action.payload
        };
        default:
            return state;
    }
}
