import React, { useState, useEffect } from 'react'
import AddressModal from './AddressModal'
import EditAddress from './EditAddress'
import { GetAddress } from '../../../helpers';
import { parseCookies, setCookie } from 'nookies'
import $ from "jquery";
const cookie = parseCookies()
const SaveAddress = () => {
  const [isLoading, setLoading] = useState(true);
  const [isSettingDeafault, settingDeafault] = useState(false);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  const getData = async () => {
    const user = JSON.parse(cookie.user);
    const res = await GetAddress.getAllAddressed(user.authId);
    if (res && res.status === 200) {
      setData(res.data);
      settingDeafault(false)
      setLoading(false)
    } else if (res && res.status === 404) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData();
  }, [])

  const reloadPageData = (status) => {
    if (status) {
      getData();
    }
  }
  const deleteAddress = async (del_id) => {
    const data = {
      address_id: del_id
    }
    const res = await GetAddress.deleteAddress(del_id, data);
    if (res && res.status === 200) {
      getData();
    }
  }

  const singleAddress = async (address_id) => {
    const user = JSON.parse(cookie.user);
    const user_id = user.authId;
    const res = await GetAddress.getSingleAddress(user_id, address_id);
    if (res && res.status === 200) {
      console.log(res);
      setEditData(res.data)
      $("#updateAddressModal").modal("show")
    }

  }
  const setDefaultAddress = async (address_id, pinCode) => {
    settingDeafault(true)
    const user = JSON.parse(cookie.user);

    const data = {
      user_id: user.authId,
      user_type: user.auth_type
    }
    const res1 = await GetAddress.getPincodePlace(pinCode);
    if (res1 && res1.length !== 0) {
      const district = res1[0].PostOffice[0].District;
      const state = res1[0].PostOffice[0].State;
      const location = JSON.stringify({
        pin_code: pinCode,
        district: district,
        state: state
      })
      setCookie(null, 'delivery', location, {
        sameSite: true,
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      })
    }
    const res = await GetAddress.setDefaultAddress(data, address_id);
    if (res && res.data) {
      getData();
    }
  }


  return (
    <>
      <div className="dashboard-title">
        <h4 className="user-heading">My Address</h4>
      </div>
      <div className="dashboard-content">
        {
          isLoading ? <div className='loading-container mh150'>
            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" />
          </div> : <>
            {isSettingDeafault &&
              <div className='loadingDefault'>

                <div className='loader'>
                  <div className="lds-dual-ring-2"></div>
                </div>

              </div>
            }
            {
              data.length !== 0 ? <> {data.map((item, i) => {
                return (
                 
                    <div className="addressdiv" key={i}>
                      <div className='actions'>
                        <div className='actions-btn edit-btn' onClick={() => singleAddress(item.id)}>
                          <i className="fa fa-pencil" ></i>
                        </div>
                        <div className='actions-btn remove-btn' onClick={() => deleteAddress(item.id)}>
                          <i className="fa fa-times" ></i>
                        </div>

                      </div>
                      <div className='set-default'>
                        <input type="radio" checked={parseInt(item.default) === 1} onClick={() => { setDefaultAddress(item.id, item.pincode) }} />
                       <span className='text-darkgrey'><strong>Address {i + 1}</strong></span> 
                      </div>

                 
                      <p className="text-grey">{item.address}, {item.locality}, {item.locality}, {item.city}, {item.state}, {item.pincode} </p>
                    </div>

                
                )
              })}

              </> : <div>
                <img src="/images/user_img2.png" className="img-responsive center-block" alt='' />
                <p className="text-bold text-center">You have no saved address</p>
                <p className=" text-center">Tell us where you want your order delivered?</p>

              </div>
            }
          </>
        }

        <p className="text-center pt-20">
          <a href="#addressModal" className="btn4" data-toggle="modal">
            Add Address
          </a>
        </p>


      </div>
      <AddressModal reloadPageData={reloadPageData} />
      <EditAddress reloadPageData={reloadPageData} editData={editData} />
    </>
  )
}

export default SaveAddress