import React, { Fragment, useState, useEffect } from 'react'
import MainCategories from '../product/MainCategories'
import Breadcrumbs from '../partials/Breadcrumbs'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProductItem from '../product/ProductItem';
import 'react-tabs/style/react-tabs.css';
import { useParams } from 'react-router-dom';
import { GetProducts } from '../../helpers'
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { addToCart, loadingToggleAction, incrementToCart, decreaseToCart } from '../../store/actions/cartActions';
import SingleProductSlider from '../product/SingleProductSlider';
import { parseCookies } from 'nookies'
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
const cookie = parseCookies()
const SingleProduct = (props) => {
    const params = useParams()
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.user)
    const [product, setProduct] = useState(null);
    const [similarProduct, setSimilarProduct] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isLoading2, setLoading2] = useState(true);
    const [productImages, setProductImages] = useState([]);
    const [proQty, setProQty] = useState(1);
    const [currentVarID, setCurrentVarID] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const getSimilarProduct = async (id) => {
        const res = await GetProducts.getSimilarProduct({
            category_id: parseInt(id)
        });
        if (res && res.status === 200) {
            setSimilarProduct(res.data);
            setLoading2(false);
        }
    }

    const getData = async (slug) => {
        const res = await GetProducts.getSingleProduct(slug);
        if (res && res.status === 200) {
            getSimilarProduct(res.data.category_id);
            setProduct(res.data);
            setLoading(false);
            const withoutFirstAndLast = res.data?.has_variant !== '1' ? res.data?.main_img.slice(1, -1) : res.data?.variants[0].variant_img.slice(1, -1);
            var noQuotes = withoutFirstAndLast?.split('"').join('');
            setProductImages(noQuotes?.split(","));
            res.data?.variants !== null && setCurrentProduct(res.data?.variants[0]);
            res.data?.variants !== null && setCurrentVarID(res.data?.variants[0]?.id);
        }
    }

    const handleVarientChange = (val) => {
        const fVarient = product.variants.find(el => el.variant_name === val);
        const withoutFirstAndLast = fVarient.variant_img !== null ? fVarient?.variant_img.slice(1, -1) : "";
        var noQuotes = withoutFirstAndLast?.split('"').join('');
        setCurrentProduct(fVarient);
        setCurrentVarID(fVarient.id);
        setProductImages(noQuotes?.split(","));
    }

    useEffect(() => {
        getData(params.slug);
    }, []);

    const handleSubmitCart = () => {
        if(typeof(proQty) === 'number' && proQty > 0 ){
            if (cookie.auth && cookie.auth === 'true') {
                if (userDetails && userDetails.user !== null) {
                    const data = {
                        "user_id": userDetails.user.id,
                        "user_type": userDetails.user.user_type,
                        "product_id": product.id,
                        "variant_id": currentVarID,
                        "quantity": proQty
                    }
                    // props.loadingToggleAction(true);
                    props.addToCart(data);
                }
            } else {
    
                return props.history.push({
                    pathname: "/login",
                    state: {
                        pre_path: window.location.pathname
                    }
                });
            }
        }else{
      toast.error('You have entered wrong quantity !', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
       


    }

    const getItemInfo = (item) => {

        if (item.variant_discount !== null) {
            const discPer = (item.variant_discount / item.variant_price) * 100;
            const disc_price = item.variant_price - item.variant_discount;
            return " Rs." + disc_price + "  " + discPer.toFixed(1) + '% OFF';
        } else {
            return null;
        }
    }

    const getPurchage = (val) => {
        const purchage_price = val?.variant_price - val?.variant_discount;
        return purchage_price;
    }
    const increaseQty = (cart_id) => {
        dispatch(incrementToCart(cart_id));
    }
    const decreaseQty = (cart_id) => {
        dispatch(decreaseToCart(cart_id));
    }

    const isProduct = product?.has_variant === '1' ? props.cartItems?.find(el => parseInt(el.product_id) === product?.id && parseInt(el.variant_id) === currentVarID) : props.cartItems?.find(el => parseInt(el.product_id) === product?.id);
    return (
        <Fragment>
            <MainCategories />
            {
                isLoading ? <div className='loading-container'>
                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" />
                </div> :
                    <>
                        <Breadcrumbs category={product.category} produtName={product.product_name} />
                        <section className="pdetails sectiondiv">
                            <div className="container">

                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <SingleProductSlider productImages={productImages} />
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="row">
                                            <div className="col-md-12">

                                                <h3 className="product-title">
                                                    {product.product_name}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="row mt-20">
                                            <div className="col-md-8 ">
                                                <div className='product_specification'>
                                                    {
                                                        product?.has_variant === '1' ? <div className='price'>
                                                            {currentProduct?.variant_discount !== null ? <>
                                                                <div className='main_price'>
                                                                    <p> <del>Rs.{currentProduct?.variant_price} </del> </p>
                                                                </div>
                                                                <div className='purchase_price'>
                                                                    <p>Rs.{getPurchage.call(this, currentProduct)} </p>
                                                                </div>
                                                            </> : <div className='main_price'>
                                                                <p> Rs. {currentProduct?.variant_price} </p>
                                                            </div>

                                                            }
                                                        </div> : <div className='price'>
                                                            <div className='main_price'>
                                                                <p><del>Rs.{product.mrp} </del></p>
                                                            </div>
                                                            <div className='purchase_price'>
                                                                <p>Rs.{product.purchase_price} </p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        product?.has_variant === '1' &&
                                                        <div className='pack-sizes'>
                                                            <h4>Pack Sizes</h4>
                                                            <div className='varient'>

                                                                {
                                                                    product?.variants.map((item, i) => {
                                                                        return <div className="form-check" key={i}>
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id={`varient${i}`} value={item.variant_name} onChange={() => handleVarientChange(item.variant_name)} checked={currentProduct?.variant_name === item.variant_name} />
                                                                            <label className="form-check-label" htmlFor={`varient${i}`}>
                                                                                <div className='vname'> {item.variant_name}</div>
                                                                                <div className='vprice'>{item.variant_discount !== null ? <del>Rs. {item.variant_price}</del> : `Rs. ${item.variant_price}`} <span> {getItemInfo.call(this, item)}</span> </div>
                                                                            </label>
                                                                        </div>
                                                                    })
                                                                }

                                                            </div>

                                                        </div>
                                                    }

                                                    {
                                                        isProduct ? <>
                                                           
                                                            <div className='qty-add'>
                                                                <div><button type='button' className='qty-btn' onClick={() => decreaseQty(isProduct.id)}>-</button></div>
                                                                <div><div className='qty-num'>{isProduct.quantity}</div></div>
                                                                <div><button type='button' className='qty-btn' onClick={() => increaseQty(isProduct.id)}>+</button></div>
                                                            </div></> : <div className='add-cart-product'>
                                                            <div className="input-group qty_add_box">
                                                                <div className="input-group-prepend">
                                                                    <div className="input-group-text" id="btnGroupAddon">QTY</div>
                                                                </div>
                                                                <input type="number" className="form-control" aria-label="Input group example" aria-describedby="btnGroupAddon" value={proQty} onChange={(e) => setProQty(parseInt(e.target.value))} />
                                                            </div>
                                                       
                                                            <button className="text-center addtocart" onClick={handleSubmitCart}>
                                                            {
                                                                props.showLoading ? <div className="lds-dual-ring"></div> : "ADD TO CART"
                                                            }
                                                        </button>
                                                            </div>
                                                    }



                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="row mt-50">
                                    <div className="col-md-12">
                                        <Tabs className={'tab-sec'}>
                                            <TabList className={`tab1`}>
                                                <Tab>Description</Tab>
                                                <Tab>Specification</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <h3>Description</h3>
                                                {parse(product.description)}
                                            </TabPanel>
                                            <TabPanel>
                                                <h3>Specification</h3>
                                                {parse(product.specification)}
                                            </TabPanel>
                                        </Tabs>

                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="cat-product sectiondiv mt-30">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="related-heading">
                                            You might also like
                                        </h3>
                                    </div>
                                </div>
                                {
                                    !isLoading2 && <div className="row">
                                        <div className="col-md-12  mt-10">
                                            <ul className="products">
                                                {
                                                    similarProduct?.map((item, i) => {
                                                        return <li key={i}><ProductItem data={item} /></li>
                                                    })
                                                }

                                                <div className="clearfix" />
                                            </ul>
                                        </div>
                                    </div>
                                }

                            </div>
                        </section>
                    </>
            }
        </Fragment>
    )
}

export default connect((state) => ({
    cartItems: state.cart.cartItems,
    showLoading: state.cart.showLoading
}), { addToCart, loadingToggleAction, incrementToCart, decreaseToCart })(SingleProduct);