import React,{useRef, useEffect} from 'react'
import { Link } from 'react-router-dom'
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
// function useOutsideAlerter(ref) {
//     useEffect(() => {
//       /**
//        * Alert if clicked on outside of element
//        */
//       function handleClickOutside(event) {
//         if (ref.current && !ref.current.contains(event.target)) {
//           alert("You clicked outside of me!");
//         }
//       }
//       // Bind the event listener
//       document.addEventListener("mousedown", handleClickOutside);
//       return () => {
//         // Unbind the event listener on clean up
//         document.removeEventListener("mousedown", handleClickOutside);
//       };
//     }, [ref]);
//   }
  

const SearchedData = ({isShowSearch}) => {
    // const wrapperRef = useRef(null);
    // useOutsideAlerter(wrapperRef);
    
  return (
    <section  className="cartp top43" style={isShowSearch ? { display:'block' } : {}}>
    <table className="table">
      <tbody>
        <tr>
          <td className="cpit" style={{width: '9%'}}>
            <img src="/images/cart_img1.png" className="img-fluid center-block cart_img1" alt=''/>
          </td>
          <td className="cpft" style={{width: '44%'}}>
            <h5 className="text-bold">
              <Link to="/" className="text-bold">
                Fortune Mustard (Kachi Ghani) Oil  
              </Link>
            </h5>
            <p className="text-green">
              Premium Hand Chumed Oil
            </p>
          </td>
          <td className="cpl">
            <p>
              5L Can 
            </p>
          </td>
          <td className="cpq" style={{width: '18%'}}>
            <div className="form-group form-inline">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                <p>Qty</p>
              </label>
              <input type="number" className="form-control custom-select pqty_1 pqtyt" defaultValue={1} />
            </div>
          </td>
          <td className="cpp">
            <p>
              <i className="fa fa-rupee" />500
            </p>
          </td>
          <td style={{width: '14%'}} className="cpa">
            <button type="button" className="qtybtn cpab" style={{fontSize: '10px'}} data-btn={7}>Add +</button>  
          </td>
        </tr>
        <tr>
          <td className="cpit" style={{width: '9%'}}>
            <img src="/images/cart_img1.png" className="img-fluid center-block cart_img1" alt=''/>
          </td>
          <td className="cpft" style={{width: '44%'}}>
            <h5 className="text-bold">
             <Link to="/" className="text-bold">
                Fortune Mustard (Kachi Ghani) Oil  
              </Link>
            </h5>
            <p className="text-green">
              Premium Hand Chumed Oil
            </p>
          </td>
          <td className="cpl">
            <p>
              5L Can 
            </p>
          </td>
          <td className="cpq" style={{width: '18%'}}>
            <div className="form-group form-inline">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                <p>Qty</p>
              </label>
              <input type="number" className="form-control custom-select pqty_1 pqtyt" defaultValue={1} />
            </div>
          </td>
          <td className="cpp">
            <p>
              <i className="fa fa-rupee" />500
            </p>
          </td>
          <td style={{width: '14%'}} className="cpa">
            <button type="button" className="qtybtn cpab" style={{fontSize: '10px'}} data-btn={7}>Add +</button>  
          </td>
        </tr>
        <tr>
          <td className="cpit" style={{width: '9%'}}>
            <img src="images/cart_img1.png" className="img-fluid center-block cart_img1" />
          </td>
          <td className="cpft" style={{width: '44%'}}>
            <h5 className="text-bold">
              <a href="#" className="text-bold">
                Fortune Mustard (Kachi Ghani) Oil  
              </a>
            </h5>
            <p className="text-green">
              Premium Hand Chumed Oil
            </p>
          </td>
          <td className="cpl">
            <p>
              5L Can 
            </p>
          </td>
          <td className="cpq" style={{width: '18%'}}>
            <div className="form-group form-inline">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                <p>Qty</p>
              </label>
              <input type="number" className="form-control custom-select pqty_1 pqtyt" defaultValue={1} />
            </div>
          </td>
          <td className="cpp">
            <p>
              <i className="fa fa-rupee" />500
            </p>
          </td>
          <td style={{width: '14%'}} className="cpa">
            <button type="button" className="qtybtn cpab" style={{fontSize: '10px'}} data-btn={7}>Add +</button>  
          </td>
        </tr>
        <tr>
          <td className="cpit" style={{width: '9%'}}>
            <img src="images/cart_img1.png" className="img-fluid center-block cart_img1" />
          </td>
          <td className="cpft" style={{width: '44%'}}>
            <h5 className="text-bold">
              <a href="#" className="text-bold">
                Fortune Mustard (Kachi Ghani) Oil  
              </a>
            </h5>
            <p className="text-green">
              Premium Hand Chumed Oil
            </p>
          </td>
          <td className="cpl" style={{width: '10%'}}>
            <p>
              5L Can 
            </p>
          </td>
          <td className="cpq" style={{width: '18%'}}>
            <div className="form-group form-inline">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                <p>Qty</p>
              </label>
              <input type="number" className="form-control custom-select pqty_1 pqtyt" defaultValue={1} />
            </div>
          </td>
          <td className="cpp" style={{width: '10%'}}>
            <p>
              <i className="fa fa-rupee" />500
            </p>
          </td>
          <td style={{width: '14%'}} className="cpa">
            <button type="button" className="qtybtn cpab" style={{fontSize: '10px'}} data-btn={7}>Add +</button>  
          </td>
        </tr>
        <tr>
          <td className="cpit" style={{width: '9%'}}>
            <img src="images/cart_img1.png" className="img-fluid center-block cart_img1" />
          </td>
          <td className="cpft" style={{width: '44%'}}>
            <h5 className="text-bold">
              <a href="#" className="text-bold">
                Fortune Mustard (Kachi Ghani) Oil  
              </a>
            </h5>
            <p className="text-green">
              Premium Hand Chumed Oil
            </p>
          </td>
          <td className="cpl">
            <p>
              5L Can 
            </p>
          </td>
          <td className="cpq" style={{width: '18%'}}>
            <div className="form-group form-inline">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                <p>Qty</p>
              </label>
              <input type="number" className="form-control custom-select pqty_1 pqtyt" defaultValue={1} />
            </div>
          </td>
          <td className="cpp">
            <p>
              <i className="fa fa-rupee" />500
            </p>
          </td>
          <td style={{width: '14%'}} className="cpa">
            <button type="button" className="qtybtn cpab" style={{fontSize: '10px'}} data-btn={7}>Add +</button>  
          </td>
        </tr>
        <tr>
          <td colSpan={6} style={{padding: '0 5px'}}>
            <p className="text-center">
              <a href="#" className="btn3">
                View All Result
              </a>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
  )
}

export default SearchedData