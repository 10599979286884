import * as types from '../types'
import {GetProducts} from '../../helpers'
import {removeFromWishlist} from './wishListActions'
import { toast } from 'react-toastify';
import store from '../store';

//export const addToCart =  (product) => async (dispatch, getState) => {

  // const res = await GetProducts.addToCart(product);

  // if(res.data !== null){
  //   const cartData = res.data;
  //   // const data = {
  //   //   user_id:res.data.user_id,
  //   //   user_type:res.data.user_type,
  //   // }
  //   // const res2 = await GetProducts.getUserCartItem(data);
  //   // if(res2.data !== null){
     
  //   //   const cartItems = res2.data;
  //     dispatch(loadingToggleAction(false))
  //    dispatch({
  //     type: types.ADD_TO_CART,
  //     payload: { cartData },
  //    });
  // //   }

  //  }
 
//}


export const addToCart =  (product, wishlist_id) => async (dispatch, getState) => {
  const res = await GetProducts.addToCart(product);
  if(res.data !== null){
    const cartItems = getState().cart.cartItems.slice();
    let alreadyExists = false;
    cartItems.forEach((x) => {
      if(x.variant_id !== null){
      if (parseInt(x.product_id) === parseInt(product.product_id) && parseInt(x.user_id) === parseInt(product.user_id) && parseInt(x.variant_id) === parseInt(product.variant_id)) {
        alreadyExists = true;
      }
    }else if(parseInt(x.product_id) === parseInt(product.product_id) && parseInt(x.user_id) === parseInt(product.user_id)){
      alreadyExists = true;
    }
    });
    if (!alreadyExists) { 
      var main_price= 0;
      var purchage_price=0;
      var discounted_price=0;
      var total_price = 0;
      if(res.data.variant_id === null){
         main_price = parseInt(res.product.mrp);
         purchage_price=parseInt(res.product.purchase_price);
         discounted_price = parseInt(res.product.discount);
      }else{
        main_price = parseInt(res.variant.variant_price);
        discounted_price = parseInt(res.variant.variant_discount);
           if(res.variant.variant_discount !== null){
          purchage_price= parseInt(res.variant.variant_price) - parseInt(res.variant.variant_discount);
        }else{
          purchage_price = parseInt(res.variant.variant_price);
        }
      }
      cartItems.forEach((x) => {
        total_price = total_price + (parseInt(x.purchage_price)*parseInt(x.quantity));
        
      });
      var final_total = total_price + purchage_price*parseInt(res.data.quantity);

      const proInfo = {
        id: res.data.id,
        user_id: res.data.user_id,
        user_type: res.data.user_type,
        product_id: res.data.product_id,
        variant_id: res.data.variant_id,
        quantity: res.data.quantity,
        product:res.product,
        varient:res.variant,
        main_price:main_price,
        purchage_price:purchage_price,
        actual_price:purchage_price,
        discounted_price:discounted_price
      }
      cartItems.push({ ...proInfo });
      toast.success('Nice ! Product added to cart', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      if(wishlist_id){
        dispatch(removeFromWishlist(wishlist_id))
        dispatch({
          type: types.ADD_TO_CART,
          payload: { cartItems, final_total },
        });
      }else{
        dispatch({
          type: types.ADD_TO_CART,
          payload: { cartItems, final_total },
        });
      }
    
     // dispatch(loadingToggleAction(false))
    }
  }
 
 
 
}
export const setAllProductToCart = (user) => async (dispatch, getState) =>{
  const data = {
   user_id:user.authId,
   user_type:user.auth_type,
 }
 const res2 = await GetProducts.getUserCartItem(data);
 if(res2.data !== null){
   const cartItems2 = res2.data;
   var total_price = 0;
   var main_price= 0;
   var purchage_price=0;
   var discounted_price=0;
   var cartItems = [];
   cartItems2.forEach((x) => {
    if(x.variant_id === null){
      main_price = parseInt(x.product.mrp);
      purchage_price=parseInt(x.product.purchase_price);
      discounted_price = parseInt(x.product.discount);
   }else{
     main_price = parseInt(x.variant.variant_price);
     discounted_price = parseInt(x.variant.variant_discount);
        if(x.variant.variant_discount !== null){
       purchage_price= parseInt(x.variant.variant_price) - parseInt(x.variant.variant_discount);
     }else{
       purchage_price = parseInt(x.variant.variant_price);
     }
   }
    const proInfo = {
           id: x.id,
          user_id: x.user_id,
          user_type: x.user_type,
          product_id: x.product_id,
          variant_id: x.variant_id,
          quantity: x.quantity,
          product:x.product,
          varient:x.variant,
          main_price:main_price,
          purchage_price:purchage_price,
          actual_price:purchage_price,
          discounted_price:discounted_price
    }
     cartItems.push({ ...proInfo });
    
   });
  
   cartItems.forEach((x) => {
     total_price = total_price + (parseInt(x.purchage_price)*parseInt(x.quantity))
   });
   var final_total = total_price;
        dispatch({
            type: types.FETCH_TO_CART,
            payload: { cartItems, final_total },
        });
 }
}
export const loadingToggleAction = (status) =>{
   return {
     type:types.TOGGLE_LOADING,
     payload: status
   }
}

export const getTotalPrice =  (value, flag) => async (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  var total_price = 0;
  cartItems.forEach((x) => {
    total_price = total_price + parseInt(x.purchage_price)
  });
  var final_total = total_price + value;
  return {
    type:types.GET_TOTALS,
    payload: final_total
  }
}



export const resetCart = () =>{
  return {
    type:types.RESET,
  }
}
export const fetchCartItems = (data) =>  async (dispatch, getState) => {

  const data2 = {
    user_id:data.id,
    user_type:data.user_type,
  }
  const res2 = await GetProducts.getUserCartItem(data2);
  if(res2.data !== null){
    const cartItems = res2.data;
   dispatch(loadingToggleAction(false))
   dispatch({
    type: types.ADD_TO_CART,
    payload: { cartItems },
   });
  }else{
    dispatch(loadingToggleAction(false))
    dispatch({
      type: types.ADD_TO_CART,
      payload: {},
     });
  }
}

export const removeFromCart = (del_id) => async (dispatch, getState) => {
  const res = await GetProducts.removeFromCart({cart_id:del_id});
  
  if(res){
    let cartItemsmain = store.getState().cart.cartItems.slice();
    let cart = store.getState().cart;
   
   const selectProduct = cartItemsmain.find(item => item.id === del_id);
   const index = cartItemsmain.indexOf(selectProduct)
   const value = cartItemsmain[index];
   var final_total = parseInt(cart.total_price) - (parseInt(value.actual_price) * value.quantity);
  
     let cartItems = store.getState().cart.cartItems.slice().filter((x) => x.id !== del_id);
     dispatch({ type: types.REMOVE_FROM_CART, payload: { cartItems, final_total } });
   }
  };
  export const incrementToCart = (cart_id) => async (dispatch) => {
    let cartItems = store.getState().cart.cartItems.slice();
    let cart = store.getState().cart;
    const selectProduct = cartItems.find(item => item.id === cart_id);
    
    const index = cartItems.indexOf(selectProduct)
    const value = cartItems[index]
    value.quantity = parseInt(value.quantity) + 1;
  //  value.purchage_price = parseInt(value.actual_price) * parseInt(value.quantity);
    var final_total = parseInt(cart.total_price) + parseInt(value.actual_price);
    const res = await GetProducts.updateCartQty({
       cart_id:cart_id,
       quantity:value.quantity,
    });
if(res){
  dispatch({
      type: types.INCREASE_QUANTITY,
      payload: { cartItems, final_total },
    });
}
  }

  export const decreaseToCart = (cart_id) => async (dispatch, getState) => {
    let cartItems = store.getState().cart.cartItems.slice();
    let cart = store.getState().cart;
    const selectProduct = cartItems.find(item => item.id === cart_id);
    if(parseInt(selectProduct.quantity) === 1){
      dispatch(removeFromCart(cart_id))
    }else{
    const index = cartItems.indexOf(selectProduct)
    const value = cartItems[index]
    value.quantity = parseInt(value.quantity) - 1;
    //value.purchage_price = parseInt(value.purchage_price) - parseInt(value.actual_price);
    var final_total = parseInt(cart.total_price) - parseInt(value.actual_price);
  
    const res = await GetProducts.updateCartQty({
       cart_id:cart_id,
       quantity:value.quantity,
    });
if(res){
  dispatch({
      type: types.DECREASE_QUANTITY,
      payload: { cartItems, final_total },
    });
}
}
   
  }
