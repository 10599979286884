import { Apis } from '../../config';
import api from '../../ApiConfig';

const addNewAddres = async (data) =>{
    try {
        let result = await api.post(Apis.AddNewAddress, data);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}

const getAllAddressed = async (user_id) =>{
    try {
        let result = await api.get(Apis.GetAddressList + user_id);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}

const deleteAddress = async (address_id, data) =>{
    try {
        let result = await api.post(Apis.DeleteAddress + address_id,data);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch logo')
    }
}

const getSingleAddress = async (user_id, address_id) =>{
    try {
        let result = await api.get(Apis.GetSigleAddress + user_id + "/" + address_id);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch address')
    }
}
const updateAddress = async (data, address_id) =>{
    try {
        let result = await api.post(Apis.UpdateAddress + address_id, data);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch address')
    }
}
const setDefaultAddress = async (data, address_id) =>{
    try {
        let result = await api.post(Apis.SetDefaultAddress + address_id, data);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch address')
    }
}
const getDefaultAddress = async (user) =>{
    try {
        let result = await api.get(Apis.GetDefaultAddress + user.user_id + "/" + user.user_type);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch address')
    }
}

const getAllPincodes = async () =>{
    try {
        let result = await api.get(Apis.GetAllPincodes);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch pincodes')
    }
}
const getSinglePincodes = async (query) =>{
    try {
        let result = await api.get(Apis.GetSinglePincode+query);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch pincodes')
    }
}

const getPincodePlace = async (query) =>{
    try {
        let result = await api.get(`https://api.postalpincode.in/pincode/${query}`);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch pincodes place')
    }
}


const exportedObject = {
    addNewAddres,
    getAllAddressed,
    deleteAddress,
    getSingleAddress,
    updateAddress,
    setDefaultAddress,
    getDefaultAddress,
    getAllPincodes,
    getSinglePincodes,
    getPincodePlace
   };
export default exportedObject;