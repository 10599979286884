import React from 'react'

const Custom404 = () => {
  return (
    <div className='not-found'>
        <h1>404</h1>
        <p>Page not found !</p>
    </div>
  )
}

export default Custom404