import React, { useState, useEffect } from 'react'
import { IMAGE_URL } from '../../../config';
import { useSelector, useDispatch } from "react-redux";
import { parseCookies } from 'nookies'
import { useHistory } from "react-router-dom";
import { addToCart } from '../../../store/actions/cartActions';
import { toast } from 'react-toastify';
const cookie = parseCookies()
const WishListItem = (props) => {
  const [isLoading , setLoading] = useState(true);
  const item = props.item;
  const userDetails = useSelector(state => state.user);
  let history = useHistory();
  const dispatch = useDispatch();

  const [pPrice, setPPrice]  = useState(null);
  const [mPrice, setMPrice]  = useState(null);
  const [fVName, setfVName]  = useState(null);
  const [fVar, setFvar] = useState(null)
  const [qty, setQty] = useState(1);
const getData =() =>{
  const fVariant = item?.product?.variants && item?.product?.variants[0];
  var purchagePrice = fVariant ? fVariant.variant_discount !== null ? fVariant.variant_price - fVariant.variant_discount : fVariant.variant_price : item?.product?.purchase_price
  var mainPrice = fVariant ? fVariant.variant_price : item?.product?.mrp;
  var fVarientName = fVariant ? fVariant.variant_name : null
  setPPrice(purchagePrice);
  setMPrice(mainPrice);
  setfVName(fVarientName);
  setFvar(fVariant);
  setLoading(false);
}
useEffect(() => {
  getData();
},[])


  const handleCartSubmit = () => {
    if(typeof(qty) === 'number' && qty > 0 ){
      if (cookie.auth && cookie.auth === 'true') {
        if (userDetails && userDetails.user !== null) {
          const data = {
            "user_id": userDetails.user.id,
            "user_type": userDetails.user.user_type,
            "product_id": item?.product.id,
            "variant_id": fVar ? fVar?.id : null,
            "quantity": qty
          }
          
          const wishlist_id = item?.id;
      
          //props.addCart(data, wishlist_id); 
          
          dispatch(addToCart(data, wishlist_id)).then(
            getData()
          );
        }
      } else {
  
        return history.push({
            pathname: "/login",
            state: {
                pre_path: window.location.pathname
            }
        });
      }
    }else{
toast.error('You have entered wrong quantity !', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

  }

  const getItemInfo = (item) => {
    if (item.variant_discount !== null) {
      const discPer = (item.variant_discount / item.variant_price) * 100;
      const disc_price = item.variant_price - item.variant_discount;
      return " Rs." + disc_price;
    } else {
      return null;
    }
  }
  const handleChangeSize = (val) => {
   
    const FilteredVariant = item.product.variants.find(el=>parseInt(el.id) === parseInt(val));
     var purchagePrice = FilteredVariant ? FilteredVariant.variant_discount !== null ? FilteredVariant.variant_price - FilteredVariant.variant_discount : FilteredVariant.variant_price : item?.product?.purchase_price;
     var mainPrice = FilteredVariant ? FilteredVariant.variant_price : item?.product?.mrp;
     setFvar(FilteredVariant);
     setPPrice(purchagePrice);
     setMPrice(mainPrice);
     setfVName(FilteredVariant.variant_name);
    
  }

  return (
    <>
    
     {
       !isLoading && <li >
       <div className="product-item psl">
         <div className='remove-wishlist'>
           <button onClick={() => props.removeWishItem(item.id)}> <i className="fa fa-times"></i></button>
         </div>
         <img src={IMAGE_URL + item.product.thumbnail_img} className="img-responsive" alt='wishItem' />
         <p>
           <a href="#" className="product-name">
             {item.product.product_name}
           </a>
         </p><p>
         </p>
         {
           parseInt(item.product.has_variant) === 1 ?
           <div className="dropdown">
 
             <button className="btn dropdown-toggle qty-dropdown" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               {fVName} -   Rs. {pPrice}
             </button>
             <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
               {
                 item.product.variants?.map((item2, i) => {
                   return <span className="dropdown-item" key={i} onClick={() => handleChangeSize(item2.id)}>{item2.variant_name} - <span> {getItemInfo.call(this, item2)}</span></span>
                 })
               }
             </div>
           </div> : <div className='emp-space'></div>
         }
 
         <p className="listprice">
           Rs {pPrice}
           <span className="acci1 text-color1">
             <del>Rs {mPrice}</del>
             {/* <span className="text-green">[50% OFF]</span> */}
           </span>
         </p>
         <div className="qtyfirstdiv pdiv1"><div className="col-xs-4 cpadding3">

         <div className='row'>
                <div className='col-md-6'>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text" id="btnGroupAddon">QTY</div>
                    </div>
                    <input type="number" className="form-control" aria-label="Input group example" aria-describedby="btnGroupAddon" value={qty} onChange={(e)=>setQty(parseInt(e.target.value))}/>
                  </div>
                </div>
                <div className='col-md-6 text-right'>
                  <button type="button" className="qtybtn" onClick={handleCartSubmit}>
                    Add +
                  </button>
                </div>
              </div>
           {/* <button type="button" className="qtybtn" onClick={handleCartSubmit}>
             Add +
           </button> */}
           <div className="clearfix" />
         </div></div>
         <div className="qtyseconddiv qdiv1">
           <div className="col-md-12 padding0">
             <div className="input-group">
               <span className="input-group-btn">
                 <button type="button" className="quantity-left-minus btn btn-number" data-type="minus" data-field={1}>
                   <span className="glyphicon glyphicon-minus" />
                 </button>
               </span>
               <input type="text" id="quantity1" name="quantity" className="form-control input-number quantity_1" defaultValue />
               <span className="input-group-btn">
                 <button type="button" className="quantity-right-plus btn btn-number" data-type="plus" data-field={1}>
                   <span className="glyphicon glyphicon-plus" />
                 </button>
               </span>
             </div>
           </div>
           <div className="clearfix" />
         </div>
       </div>
     </li>
     }
    </>
    
  )
}

export default WishListItem