import React,{useState} from 'react'
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
const ResetPassword = (props) => {
  const [isLoading, setLoading] = useState(false);
    const submitResetPassword = async (values) =>{
        const data = {
            user_id:props.userDetails.id,
            user_type:props.userDetails.user_type,
            password:values.password,
            confirm_password:values.confirm_password,
        }
        console.log(data);
        props.resetPassword(data);
    }
  return (
    <Formik
                initialValues={{ password: '', confirm_password: '' }}
                validate={values => {
                  const errors = {};
                  if (!values.password) {
                    errors.password = 'Password is required.';
                  }
                  if (!values.confirm_password) {
                    errors.confirm_password = 'Confirm password is required.';
                  }
                  if(values.password !== values.confirm_password){
                    errors.confirm_password = "Password and confirm password doesn't match.";
                  }
                  return errors;
                }}

                onSubmit={submitResetPassword}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (<form onSubmit={handleSubmit}>


                  <div className="form-group">
                    <input type="password" 
                    placeholder="Password" 
                    className="form-control"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password} />
                     <span className='help-error'>{errors.password && touched.password && errors.password}</span>
                  </div>
                  <div className="form-group">
                    <input type="password" 
                    placeholder="Confirm Password" 
                    className="form-control"
                    name="confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirm_password} />
                     <span className='help-error'>{errors.confirm_password && touched.confirm_password && errors.confirm_password}</span>
                  </div>
                 
                  <div className="form-group">
                    <button type="submit" className="btn btn-green btn-block">{isLoading ? 'Please wait..':'RESET PASSWORD'}</button>
                  </div>
                </form>
                )}
              </Formik>
  )
}

export default ResetPassword