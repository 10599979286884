import { Apis } from '../../config';
import api from '../../ApiConfig';
import { toast } from 'react-toastify';
const getWalletMoney = async (user) =>{
    try {
        let result = await api.post(Apis.GetWalletMoney,user);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch wallet money')
    }
}


const addMoneyToWallet = async (data) =>{

    try {
        let result = await api.post(Apis.AddMoneyToWallet,data);
        return result.data;
    } catch (error) {
       
        // throw Error('Unable to add money');
        return error;
    }
}

const verifyRazorpayWallet = async (data) =>{
    try {
        let result = await api.post(Apis.VerifyPaymentWallet,data);
        return result.data;
    } catch (error) {
        throw Error('Unable to verify razorpay')
    }
}

const processWalletOrder = async (data) =>{
    try {
        let result = await api.post(Apis.ProcessWalletOrder,data);
        return result.data;
    } catch (error) {
        throw Error('Unable to process order with wallet.')
    }

}

const getCashfreePaymentStatus = async (data) =>{
    try {
    let result = await api.post(Apis.GetCashfreePaymentStatus,data);
    return result.data;
} catch (error) {
    return error;
}
}

const verifyCashfreetPayment = async (data) =>{
    try {
    let result = await api.post(Apis.VerifyCashfreePayment,data);
    return result.data;
} catch (error) {
    return error;
}
}
const exportedObject = {
    getWalletMoney,
    addMoneyToWallet,
    verifyRazorpayWallet,
    processWalletOrder,
    verifyCashfreetPayment,
    getCashfreePaymentStatus

};
export default exportedObject;