import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ProductCarouselItem from '../product/ProductCarouselItem';
import { Link } from "react-router-dom";
import { useQueries } from 'react-query';
import { GetHome, GetCategories } from '../../helpers';
import { IMAGE_URL } from '../../config';
import {
    options2,
    options3,
    options4,
    options1
} from '../../helpers/Options'

const Home = () => {
    const results = useQueries([
        { queryKey: ['homeBanner', 1], queryFn: GetHome.getHomeBanner },
        { queryKey: ['categories', 2], queryFn: GetCategories.getCategories },
        { queryKey: ['dealoftheday', 3], queryFn: GetHome.getDealOfTheDay },
        { queryKey: ['newArrivals', 4], queryFn: GetHome.getGetNewArrivals },
        { queryKey: ['youMayAlsoLike', 5], queryFn: GetHome.getYouMayAlsoLike },
        { queryKey: ['trendingProducts ', 6], queryFn: GetHome.getTreadingProducts }
    ])
    const bannerData = results[0]?.data;
    const categoryData = results[1]?.data;
    const dealOfTheDay = results[2]?.data;
    const newArrivals = results[3]?.data;
    const youMayAlsoLike = results[4]?.data;
    const trendingProducts = results[5]?.data;
    // console.log(bannerData);
    return (
        <>
            <section className="sectiondiv hero">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-9 col-sm-9">
                            {
                                bannerData && <OwlCarousel items={1}
                                    className="owl-theme owl-carousel1"
                                    {...options1}
                                >
                                    {
                                        bannerData.data.map((item, i) => {
                                            if (item.status) {
                                                return <div key={i}><a href={`${item.url}`} target="_blank" rel="noreferrer"><img src={IMAGE_URL + item.image} alt="banner" className="img-responsive owmimg1" /></a></div>
                                            } else {
                                                return null
                                            }
                                        })
                                    }
                                </OwlCarousel>
                            }
                        </div>
                        <div className="col-md-3 col-sm-3">
                            <img src="images/sideimg.png" className="img-responsive sideimg" alt="sideimg" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectiondiv offer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel items={5}
                                className="owl-theme owl-carousel2"
                                {...options2}
                            >
                                <div><img src="images/offer_img1.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/offer_img2.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/offer_img3.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/offer_img4.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/offer_img5.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/offer_img5.png" alt="img1" className="img-responsive" /></div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectiondiv categories">
                <div className="container-fluid">
                    {/* <div className="row"> */}
                    <p className="text-center btn2">
                        <Link to="/">
                            SHOP BY CATEGORIES
                        </Link>
                    </p>
                    {/* </div> */}
                    <div className="row mt-30">
                        <ul className="cate-items">
                            {
                                categoryData && categoryData.data.map((item, i) => {
                                    if (item.status) {
                                        return <li key={i}>
                                            <Link to={`/${item.category_slug}`} >
                                            <div className="cate-item">
                                                <img src={IMAGE_URL + item.category_icon} className="img-responsive" alt="cate_item1" />
                                                <p className="text-black text-center">
                                                    
                                                        {item.category_name}
                                                   
                                                </p>
                                            </div>
                                            </Link>
                                        </li>
                                    } else {
                                        return null
                                    }

                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <section className="sectiondiv cateoffer" id="dealoftheday">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <p>
                                <Link to="/" >
                                    <img src="images/offer_banner1.png" className="img-responsive center-block offer_banner1" alt="offer_banner1" />
                                </Link>
                            </p>
                        </div>
                    </div>
                    <div className="row mt-20  hidden-xs" >
                        <div className="col-md-11 pr-0">
                          
                            {dealOfTheDay && dealOfTheDay.length > 6 && <p className="vmore1">
                                <Link to="/" className="text-black">
                                    VIEW MORE
                                </Link>
                            </p>}
                        </div>
                        <div className="col-md-1">
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2 col-sm-3 col-xs-12">
                            <img src="images/catagory-banner-1.jpg" className="img-responsive center-block w-100 borderimg" alt="catagory-banner-1" />
                            {
                                dealOfTheDay && dealOfTheDay.length > 6 && <p className="vmore1 visible-xs">
                                    <Link to="/" className="text-black">
                                        VIEW MORE
                                    </Link>
                                </p>
                            }

                        </div>

                        <div className="col-md-10 col-sm-9 col-xs-12 ">
                            {
                                dealOfTheDay && <OwlCarousel items={6}
                                    className="owl-theme owl-carousel3"
                                    {...options3}
                                >
                                    {
                                        dealOfTheDay.data.map((item, i) => {
                                            if (item.status) {
                                                return <ProductCarouselItem data={item} key={i} />
                                                
                                            } else {
                                                return null
                                            }

                                        })}
                                </OwlCarousel>
                            }
                        </div>
                    </div>
                    <div className="row hidden-xs mt-20">
                        <div className="col-md-11 pr-0">
                            {
                                newArrivals && newArrivals.length > 6 && <p className="vmore1">
                                    <Link to="/" className="text-black">
                                        VIEW MORE
                                    </Link>
                                </p>
                            }
                        </div>
                        <div className="col-md-1">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-sm-3">
                            <img src="images/catagory-banner-2.jpg" className="img-responsive center-block w-100 borderimg" alt="catagory-banner-2" />

                        </div>
                        <div className="col-md-10 col-sm-9">
                            {
                                newArrivals && newArrivals.length > 6 && <p className="vmore1 vmoreAb visible-xs">
                                    <Link to="/" className="text-black">
                                        VIEW MORE
                                    </Link>
                                </p>
                            }

                            {newArrivals && <OwlCarousel items={6}
                                className="owl-theme owl-carousel3 car2"
                                {...options3}
                            >
                                {
                                    newArrivals.data.map((item, i) => {
                                        if (item.status) {
                                            return <ProductCarouselItem data={item} key={i} />
                                        } else {
                                            return null
                                        }

                                    })}
                            </OwlCarousel>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectiondiv cateoffer2 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <Link to="/" ><img src="images/offer-banner-1.jpg" className="img-responsive center-block borderimg o1" alt="cate_img1" /></Link>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <Link to="/" ><img src="images/offer-banner-2.jpg" className="img-responsive center-block borderimg o2" alt="cate_img1" /></Link>
                        </div>
                    </div>
                    <div className="row mt-30 hidden-xs">
                        <div className="col-md-11">
                        {
                            youMayAlsoLike && youMayAlsoLike.length > 6 && <p className="vmore1">
                                <Link to="/" className="text-black">
                                    VIEW MORE
                                </Link>
                            </p>
                        }
                        </div>
                        <div className="col-md-1">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-sm-3">
                            <img src="images/catagory-banner-3.jpg" className="img-responsive center-block borderimg w-100" alt="cate_img1" />

                        </div>
                        <div className="col-md-10 col-sm-9">
                        {
                            youMayAlsoLike && youMayAlsoLike.length > 6 && <p className="vmore1 vmoreAb visible-xs">
                                <Link to="/" className="text-black">
                                    VIEW MORE
                                </Link>

                            </p>
                        }
                            { youMayAlsoLike && <OwlCarousel items={6}
                                className="owl-theme owl-carousel3 car3"
                                {...options3}
                            >
                                 {
                                    youMayAlsoLike.data.map((item, i) => {
                                        if (item.status) {
                                            return <ProductCarouselItem data={item} key={i} />
                                        } else {
                                            return null
                                        }

                                    })}
                            </OwlCarousel> }

                        </div>
                    </div>
                    <div className="row hidden-xs mt-20">
                        <div className="col-md-11">
                        {
                            trendingProducts && trendingProducts.length > 6 &&  <p className="vmore1">
                                <Link to="/" className="text-black">
                                    VIEW MORE
                                </Link>
                            </p>
                        }
                        </div>
                        <div className="col-md-1">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2 col-sm-3">
                            <img src="images/catagory-banner-4.jpg" className="img-responsive center-block borderimg w-100" alt="cate_img1" />
                        </div>
                        <div className="col-md-10 col-sm-9">
                        {
                            trendingProducts && trendingProducts.length > 6 && <p className="vmore1 vmoreAb visible-xs">
                                <Link to="/" className="text-black">
                                    VIEW MORE
                                </Link> 
                            </p>
                        }
                           { trendingProducts && <OwlCarousel items={6}
                                className="owl-theme owl-carousel3 car2"
                                {...options3}
                            >
                             {
                                trendingProducts.data.map((item, i) => {
                                        if (item.status) {
                                            return <ProductCarouselItem data={item} key={i} />
                                        } else {
                                            return null
                                        }

                                    })}
                            </OwlCarousel>}

                        </div>
                    </div>
                </div>
            </section>
            <section className="sectiondiv bank">
                <div className="container-fluid">
                    {/* <div className="row"> */}
                    <p className="text-center btn2 mt-30">
                        <Link to="/" >
                            BANK &amp; WALLET OFFERS
                        </Link>
                    </p>
                    {/* </div> */}
                    <div className="row">
                        <div className="col-md-12">
                            <OwlCarousel items={5}
                                className="owl-theme owl-carousel22"
                                {...options4}
                            >
                                <div><img src="images/bank_img1.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/bank_img2.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/bank_img3.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/bank_img4.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/bank_img5.png" alt="img1" className="img-responsive" /></div>
                                <div><img src="images/bank_img5.png" alt="img1" className="img-responsive" /></div>
                            </OwlCarousel>

                        </div>
                    </div>
                </div>
            </section>
            <section className="sectiondiv blankoffer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <img src="images/blank_img1.png" className="img-responsive center-block" alt="cate_img1" />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <img src="images/blank_img2.png" className="img-responsive center-block offer_banner2" alt="cate_img1" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home