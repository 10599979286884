import React, { useState, useEffect } from 'react'
// import { cashfreeSandbox } from 'cashfree-elementjs';
// import { cashfreeProd } from 'cashfree-elementjs';
import backJson from './data/netBanking.json'
//import NetBankingOptions from '../NetBankingOptions';
import $ from "jquery";
const NetBanking = ({cfCheckout, onPayment}) => {
    const [bankCode, setBankCode] = useState(null);
   // const [bankName, setBankName] = useState(null);
    const [isReadytoPay, setIsReadyToPay] = useState(false);
    // //Payment responses
    // const onPaymentSuccess = (data) => {
    //     console.log("Success:", data);
    // }
    // const onPaymentFailure = (data) => {
    //     console.log("Failure:", data);
    // }
    // const onError = (data) => {
    //     console.log("Error:", data);
    // }

    // //payment options
    // const option = {
    //     onPaymentSuccess: onPaymentSuccess,
    //     onPaymentFailure: onPaymentFailure,
    //     onError: onError,
    // }
    // var cfCheckout = cashfreeProd.initializeApp(option);
    function netBankinHandler(data) {
        console.log("Net Banking --> ", data)
        setIsReadyToPay(data.isReadyToPay)
    }
    useEffect(() => {
        cfCheckout.element(
            {
                pay: document.getElementById('pay-nb'),
                type: 'netbanking',
                onChange: netBankinHandler
            },
        );
    }, [cfCheckout])


    const handleChangeBank = (bankCode, backName) => {
        setBankCode(bankCode);
        // setBankName(backName);
        $("#backlist").modal("hide");
    }
    const onPaymentInit = async () => {
       // await cfCheckout.pay('42JuGuMnd0b7F6e2ZC2F', 'netbanking');
       onPayment("netbanking");
    }
    //const mainBank = backJson.filter(el => el.default_show === true);

    return (
        <div className="mb-3">
               <div id="pay-nb">
               <label>All Banks</label>
                <select className='form-control mb-3' data-netbanking-code value={bankCode} onChange={(e) => handleChangeBank(e.target.value)}>
                    <option value="">Select Back</option>
                    {
                        backJson.map((item, i)=>{
                            return <option value={item.back_code} key={i}>{item.back_name}</option>
                        })
                    }
                </select>
                {/* <div className='net-banking-back'>
                    <div className='bank_list mb-3'>
                        {
                            mainBank.map((item, i) => {
                                return <div className='bank-item' key={i}>
                                    <div className='bank-item-wrap'>
                                        <label htmlFor={`${'back_name' + i}`} className="d-flex">
                                            <input type="radio" data-netbanking-code value={item.back_code} name='back_name' id={`${'back_name' + i}`} checked={bankCode === item.back_code} onChange={(e) => handleChangeBank(e.target.value)} />
                                            <div className='bank_icon'>
                                                <img src={"/images/bank/" + item.thumbnail} alt='' />
                                            </div>

                                            <span>{item.back_name}</span>
                                        </label>

                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div> */}
                <button className={isReadytoPay ? 'btn btn-block pay-btn2 ' : 'btn btn-block btn-dark pay-btn'} onClick={isReadytoPay ? onPaymentInit : undefined} disabled={!isReadytoPay}>Pay Now</button>
               </div>
              

            {/* <NetBankingOptions/> */}
           
                {/* <label>All Banks</label>
                <select className='form-control mb-3' data-netbanking-code value={bankCode} onChange={(e) => handleChangeBank(e.target.value)}>
                    <option value="">---Select Back---</option>
                    {
                        backJson.map((item, i)=>{
                            return <option value={item.back_code} key={i}>{item.back_name}</option>
                        })
                    }
                </select> */}
                 {/* <div id="pay-nb">
                <button type="button" className="btn net-banking-model-btn" data-toggle="modal" data-target="#backlist">
                    <span>{bankName === null ? 'Other Back' : bankName}</span>  <span><i className="fa fa-chevron-down" /></span>
                </button> */}
                {/* Modal */}
                {/* <div className="modal" id="backlist" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered mh-375" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Select Bank</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body net-banking-banks-body">
                                <ul>
                                    {
                                        backJson.map((item, i) => {
                                            return <li key={i} onClick={() => handleChangeBank(item.back_code, item.back_name)} className={bankCode === item.back_code ? 'active' : ''}>{item.back_name}</li>
                                        })
                                    }
                                </ul>

                            </div>

                        </div>
                    </div>
                </div> */}

                
                {/* <input type="text" value={bankCode} data-netbanking-code /> */}

                 {/* </div> */}
            {/* <button className={isReadytoPay ? 'btn btn-block pay-btn2 ' : 'btn btn-block btn-dark pay-btn'} onClick={isReadytoPay ? onPaymentInit : undefined} disabled={!isReadytoPay}>Pay Now</button> */}
        </div>
    )
}

export default NetBanking