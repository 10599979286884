import * as types from '../types'


const initialState = {
  cartItems: [],
  showLoading: false
}

export const cartReducer = (
  state = { cartItems: [], total_price : 0 },
  action
) => {

  switch (action.type) {
    case types.ADD_TO_CART:
      return {
        cartItems: action.payload.cartItems,
        total_price:action.payload.final_total,
      };
      
    case types.FETCH_TO_CART:
      return {
        cartItems: action.payload.cartItems,
        total_price:action.payload.final_total,
      };
      
    case types.GET_TOTALS:
        return {
          total_price: action.payload
        };
    case types.INCREASE_QUANTITY:
      return { 
        cartItems: action.payload.cartItems,
        total_price:action.payload.final_total
       };

    case types.DECREASE_QUANTITY:
      return {
        cartItems: action.payload.cartItems,
        total_price:action.payload.final_total
      };

    case types.REMOVE_FROM_CART:
      return {
        cartItems: action.payload.cartItems,
        total_price:action.payload.final_total
      };

    case types.RESET:
      return {
        cartItems: [],
        total_price:0
      };

    case types.TOGGLE_LOADING:
      return {
        cartItems: action.payload.cartItems,
        showLoading: action.payload
      };

    default:
      return state;
  }
};
