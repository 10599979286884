import { Apis } from '../../config';
import api from '../../ApiConfig';

const createOrder = async (data) =>{
    try {
        let result = await api.post(Apis.CreateOrder,data);
        return result.data;
    } catch (error) {
        console.log(error);
        throw Error('Unable to create order')
    }
}
const getUserOrder = async (user) =>{
    try {
        let result = await api.post(Apis.GetUserOrder, user);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch order')
    }
}
const exportedObject = {
    createOrder,
    getUserOrder
   };
export default exportedObject;