import React from 'react'
import { useQuery } from 'react-query';
import { GetInnerPages } from '../../../helpers';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import parse from 'html-react-parser';
import {


    options6,

} from '../../../helpers/Options'
const AboutUs = () => {
    const { status, data, error } = useQuery('aboutData', GetInnerPages.getAboutData);
    const aboutData = data?.data;
    return (
        <>
            {/* <section className="breadcrumbs sectiondiv">
                <div className="container">
                    <div className='page-title'>
                        <h1 className="text-center text-color1">About Us</h1>
                    </div>
                </div>
            </section> */}
            {/* <section>
                <div className='container'>
                    {status === "loading" ? (
                        "Loading..."
                    ) : status === "error" ? (
                        <span>Error: {error.message}</span>
                    ) : (
                        <div className='inner-page-content'>
                          {parse(aboutData?.content)}
                        </div>
                    )
                    }
                </div>
            </section> */}
             <section className="video">
          <div className="overlay-wcs" />
          <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
            <source src="/images/about/webiste-video-9-sec-comp2.mp4" type="video/mp4" />
          </video>
          <div className="container h-100">
            <div className="d-flex h-100 text-center align-items-center">
              <div className="w-100 text-white">
                <img src="/images/about/logo-white-300x84.png" className="img-responsive" />
                <h4 className="text-white">India’s leading wholesale food and grocery, and new retail platform</h4>
                <p className="text-white">
                  Jai Jawan, Jai Kisaan, Jai Dukaan
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionviv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10">
                <h3 className="h3shead">
                  We are fundamentally reengineering the food and grocery value chain in India using technology, data science, and design.
                </h3>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </section>  
        <section className="sectionviv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-xs-12 pl-0 pr-0">
                <img src="/images/about/jumbotech3@2x.jpg" className="img-responsive center-block imgw" />
              </div>
              <div className="col-md-1" />
              <div className="col-md-4 mt-150">
                <h3>
                  Marketplace <br /> Platform
                </h3>
                <h4 className="mt-20">
                  Our marketplace platform connects thousands of mom &amp; pop grocery retailers -‘kirana stores’ and supermarkets, with brands and staples producers
                </h4>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </section>
        <section className="sectionviv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-4 mt-150">
                <h3>
                  Wide Selection  
                </h3>
                <h4 className="mt-20">
                  We offer a wide selection of high-quality staples, packaged foods, personal care, and home care products from all the leading brands, and staples producers
                </h4>
              </div>
              <div className="col-md-1" />
              <div className="col-md-6 col-xs-12 pl-0 pr-0">
                <img src="/images/about/wide-range-of-products-500px.jpg" className="img-responsive center-block imgw imgw2" />
              </div>
            </div>
          </div>
        </section>
        <section className="sectionviv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 pl-0 pr-0">
                <img src="/images/about/yash-Sourcing-500px-1.jpg" className="img-responsive center-block imgw imgw2" />
              </div>
              <div className="col-md-1" />
              <div className="col-md-4 mt-150">
                <h3>
                  Quality Sourcing
                </h3>
                <h4 className="mt-20">
                  We ensure our customers get high-quality products by providing sourcing services and quality control services to our marketplace sellers
                </h4>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </section>
        <section className="sectionviv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-4 mt-150">
                <h3>
                  Supply Chain and Logistics
                </h3>
                <h4 className="mt-20">
                  We own and operate our full stack tech-driven supply chain and logistics to provide warehousing and fulfilment solutions to our sellers and storefront delivery to our customers
                </h4>
              </div>
              <div className="col-md-1" />
              <div className="col-md-6 pl-0 pr-0">
                <img src="/images/about/FC-image.jpg" className="img-responsive center-block imgw2" />
              </div>
            </div>
          </div>
        </section>
        <section className="sectionviv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 pl-0 pr-0">
                <img src="/images/about/Credit-1.jpg" className="img-responsive center-block imgw" />
              </div>
              <div className="col-md-1" />
              <div className="col-md-4 mt-150">
                <h3>
                  Credit and <br /> Payments
                </h3>
                <h4 className="mt-20">
                  We provide payment solutions, and provide access to working capital credit from our lending partners for our customers and sellers via our fintech platform
                </h4>
              </div>
              <div className="col-md-1" />
            </div>
          </div>
        </section>
        <section className="sectionviv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-4 mt-150">
                <h3>
                  J24 New Retail <br /> Platform
                </h3>
                <h4 className="mt-20">
                  We provide Retail-as-a-Service with PoS fully integrated with our supply chain and fintech to help mom &amp; pop entrepreneurs to run modern convenience retail stores
                </h4>
              </div>
              <div className="col-md-1" />
              <div className="col-md-6 pl-0 pr-0">
                <img src="/images/about/brothers-store.jpg" className="img-responsive center-block imgw imgw2" />
              </div>
            </div>
          </div>
        </section>
        <section className="sectionviv">
          <div className="container">
            <div className="row">
              <hr className="hr5" />
            </div>
          </div>
        </section>
        <section className="sectionviv hapisec">
          <div className="container">
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <h3 className="text-center">
                  Happiness. Prosperity. Delivered.
                </h3>
                <h4 className="text-center mt-30">
                  Fair access to markets and increased economic opportunities for India’s wholesale ecosystem of millions of mom &amp; pop grocery retailers, brands, and staples producers and farmers.
                </h4>
              </div>
              <div className="col-md-2" />
            </div>
          </div>
        </section> 
        <section className="sectionviv slider">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
              <OwlCarousel items={1}
                                className="owl-theme owl-carousel22"
                                {...options6}
                            >
                                <div><img src="/images/about/1.jpg" alt="img1" className="img-responsive" /></div>
                                <div><img src="/images/about/2.jpg" alt="img1" className="img-responsive" /></div>
                                <div><img src="/images/about/3.jpg" alt="img1" className="img-responsive" /></div>
                                <div><img src="/images/about/4.jpg" alt="img1" className="img-responsive" /></div>
                                <div><img src="/images/about/5.jpg" alt="img1" className="img-responsive" /></div>
                            </OwlCarousel>

              </div>
            </div>
          </div>
        </section>
        <section className="sectionviv back1">
          <div className="bak1">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="text-center text-white h1size1">
                    Hear from our brand partners!
                  </h1>
                </div>
              </div>
              <div className="row mt-50">
                <div className="col-md-4">
                  <img src="/images/about/Mars-300x127.png" className="img-responsive center-block" />
                  <div className="divblack1">
                    <p className="text-white">
                      Jumbotail is one of the most data-centric and agile companies in the B2B e-commerce domain.  Their strong focus on customers makes them a worthy player in the space.
                    </p>
                    <p className="text-white">
                      Abhinav Akash 
                    </p>
                    <p className="text-white">
                      Channel Head, E-Commerce
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src="/images/about/Marico-1-300x127.png" className="img-responsive center-block" />
                  <div className="divblack1">
                    <p className="text-white">
                      Great ability to dissect and target customers with a variety of offers with meaningful data based insights.
                    </p>
                    <p className="text-white">
                      Pawan Malve
                    </p>
                    <p className="text-white">
                      NKam, E-Commerce
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src="/images/about/ReRB-1-300x127 (1).png" className="img-responsive center-block" />
                  <div className="divblack1">
                    <p className="text-white mt-50">
                      Jumbotail has helped us in the quality of distribution, basis anytime availability and a data-centric approach.
                    </p>
                    <p className="text-white">
                      Kshitij Sharma
                    </p>
                    <p className="text-white">
                      KAM, E-commerce
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-30">
                <div className="col-md-4">
                  <img src="/images/about/Mohini-300x127.png" className="img-responsive center-block" />
                  <div className="divblack1">
                    <p className="text-white">
                      Jumbotail is an effective platform to reach out to the kind of customer that I cannot directly market to.
                    </p>
                    <p className="text-white">
                      Raghav Gupta
                    </p>
                    <p className="text-white">
                      Mohini Mill Corporation
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src="/images/about/SMTC-logo-300x127.png" className="img-responsive center-block" />
                  <div className="divblack1">
                    <p className="text-white">
                      Its an amazing experience to partner with Jumbotail which has helped me to understand how to improve the quality using customer’s feedback which they provide us with.
                    </p>
                    <p className="text-white">
                      Kapil
                    </p>
                    <p className="text-white">
                      Sri Mookambika Trading Company
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <img src="/images/about/Durga-300x127.png" className="img-responsive center-block" />
                  <div className="divblack1">
                    <p className="text-white">
                      Jumbotail has given us presence across Bangalore via their platform which shows their marketing ability and importance for Brand Image.
                    </p>
                    <p className="text-white">
                      Nandish
                    </p>
                    <p className="text-white">
                      Sri Mookambika Trading Company
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionviv boxdiv">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1 className="text-center">Driven by purpose. Hungry for impact.</h1>
                <h4 className="text-center">
                  Our world-class team is solving tough problems using cutting edge technology, artificial intelligence, intuitive design, innovative business models and hardcore operational excellence.
                </h4>
              </div>  
            </div>
            <div className="row mt-100">
              <div className="col-md-4">
                <div className="boxdiv1">
                  <h5 className="text-center">
                    OUR APPROACH
                  </h5>
                  <p className="text-center mt-20">
                    We invest heavily in understanding our users and think first principles to solve their problems. We care for outcomes, not outputs. We have fun.
                  </p>
                  <p className="text-center">
                    <a className="btnl1" href="#">TAKE A PEEK</a>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="boxdiv1">
                  <h5 className="text-center">
                    OUR CORE VALUES
                  </h5>
                  <p className="text-center mt-20">
                    We believe that the bedrock of a great enduring company is its core values. We have defined our core values that are meaningful to us, and that we live and die by.
                  </p>
                  <p className="text-center">
                    <a className="btnl1" href="#">LEARN MORE</a>
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="boxdiv1">
                  <h5 className="text-center">
                    JOIN OUR MISSION
                  </h5>
                  <p className="text-center mt-20">
                    If you have the hunger to work with a world class team to solve challenging problems and impact real lives, we would love to hear from you.
                  </p>
                  <p className="text-center">
                    <a className="btnl1" href="#">VIEW CAREERS</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionviv btndiv">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="text-center">
                  <a href="#" className="btnl2">
                    FIND US ON LINKEDIN
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        </>
    )
}

export default AboutUs