import { Apis } from '../../config';
import api from '../../ApiConfig';

const getAboutData = async () =>{
    try {
        let result = await api.get(Apis.GetAboutData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}

const getOurCommitmentData = async () =>{
    try {
        let result = await api.get(Apis.GetOurCommitmentData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}

const getHowItWorksData = async () =>{
    try {
        let result = await api.get(Apis.GetHowItWorksData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}

const getTestimonialsData = async () =>{
    try {
        let result = await api.get(Apis.GetTestimonialsData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}

const getPressData = async () =>{
    try {
        let result = await api.get(Apis.GetPressData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}

const getPrivacyPolicyData = async () =>{
    try {
        let result = await api.get(Apis.GetPrivacyPolicyData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}

const getRefundPolicyData = async () =>{
    try {
        let result = await api.get(Apis.GetRefundPolicyData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}

const getReturnPolicyData = async () =>{
    try {
        let result = await api.get(Apis.GetReturnPolicyData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}

const getShippingPolicyData = async () =>{
    try {
        let result = await api.get(Apis.GetShippingPolicyData);
        return result.data;
    } catch (error) {
        throw Error('Unable to fetch data')
    }
}


const exportedObject = {
    getAboutData,
    getOurCommitmentData,
    getHowItWorksData,
    getTestimonialsData,
    getPressData,
    getPrivacyPolicyData,
    getRefundPolicyData,
    getReturnPolicyData,
    getShippingPolicyData
};
export default exportedObject;