import * as types from '../types'
import {GetAuth} from '../../helpers'
import { destroyCookie } from 'nookies'
import { fetchCartItems } from './cartActions'
export const fetchusers = () => async dispatch =>{
    let resp = await GetAuth.getUser();
    if(resp && resp.data === null){
        dispatch(unsetUser());
    }else{
        dispatch({
            type:types.SET_USER,
            payload:resp.data
        })

       // dispatch(fetchCartItems(resp.data))
    }
       
}

export const unsetUser = () => async dispatch =>{
    dispatch({
        type:types.UNSET_USER,
    })
}
