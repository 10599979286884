import * as types from '../types'
import {GetProducts} from '../../helpers'
import { toast } from 'react-toastify';



export const addToWishlist =  (product) => async (dispatch, getState) => {
 
  const res = await GetProducts.addToWishlist(product);
   if(res.data){
     
    const wishItems = getState().wishlist.wishItems.slice();
   
    let alreadyExists = false;
    wishItems.forEach((x) => {
      if (parseInt(x.product_id) === parseInt(product.product_id)){
         alreadyExists = true;
      }
    })
  
    if (!alreadyExists) { 
      const proInfo = {
        id: res.data.id,
        user_id: res.data.user_id,
        user_type: res.data.user_type,
        product_id: res.data.product_id,
        product:res.product,
        varient:res.variant
      }
      wishItems.push({ ...proInfo });
      toast.success('Nice ! Product added to wishlist', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      dispatch(loadingWishlist(false))
       dispatch({
          type: types.ADD_TO_WISHLIST,
          payload: { wishItems },
        });
    }

   }  
}
export const setAllProductToWishlist = (user) => async (dispatch) =>{
  const data = {
    user_id:user.authId,
    user_type:user.auth_type,
  }
  const res = await GetProducts.getAllWishlistItems(data);
  if(res && res.status === 200){
    const wishItems = res.data || [];
    dispatch(loadingWishlist(false))
    dispatch({
      type: types.FETCH_FROM_WISHLIST,
      payload: { wishItems },
    });
   
  }


}
export const resetToWishlist = () =>{
  return {
    type:types.RESET_WISHLIST,
  }
}
export const removeFromWishlist = (del_id) => async (dispatch, getState) => {
  const res = await GetProducts.removeFromWishlist({wishlist_id:del_id});
  if(res && res.status === 200){
    const wishItems = getState().wishlist.wishItems.slice().filter((x) => x.id !== del_id);
    dispatch(loadingWishlist(false))
    dispatch({
      type: types.REMOVE_FROM_WISHLIST,
      payload: { wishItems },
    });
  }


}

export const loadingWishlist = (status) =>{
    return {
      type:types.LOADING_WISHLIST,
      payload: status
    }
 }
