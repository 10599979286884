import React, { useEffect, useState } from 'react'
import AddMoneyModal from '../AddMoneyModal'
import CashFreeModal from './CashFreeModal'
import { useSelector } from 'react-redux'
import { GetWallet } from '../../../../helpers'
import { API_URL } from '../../../../config'
const Wallet = () => {
  const userDetails = useSelector(state => state.user);
  const [isLoading, setLoading] = useState(true);
  const [wallet, setWallet] = useState(null);

  //payU data
  const [key, setKey] = useState('oZ7oo9');
  const [txnid, setTxnid] = useState('');
  const [productinfo, setProductinfo] = useState('Wallet');
  const [amount, setAmount] = useState(10);
  const [hash, setHash] = useState('');


  const getWallet = async () => {
    setLoading(true);
    const user = {
      user_id: userDetails?.user.id,
      user_type: userDetails?.user.user_type,
    }

    const res = await GetWallet.getWalletMoney(user);
    if (res && res.data !== null) {
      setWallet(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
  const renderDropin = (data) => {
    console.log(data);
    window.location.href = `https://payments-test.cashfree.com/order/#${data.order_token}`;
  }
  const payUmoneyHash = async (amt) => {
    setAmount(amt);

    const data = {
      payment_method: "payu",
      udf1: userDetails?.user.id,
      udf2: userDetails?.user.user_type,
      amount: amt,
      firstname: userDetails?.user.name,
      email: userDetails?.user.email,
      phone: userDetails?.user.phone,
      productinfo: productinfo
    }
  
    const res = await GetWallet.addMoneyToWallet(data);
    console.log(res);
    if(res && res.status === 200){
       setTxnid(res.txnid)
       setHash(res.hash)
       document.getElementById('payform').submit();
    }

  }


  useEffect(() => {
    getWallet();
  }, [])



  return (
    <>
      <form action='https://test.payu.in/_payment' method='post' id="payform">
        <input type="hidden" name="key" value={key} />
        <input type="hidden" name="txnid" value={txnid} />
        <input type="hidden" name="productinfo" value={productinfo} />
        <input type="hidden" name="amount" value={amount} />
        <input type="hidden" name="email" value={userDetails?.user.email} />
        <input type="hidden" name="firstname" value={userDetails?.user.name} />
        <input type="hidden" name="surl" value={`${API_URL}/api/payu-responce`} />
        <input type="hidden" name="furl" value={`${API_URL}/api/payu-responce`} />
        <input type="hidden" name="phone" value={userDetails?.user.phone} />
        <input type="hidden" name="udf1" value={userDetails?.user.id} />
        <input type="hidden" name="udf2" value={userDetails?.user.user_type} />
        <input type="hidden" name="hash" value={hash} />
      </form>


      <div className="dashboard-title">
        <h4 className="user-heading">My Wallet</h4>
      </div>
      <div className="dashboard-content text-center">
        {
          isLoading ? <div className='loading-container mh150'>
            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="loading icon" />
          </div> : <>
            <p className="user-balance">My Balance</p>
            <p className='balance'><i className="fa fa-inr"></i> {wallet?.amount === undefined ? '0' : wallet?.amount}</p>
            <a href="#addMoneyModal" className="address-btn" data-toggle="modal" data-backdrop="static" data-keyboard="false">
              Add Money
            </a>
            <AddMoneyModal renderDropin={renderDropin} payUmoneyHash={payUmoneyHash} />
            <CashFreeModal />
          </>
        }

      </div>



    </>
  )
}

export default Wallet