import React,{useEffect, useState} from 'react'
// import { cashfreeSandbox } from 'cashfree-elementjs';
// import { cashfreeProd } from 'cashfree-elementjs';
const CardPayment = ({cfCheckout, onPayment, cardError}) => {
     const [appIsReady, setAppIsReady] = useState(false);
     const [cardBrand, setCardBrand] = useState(null);
     const [cardExpiryMonth, setCardExpiryMonth] = useState('');
     const [cardExpiryYear, setCardExpiryYear] = useState('');
     const [cardNumber, setCardNumber] = useState('');
     const [cvv, setCvv] = useState('');
//     : "12"
// cardExpiryYear: "24"
// cardHolder: ""
// cardNumber: "4111111111111111"
// cvv: "123

const handleChangeCardNumber = (e) =>{
    const val = e.target.value;
    setCardNumber(val);

    
}
    function cardEventHandler(data){
        console.log("card --> ", data)
        setAppIsReady(data.isReadyToPay);   
        if(data.brand){
            setCardBrand(data.brand);
        }else{
            setCardBrand(null)
        }     
}
    useEffect(() => {
        cfCheckout.element(
            {
                pay: document.getElementById('pay-card'),
                type: 'card',
                onChange: cardEventHandler
            },
        );
    }, [cfCheckout]);

const onPaymentInit = async() =>{
   onPayment("card");
}
  return (
    <div id="pay-card">
         {cardError !== '' && <span className='alert alert-danger d-block'><i className="fa fa-exclamation-circle"></i> {cardError}</span> }
        <div className="mb-3 plr">
            <label>Card Number</label>
            <input type="tel" value={cardNumber} data-card-number onChange={handleChangeCardNumber} maxLength="19" className="form-control plr" placeholder="Card Number" />
            {cardBrand !== null && <span className={`card-type card-type-${cardBrand}`}></span> }
        </div>
        <div className="row">
            <div className="col-sm-7  mb-3">
                <label>Valid Through</label>
               <div className='d-flex'>
               <input type="text" value={cardExpiryMonth} data-card-expiry-mm onChange={(e)=>setCardExpiryMonth(e.target.value)} className="form-control col-sm-6" placeholder="MM" maxLength={2} />
                <input type="text" value={cardExpiryYear} data-card-expiry-yy onChange={(e)=>setCardExpiryYear(e.target.value)} className="form-control col-sm-6" placeholder="YY" maxLength={2} />
               </div> 
            </div>
            <div className="col-sm-5 mb-3">
                <label>CVV</label>
                <input type="password" maxLength="4" value={cvv} data-card-cvv onChange={(e)=>setCvv(e.target.value)} className="form-control" placeholder="CVV"/>
            </div>
        </div>
        <div className="mb-3">
            <button type="button" onClick={appIsReady ? onPaymentInit : undefined} className={appIsReady? 'btn btn-block pay-btn2' : 'btn btn-block btn-dark pay-btn'}>Pay Now</button>
        </div>
       
    </div>
  )
}

export default CardPayment